const featuresPay = {
    featureTitle: 'Prenez des décisions commerciales basées sur les données',
    featureDescription:
        'Découvrez quels articles se vendent et génèrent le plus de profit, et accédez aux rapports financiers à tout moment, n’importe où. Votre entreprise dans la paume de votre main !',
    featureButton: "Abonnement Premium depuis l'application",
    featureStartingPrice: '80',
    featureEndingPart: '30',
    featureStaringText: 'À partir de',
    featureEndingText: 'Mille',

    features: [
        {
            title: 'Articles illimités',
            description: 'Ajoutez tous les articles que vous possédez sans aucune limite.'
        },
        {
            title: 'Personnel illimité',
            description: "Ajoutez du personnel/admin à l'entreprise sans aucune limite."
        },
        {
            title: 'Historique illimité',
            description: 'Consultez les modifications dans les anciens enregistrements de stock sans aucune limite.'
        },
        {
            title: "Filtre d'historique",
            description:
                "Consultez facilement l'historique en fonction du type, de la catégorie, du personnel et de la date."
        },
        {
            title: 'Télécharger au format Excel',
            description: "Obtenez l'historique des enregistrements de stock dans Microsoft Excel en un clic."
        },
        {
            title: 'Télécharger au format PDF',
            description: 'Obtenez la liste des derniers articles et du stock en PDF en un clic.'
        }
    ],
    warehousesGuide: [
        {
            desc: 'Créez plusieurs entrepôts pour une meilleure gestion des stocks.'
        },
        {
            desc: 'Attribuez des produits à des emplacements spécifiques.'
        },
        {
            desc: 'Surveillez les niveaux de stock dans les entrepôts.'
        }
    ],
    addVariantsGuide: [
        { desc: 'Ajoutez des variantes de taille, de couleur ou de matériau.' },
        { desc: 'Définissez les niveaux de stock par variante.' },
        { desc: 'Suivez à travers les entrepôts.' }
    ]
}

export default featuresPay
