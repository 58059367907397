const featuresPay = {
    featureTitle: 'डेटाच्या आधारे व्यवसाय निर्णय घ्या',
    featureDescription:
        'कोणती वस्तू विकली जात आहे आणि सर्वाधिक नफा मिळवून देत आहे ते जाणून घ्या, आणि कधीही, कुठूनही आर्थिक अहवाल पहा. तुमचा व्यवसाय तुमच्या हाती!',
    featureButton: 'अॅपमधून प्रीमियम सदस्यता',
    featureStartingPrice: '80',
    featureEndingPart: '30',
    featureStaringText: 'पासून सुरूवात',
    featureEndingText: 'हजार',

    features: [
        {
            title: 'अमर्यादित वस्तू',
            description: 'तुमच्याकडे असलेल्या सर्व वस्तू अमर्यादितपणे जोडा.'
        },
        {
            title: 'अमर्यादित कर्मचारी',
            description: 'व्यवसायात अमर्यादितपणे कर्मचारी/व्यवस्थापक जोडा.'
        },
        {
            title: 'अमर्यादित इतिहास',
            description: 'जुन्या स्टॉक रेकॉर्डमधील बदल अमर्यादितपणे पहा.'
        },
        {
            title: 'इतिहासाची गाळणी',
            description: 'प्रकार, श्रेणी, कर्मचारी आणि तारखेनुसार इतिहास सहजपणे पहा.'
        },
        {
            title: 'एक्सेलमध्ये डाउनलोड करा',
            description: 'एकाच क्लिकमध्ये स्टॉक रेकॉर्डचा इतिहास मायक्रोसॉफ्ट एक्सेलमध्ये मिळवा.'
        },
        {
            title: 'PDF मध्ये डाउनलोड करा',
            description: 'एकाच क्लिकमध्ये नवीनतम वस्तू आणि स्टॉक यादी PDF मध्ये मिळवा.'
        }
    ],
    warehousesGuide: [
        {
            desc: 'साठा व्यवस्थापन सुधारण्यासाठी एकाधिक गोदामे तयार करा.'
        },
        {
            desc: 'विशिष्ट ठिकाणी उत्पादने नियुक्त करा.'
        },
        {
            desc: 'गोदामांमधील साठा पातळीचे निरीक्षण करा.'
        }
    ],
    addVariantsGuide: [
        { desc: 'आकार, रंग किंवा सामग्री प्रकार जोडा.' },
        { desc: 'प्रत्येक प्रकारासाठी स्टॉक पातळी सेट करा.' },
        { desc: 'गोदामांमध्ये ट्रॅक करा.' }
    ]
}

export default featuresPay
