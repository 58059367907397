const common = {
    no_data: 'Aucune donnée disponible',
    header: {
        business_name: 'Votre Entreprise'
    },
    download_app: {
        text: 'Pour utiliser la version web de Simply, veuillez utiliser un ordinateur portable ou un PC.',
        button: 'Télécharger la version mobile'
    },
    unknown: 'Inconnu',
    request_error:
        'Une erreur est survenue. Veuillez réessayer ! Ou contactez le support client pour signaler votre problème.',
    home: 'Accueil',
    product: 'Produit',
    add_product: 'Ajouter un produit',
    edit_product: 'Modifier le produit',
    stock_in: 'Stock entrant',
    Sales_Turnover: "Chiffre d'affaires",
    profit: 'Bénéfice',
    stock_out: 'Stock sortant',
    staff: 'Personnel',
    stock_audit: 'Audit de stock',
    button: {
        save: 'Enregistrer',
        save_and_add_more: 'Enregistrer & Ajouter Plus',
        submit: 'Soumettre',
        done: 'Fait',
        edit: 'Modifier',
        save_edit: 'Enregistrer les modifications',
        yes: 'Oui',
        clear: 'Effacer',
        delete: 'Supprimer',
        cancel: 'Annuler',
        reset: 'Réinitialiser'
    },
    select_date: 'Sélectionner la date',
    select_action: "Sélectionner l'action",
    mandatory: ' *(Obligatoire)',
    not_mandatory: '(Non obligatoire)',
    business: 'Entreprise',
    account: 'Compte',
    profile: 'Profil',
    setting: 'Paramètres',
    whichLanguage: 'Français',
    headerDashboard: 'Devenir VIP',
    contact_us: 'Contactez-nous',
    logout: 'Se déconnecter',
    category: 'Catégorie',
    data: 'Données',
    history: 'Historique',
    lowStockAlert: 'Alerte de Stock Faible',
    lowStockAlertButton: 'Ajouter la Quantité de Stock de Sécurité',
    message: {
        login_success: 'Connexion réussie !',
        onboard_success: 'Hourra !! Votre voyage a commencé !',
        successfully_saved: 'Données enregistrées avec succès',
        successfully_deleted: 'Données supprimées avec succès',
        error_required_field: 'Le champ requis ne peut pas être vide',
        no_access: 'Accès refusé',
        error_update_history: "Échec de la mise à jour de l'historique"
    },
    feedback: 'Donner un avis',
    contact: 'Contact',
    add: 'Ajouter',
    supplier: 'Fournisseur',
    customer: 'Client',
    random: 'Aléatoire',
    scan: 'Scanner',
    enter: 'Connexion en scannant le code-barres',
    terms: {
        text: 'En continuant, vous acceptez la',
        link: 'Politique de confidentialité'
    },
    others: 'Autres',
    calendar: {
        presets: {
            today: "Aujourd'hui",
            yesterday: 'Hier',
            last_7_days: 'Derniers 7 jours',
            last_30_days: 'Derniers 30 jours',
            this_month: 'Ce mois-ci',
            last_month: 'Le mois dernier',
            custom_range: 'Plage personnalisée'
        }
    },
    print_large: 'Imprimer grand (A4)',
    print_dot_matrix: 'Impression Grande (Matricielle)',
    print_medium: 'Imprimer moyen (75mm)',
    print_small: 'Imprimer petit (48mm)',
    view_invoice: 'Facture',
    price: 'Prix',
    stock: 'Stock',
    quantity: 'Quantité',
    total: 'Total',
    print_footer_left: 'Créé par',
    print_footer_center: 'Imprimé le',
    print_footer_date: 'le',
    print_to: 'À',
    language: 'Français',
    dateStatus: 'Derniers 7 jours',
    placeholder: 'Type de transaction',
    staffHistory: 'Sélectionner le personnel',
    languageTitle: 'Français',
    date: 'Date',
    download: 'Télécharger',
    download_excel: 'Télécharger Excel',
    download_pdf: 'Télécharger le Code-Barres',
    currentTitle: 'Devise',
    Selectperiod: 'Sélectionner la période',
    TransactionType: 'Type de transaction',
    SelectCustomer: 'Sélectionner le client',
    invoice: 'Facture',
    login: {
        google: 'Se connecter avec Google',
        apple: 'Se connecter avec Apple'
    },
    trusted_by: {
        title: "Fidélé par plus de 50 000 entreprises \n à travers l'Indonésie 🇮🇩"
    },
    privacyPolicyOne: 'En vous connectant, vous acceptez la',
    privacyPolicyTwo: 'Politique de confidentialité',
    privacyPolicyThree: 'de Simply.',
    manufacture: 'Fabrication',
    warehouse: 'Entrepôts'
}

export default common
