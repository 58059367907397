import common from './common'
import featuresPay from './feature'

const ml = {
    featuresPay,
    common,
    short: {
        finance: 'ഫിനാൻസ്',
        stock: 'സ്റ്റോക്ക്'
    },
    onboarding: {
        welcome: 'സ്വാഗതം!',
        step: {
            product: {
                title: '65% പൂർത്തിയായി',
                description: 'നിങ്ങളുടെ വലിയ വിജയത്തിന്റെ ആരംഭിക്കാൻ വെറും 2 ഘട്ടങ്ങൾ ബാക്കി!'
            },
            stock: {
                title: '95% ഒട്ടും പൂർത്തിയായിട്ടില്ല!!',
                description: 'നിങ്ങളുടെ വലിയ വിജയത്തിന്റെ ആരംഭിക്കാൻ വെറും ഒരു ഘട്ടം ബാക്കി! ഇപ്പോൾ പൂർത്തിയാക്കുക.'
            }
        },
        business: {
            title: 'ബിസിനസ് വിജയകരമായി സൃഷ്ടിച്ചു!',
            description: 'ശ്രേഷ്ടം! നിങ്ങളുടെ ബിസിനസ് രജിസ്റ്റർ ചെയ്തിരിക്കുന്നു'
        },
        product: {
            title: 'നിങ്ങളുടെ ആദ്യ ഉൽപ്പന്നം ചേർക്കുക',
            description: 'സ്റ്റോക്ക് രേഖപ്പെടുത്താൻ ഉൽപ്പന്നം ചേർക്കുക'
        },
        stock: {
            title: 'സ്റ്റോക്ക് ചേർക്കാൻ ശ്രമിക്കുക',
            description: 'വരുന്നവയും പോകുന്നവയുമായ സ്റ്റോക്ക് രേഖപ്പെടുത്താൻ ആരംഭിക്കുക'
        },
        action: {
            add_business: {
                title: 'ബിസിനസ് സൃഷ്ടിക്കുക',
                description: 'ഒരു പുതിയ ബിസിനസ് സൃഷ്ടിക്കണോ? ഇവിടെ ക്ലിക്ക് ചെയ്യുക'
            },
            join_business: {
                title: 'ബിസിനസിലേക്ക് ചേരുക',
                description: 'ഒരു ക്ഷണകോഡ് ഉണ്ടോ? ഇവിടെ ചേരുക'
            }
        }
    },
    business: {
        title: 'ഒരു ബിസിനസ് തിരഞ്ഞെടുക്കുക, പുതിയതായി സൃഷ്ടിക്കുക, അല്ലെങ്കിൽ ചേരുക',
        add_business: {
            title: 'പുതിയ ബിസിനസ് സൃഷ്ടിക്കുക',
            name: 'ബിസിനസ് പേര്',
            name_placeholder: 'പേര് നൽകുക',
            category: 'ബിസിനസ് വിഭാഗം',
            category_placeholder: 'വിഭാഗം തിരഞ്ഞെടുക്കുക',
            button: 'ബിസിനസ് സൃഷ്ടിക്കുക'
        },
        edit_business: {
            title: 'ബിസിനസ് എഡിറ്റ് ചെയ്യുക'
        },
        join_business: {
            title: 'ബിസിനസിലേക്ക് ചേരുക',
            input_code: 'കീഴെ കാണുന്ന ക്ഷണകോഡ് നൽകുക:',
            field_placeholder: 'ക്ഷണകോഡ്',
            confirm_title: 'ചേരുക',
            confirm_text: 'നിങ്ങൾ ടീമിന്റെ ഭാഗമാകുന്നതാണ് '
        },
        no_business: 'ലഭ്യമായ ബിസിനസുകൾ ഇല്ല',
        create_or_join: 'ഒരു ബിസിനസ് സൃഷ്ടിക്കുക അല്ലെങ്കിൽ ചേരുക',
        message: {
            saved: 'ബിസിനസ് വിജയകരമായി സേവ് ചെയ്തു!'
        }
    },
    profile: {
        edit_profile: {
            title: 'പ്രൊഫൈൽ എഡിറ്റ് ചെയ്യുക',
            name: 'പൂർണ്ണ പേര്',
            name_placeholder: 'പൂർണ്ണ പേര് നൽകുക',
            email: 'ഇമെയിൽ',
            phone_number: 'ഫോൺ നമ്പർ'
        },
        message: {
            required_name: 'പേര് ആവശ്യമുണ്ട്'
        }
    },
    product: {
        choose_product: 'ഉൽപ്പന്നം തിരഞ്ഞെടുക്കുക',
        choose_product_empty: 'ഉൽപ്പന്നം തിരഞ്ഞെടുക്കുക ಮತ್ತು അളവ് നൽകുക',
        search: {
            placeholder: 'പേര് ഉപയോഗിച്ച് തിരയുക',
            tooltip: 'സ്കാനർ തുറക്കുക'
        },
        column: {
            image: 'ഫോട്ടോ',
            name: 'പേര്',
            price: 'വില',
            buying_price: 'വിലാസം',
            selling_price: 'വിൽപ്പന വില',
            sku: 'SKU',
            stock: 'സ്റ്റോക്ക്',
            quantity: 'അളവ്',
            latest_stock: 'ശേഷിക്കുന്നത്',
            product_deleted: 'ഉൽപ്പന്നം ഇല്ലാതാക്കിയിരിക്കുന്നു',
            info_not_found: 'വിവരം ലഭ്യമല്ല',
            expired_date: 'കാലഹരണമുള്ള തീയതി'
        },
        no_product: 'ലഭ്യമായ ഉൽപ്പന്നങ്ങൾ ഇല്ല',
        add_product: {
            sku: 'ഉൽപ്പന്ന കോഡ്',
            sku_placeholder: 'ഉൽപ്പന്ന കോഡ്. ഉദാഹരണം: 12345',
            name: 'ഉൽപ്പന്നത്തിന്റെ പേര്',
            name_placeholder: 'ഉൽപ്പന്നത്തിന്റെ പേര് നൽകുക',
            initial_stock: 'ആദിമ സ്റ്റോക്ക്',
            initial_stock_placeholder: 'ആദിമ സ്റ്റോക്ക് നൽകുക',
            buying_price: 'വിലാസം',
            buying_price_placeholder: 'വിലാസം നൽകുക',
            selling_price: 'വിൽപ്പന വില',
            selling_price_placeholder: 'വിൽപ്പന വില നൽകുക',
            category: 'വിഭാഗം',
            category_placeholder: 'വിഭാഗം തിരഞ്ഞെടുക്കുക',
            add_selling_price: 'ഹോൾസേൽ വില ചേർക്കുക',
            expired_date: 'കാലഹരണമുള്ള തീയതി',
            expired_date_placeholder: 'തീയതി തിരഞ്ഞെടുക്കുക',
            description: 'വിവരണം',
            description_placeholder: 'വിവരണം നൽകുക'
        },
        choose_selling_price: 'വിൽപ്പന വില തിരഞ്ഞെടുക്കുക',
        selling_price: 'വിൽപ്പന വില',
        total_product: 'മൊത്തം ഉൽപ്പന്നങ്ങൾ',
        total_quantity: 'മൊത്തം സ്റ്റോക്ക്',
        total_buying_price: 'മൊത്തം വിലാസം',
        total_selling_price: 'മൊത്തം വിൽപ്പന വില',
        message: {
            required_name: 'ഉൽപ്പന്നത്തിന്റെ പേര് ആവശ്യമുണ്ട്',
            required_sku: 'SKU ആവശ്യമുണ്ട്',
            required_category: 'വിഭാഗം ആവശ്യമുണ്ട്',
            required_quantity: 'ആദിമ സ്റ്റോക്ക് ആവശ്യമുണ്ട്',
            invalid_input_qty: 'അവസാനത്തിലുള്ള സ്റ്റോക്ക് അളവ് അസാധുവാണ്',
            required_buying_price: 'വിലാസം ആവശ്യമുണ്ട്',
            required_selling_price: 'വിൽപ്പന വില ആവശ്യമുണ്ട്',
            required_selling_price_name: 'വിൽപ്പന വിലയുടെ പേര് ആവശ്യമുണ്ട്',
            required_selling_price_price: 'വിൽപ്പന വില ആവശ്യമുണ്ട്'
        },
        viewMore: 'കൂടുതൽ കാണുക',
        WarehouseDetails: 'ഗോഡൗൺ വിശദാംശങ്ങൾ',
        WarehouseName: 'ഗോഡൗൺ പേര്',
        AddWarehouse: 'ഗോഡൗൺ ചേർക്കുക',
        WarehouseQty: 'ഗോഡൗൺ അളവ്',
        Warehouse: 'ഗോഡൗൺ',
        Warehouses: 'ഗോഡൗണുകൾ',
        Variants: 'വേരിയന്റുകൾ',
        VariantName: 'വേരിയന്റ് പേര്',
        productVariant: 'ഉൽപ്പന്ന വേരിയന്റുകൾ',
        AddVariant: 'വേരിയന്റ് ചേർക്കുക',
        TranferItem: 'ഇനം മാറ്റുക',
        AddUnit: 'പുതിയ യൂണിറ്റ്',
        InitialStkWarehouse: 'വേരിയന്റുകൾ ചേർക്കാൻ പ്രാഥമിക സ്റ്റോക്ക് ആവശ്യമാണ്',
        InitialStockinwarehouse: 'ഗോഡൗണിലെ പ്രാഥമിക സ്റ്റോക്ക്',
        TotalQuantity: 'മൊത്തം അളവ്:',
        AddNote: 'കുറിപ്പ് ചേർക്കുക (ചെലവ് വില)',
        AddNotetitle: 'കുറിപ്പ് ചേർക്കുക',
        VariantSuccessMessage: 'ഉൽപ്പന്ന വേരിയന്റ് വിജയകരമായി അപ്ഡേറ്റ് ചെയ്തു',
        Next: 'അടുത്തത്',
        Submit: 'സമർപ്പിക്കുക',
        INACTIVE: 'നിഷ്ക്രിയം',
        errorone: 'അയ്യോ! അഭ്യർത്ഥിച്ച അളവ്',
        errortwo:
            'നിയമിതമായ ഗോഡൗൺ അളവിനൊപ്പം പൊരുത്തപ്പെടുന്നില്ല. ദയവായി അളവ് ക്രമീകരിക്കുക അല്ലെങ്കിൽ സ്റ്റോക്ക് കൂട്ടുക.',
        SelectWarehouse: 'ഗോദാം തിരഞ്ഞെടുക്കുക',
        SelectVariant: 'വേരിയന്റ് തിരഞ്ഞെടുക്കുക',
        Discount: 'താഴവില',
        DiscountDetails: 'താഴവില വിശദാംശങ്ങൾ',
        FinalPrice: 'അന്ത്യ വില',
        DiscountType: 'താഴവില തരം',
        DiscountPer: 'താഴവില %',
        DiscountValue: 'താഴവില മൂല്യം',
        Discountonprice: 'വിലക്കിഴിവ്',
        Discountondiscount: 'കിഴിവിന്മേൽ കിഴിവ്',
        AdditionalDiscount: 'അധിക കിഴിവ്',
        SellingPrice: 'വിൽപ്പന വില:',
        Save: 'സംരക്ഷിക്കുക',
        OnOriginalPrice: 'ആദ്യ വിലയിൽ',
        OnDiscountedPrice: 'കിഴിവ് ലഭിച്ച വിലയിൽ'
    },
    note: {
        title: 'കുറിപ്പുകൾ',
        add: 'കുറിപ്പ് ചേർക്കുക',
        no_note: 'ലഭ്യമായ കുറിപ്പുകൾ ഇല്ല'
    },
    settings: {
        category: 'വിഭാഗം',
        language: 'ഭാഷ',
        switch_business: 'ബിസിനസ് മാറ്റുക',
        currencySelect: 'കറൻസി'
    },
    category: {
        category_list: {
            title: 'ഉൽപ്പന്ന വിഭാഗങ്ങൾ'
        },
        add_category: {
            button: 'പുതിയ വിഭാഗം',
            title: 'പുതിയ വിഭാഗം ചേർക്കുക'
        },
        edit_category: {
            title: 'വിഭാഗം എഡിറ്റ് ചെയ്യുക'
        },
        name: 'വിഭാഗത്തിന്റെ പേര്',
        name_placeholder: 'വിഭാഗത്തിന്റെ പേര് നൽകുക',
        no_category: 'ലഭ്യമായ വിഭാഗങ്ങൾ ഇല്ല'
    },
    staff: {
        add: 'സ്റ്റാഫ് ചേർക്കുക',
        edit: 'സ്റ്റാഫ് എഡിറ്റ് ചെയ്യുക',
        access: {
            stock: 'സ്റ്റോക്ക്',
            add_product: 'പുതിയ ഉൽപ്പന്നം ചേർക്കുക',
            edit_product: 'ഉൽപ്പന്നം എഡിറ്റ് ചെയ്യുക',
            delete_product: 'ഉൽപ്പന്നം ഇല്ലാതാക്കുക',
            view_capital_price: 'വിലാസം കാണുക',
            add_category: 'പുതിയ വിഭാഗം ചേർക്കുക',
            edit_category: 'വിഭാഗം എഡിറ്റ് ചെയ്യുക',
            delete_category: 'വിഭാഗം ഇല്ലാതാക്കുക',
            add_supplier: 'പുതിയ വിൽപ്പനക്കാരൻ ചേർക്കുക',
            edit_supplier: 'വിൽപ്പനക്കാരൻ എഡിറ്റ് ചെയ്യുക',
            delete_supplier: 'വിൽപ്പനക്കാരൻ ഇല്ലാതാക്കുക',
            add_customer: 'പുതിയ ഉപഭോക്താവ് ചേർക്കുക',
            edit_customer: 'ഉപഭോക്താവ് എഡിറ്റ് ചെയ്യുക',
            delete_customer: 'ഉപഭോക്താവ് ഇല്ലാതാക്കുക',
            add_staff: 'സ്റ്റാഫ് ചേർക്കുക',
            edit_staff: 'സ്റ്റാഫ് എഡിറ്റ് ചെയ്യുക',
            delete_staff: 'സ്റ്റാഫ് ഇല്ലാതാക്കുക',
            change_business_profile: 'ബിസിനസ് പ്രൊഫൈൽ മാറ്റുക'
        },
        no_staff: 'ലഭ്യമായ സ്റ്റാഫ് ഇല്ല.'
    },
    code: {
        title: 'പുതിയ സ്റ്റാഫ് ചേർക്കപ്പെട്ടു!',
        subtitle: 'ചേരാൻ ക്ഷണ കോഡ് താഴെ പങ്കുവെക്കുക:',
        valid: 'കോഡ് 48 മണിക്കൂർ മാത്രം സാധുവാണ്',
        share: 'വാട്സ്ആപ്പിൽ പങ്കുവയ്ക്കുക',
        copied: 'കോപിയ്ക്കപ്പെട്ടത്!',
        invalid: 'സാധുവായ കോഡ് ഇല്ല!'
    },
    history: {
        no_history: 'ചരിത്വം ഇല്ല.',
        detail: {
            remove: 'ചരിത്വം ഇല്ലാതാക്കുക',
            confirm: 'ഈ ഫീച്ചർ നിലവിലുള്ള സ്റ്റോക്ക്, ചരിത്വം, റിപ്പോര്‍ട്ടുകൾ എന്നിവയെ ബാധിക്കും.'
        }
    },
    supplier: {
        add: {
            title: 'വിൽപ്പനക്കാരൻ ചേർക്കുക'
        },
        edit: {
            title: 'വിൽപ്പനക്കാരൻ എഡിറ്റ് ചെയ്യുക'
        },
        new: 'പുതിയ വിൽപ്പനക്കാരൻ',
        no_supplier: 'ലഭ്യമായ വിൽപ്പനക്കാർ ഇല്ല.',
        bank: {
            title: 'ബാങ്ക് വിവരങ്ങൾ'
        },
        note: 'കുറിപ്പുകൾ',
        form: {
            name: {
                label: 'വിൽപ്പനക്കാരന്റെ പേര്',
                placeholder: 'വിൽപ്പനക്കാരന്റെ പേര് രേഖപ്പെടുത്തുക',
                placheolder_not_mandatory: 'പേര് രേഖപ്പെടുത്തുക (ഓപ്ഷണൽ)',
                error: {
                    required: 'പേർ ശൂന്യമാകാനാവില്ല'
                }
            },
            contact: {
                label: 'ബന്ധപ്പെടേണ്ട നമ്പർ',
                placeholder: '91934554432',
                error: {
                    required: 'ബന്ധപ്പെടേണ്ട നമ്പർ ശൂന്യമാകാനാവില്ല',
                    invalid: 'അസാധുവായ നമ്പർ. രാജ്യ കോഡ് ഉൾപ്പെടുത്തണം'
                }
            },
            bank_name: {
                label: 'ബാങ്കിന്റെ പേര്',
                placeholder: 'ബാങ്കിന്റെ പേര് രേഖപ്പെടുത്തുക'
            },
            bank_account_name: {
                label: 'അക്കൗണ്ട് ഉടമയുടെ പേര്',
                placeholder: 'അക്കൗണ്ട് ഉടമയുടെ പേര് രേഖപ്പെടുത്തുക'
            },
            bank_account_number: {
                label: 'അക്കൗണ്ട് നമ്പർ',
                placeholder: 'അക്കൗണ്ട് നമ്പർ രേഖപ്പെടുത്തുക'
            },
            note: {
                label: 'കുറിപ്പുകൾ',
                placeholder: 'കുറിപ്പുകൾ ചേർക്കുക'
            }
        },
        choose: 'വിൽപ്പനക്കാരനെ തിരഞ്ഞെടുക്കുക'
    },
    customer: {
        add: {
            title: 'വിൽപ്പനക്കാരനെ ചേർക്കുക'
        },
        edit: {
            title: 'വിൽപ്പനക്കാരനെ എഡിറ്റ് ചെയ്യുക'
        },
        new: 'പുതിയ വിൽപ്പനക്കാരൻ',
        no_customer: 'വിൽപ്പനക്കാരൻമാർ ലഭ്യമല്ല.',
        note: 'കുറിപ്പുകൾ',
        form: {
            name: {
                label: 'വിൽപ്പനക്കാരന്റെ പേര്',
                placeholder: 'വിൽപ്പനക്കാരന്റെ പേര് രേഖപ്പെടുത്തുക',
                placheolder_not_mandatory: 'പേര് രേഖപ്പെടുത്തുക (ഓപ്ഷണൽ)',
                error: {
                    required: 'പേർ ശൂന്യമാകാനാവില്ല'
                }
            },
            contact: {
                label: 'ബന്ധപ്പെടേണ്ട നമ്പർ',
                placeholder: '91934554432',
                error: {
                    required: 'ബന്ധപ്പെടേണ്ട നമ്പർ ശൂന്യമാകാനാവില്ല',
                    invalid: 'അസാധുവായ നമ്പർ. രാജ്യ കോഡ് ഉൾപ്പെടുത്തണം'
                }
            },
            address: {
                label: 'വിലാസം',
                placeholder: 'വിലാസം രേഖപ്പെടുത്തുക'
            },
            note: {
                label: 'കുറിപ്പുകൾ',
                placeholder: 'കുറിപ്പുകൾ ചേർക്കുക'
            }
        },
        choose: 'വിൽപ്പനക്കാരനെ തിരഞ്ഞെടുക്കുക',
        report: {
            view: 'വിൽപ്പനക്കാരൻ റിപ്പോർട്ട് കാണുക',
            title: 'വിൽപ്പനക്കാരൻ റിപ്പോർട്ട്',
            no_report: 'തദ്ദേശത്തിൽ വിൽപ്പനക്കാർക്കായുള്ള റിപ്പോർട്ടുകൾ ഇല്ല.',
            column: {
                name: 'പേര്',
                total_transaction: 'ആകെ ഇടപാടുകൾ',
                total_amount: 'ആകെ വിൽപ്പന',
                total_profit: 'ആകെ ലാഭം'
            }
        }
    },
    confirm_delete: 'നിങ്ങൾ ഈ ഡാറ്റ നശിപ്പിക്കാൻ ഉറപ്പുള്ളതാണോ?',
    report: {
        title: 'റിപോർട്ട്'
    },
    accounting: {
        title: 'അക്കൗണ്ടിംഗ്',
        view_report: 'ഫിനാൻഷ്യൽ റിപ്പോർട്ട് കാണുക',
        dashboard_title: 'ഫിനാൻഷ്യൽ റിപ്പോർട്ട്',
        stats: 'സാങ്കേതിക വിവരങ്ങൾ',
        transaction_report: 'ലേലങ്ങളുടെയും റിപ്പോർട്ട്',
        filter: {
            date: {
                placeholder: 'തീയതി തിരഞ്ഞെടുക്കുക',
                submit: 'ലേലത്തിൽ നടപ്പാക്കുക',
                cancel: 'ഫിൽട്ടർ പുനഃസ്ഥാപിക്കുക'
            },
            timeframe: {
                day: 'ദിവസം',
                week: 'ആഴ്ച',
                month: 'മാസം',
                year: 'വർഷം'
            }
        },
        total_sales: 'ആകെ വിൽപ്പന',
        total_purchases: 'ആകെ വാങ്ങലുകൾ',
        total_profit: 'ആകെ ലാഭം',
        assets_value: 'നിലവിലെ ആസ്തി മൂല്യം',
        table: {
            date: 'തീയതി',
            sales: 'വിൽപ്പന',
            purchases: 'വാങ്ങലുകൾ',
            profit: 'ലാഭം'
        },
        no_data: 'കൈവശമുള്ള വിവരങ്ങൾ ഇല്ല.'
    },
    stock_report: {
        title: 'സ്റ്റോക്ക് റിപ്പോർട്ട്',
        view: 'സ്റ്റോക്ക് റിപ്പോർട്ട് കാണുക',
        no_report: 'തിരഞ്ഞെടുത്ത തീയതി അല്ലെങ്കിൽ സ്റ്റാഫിന് സ്റ്റോക്ക് രേഖകൾ ഉണ്ടല്ലേ.',
        allStaffPlaceHolder: 'എല്ലാ സ്റ്റാഫും',
        column: {
            total_product: 'വഴി ഉള്ള ഉൽപ്പന്ന തരം',
            total_product_quantity: 'ആകെ നിലവിലെ ഉൽപ്പന്നങ്ങൾ'
        }
    },
    subscription: {
        paywall_small: {
            title: 'VIP സവിശേഷത',
            description:
                'ഈ സവിശേഷതയ്ക്ക് വേണ്ടി നിങ്ങളുടെ ട്രയൽ കാലയളവ് അവസാനിച്ചു. ഈ പ്രാദേശിക ആപ്പിന്റെ വികസനത്തെ പിന്തുണയ്ക്കാൻ സബ്സ്ക്രൈബ് ചെയ്യുക.',
            button: 'സബ്സ്ക്രിപ്ഷൻ പ്ലാനുകൾ കാണുക'
        }
    },
    payment: {
        order: {
            title: 'പേയ്മെന്റ്'
        }
    },
    imageUploadMessage: {
        uploadSize: 'ചിത്രത്തിന്റെ വലിപ്പം 5MB നികത്താതിരിക്കുക!',
        failedMessage: 'ഇടത്ത് ചിത്രം അപ്‌ലോഡ് ചെയ്യാൻ പരാജയപ്പെട്ടു, ദയവായി വീണ്ടും ശ്രമിക്കുക!',
        successUpload: 'ചിത്രം വിജയകരമായി അപ്‌ലോഡ് ചെയ്യപ്പെട്ടു!',
        failedUIError: 'ചിത്രം മുറിക്കുവാൻ പരാജയപ്പെട്ടു, ദയവായി വീണ്ടും ശ്രമിക്കുക!',
        SuccessErrorImage: 'ചിത്രം വിജയകരമായി മുറിക്കപ്പെട്ടു!'
    },
    productColumns: {
        SKULabel: 'SKU',
        nameLabel: 'ഉൽപ്പന്നത്തിന്റെ പേര്',
        categoryLabel: 'വിഭാഗം',
        BuyingPriceLabel: 'വാങ്ങൽ വില',
        SellingLabel: 'വിൽപ്പന വില',
        StockLabel: 'സ്റ്റോക്ക്'
    },
    historyColumns: {
        Transactionlabel: 'ഇടപാട് തരം',
        Locallabel: 'പ്രാദേശിക സമയം',
        Createdlabel: 'സൃഷ്ടിച്ചത്',
        Vendorlabel: 'വിൽപ്പനക്കാരന്റെ പേര്',
        Buyerlabel: 'വിൽപ്പനക്കാരന്റെ പേര്',
        SKUlabel: 'SKU',
        Productlabel: 'ഉൽപ്പന്നത്തിന്റെ പേര്',
        Categorylabel: 'വിഭാഗം',
        Buyinglabel: 'വാങ്ങൽ വില',
        Sellinglabel: 'വിൽപ്പന വില',
        Initiallabel: 'ആദ്യ സ്റ്റോക്ക്',
        Finallabel: 'അവസാന സ്റ്റോക്ക്',
        Stocklabel: 'സ്റ്റോക്ക് മാറ്റം'
    },
    stockReportColumns: {
        skuLabel: 'SKU',
        productNameLabel: 'ഉൽപ്പന്നത്തിന്റെ പേര്',
        categoryLabel: 'വിഭാഗം',
        buyingPriceLabel: 'വാങ്ങൽ വില',
        sellingPriceLabel: 'വിൽപ്പന വില',
        stockInLabel: 'സ്റ്റോക്ക് ഉൾപ്പെടുത്തുക',
        stockOutLabel: 'സ്റ്റോക്ക് പുറത്ത്',
        remainingStockLabel: 'ശേഷിച്ച സ്റ്റോക്ക്'
    },
    accountingReportColumns: {
        labelDate: 'തിയതി',
        labelSales: 'വിൽപ്പന',
        labelPurchases: 'വാങ്ങലുകൾ',
        labelProfit: 'ലാഭം'
    },
    customerReportColumns: {
        labelName: 'പേര്',
        labelTotalTransaction: 'ആകെ ഇടപാടുകൾ',
        labelTotalSales: 'ആകെ വിൽപ്പന',
        labelTotalProfit: 'ആകെ ലാഭം'
    },
    scannerDialog: {
        finished: 'ശേഷിച്ചു',
        reduce: 'കുറയ്ക്കുക',
        successfull: 'വിജയകരമായ',
        searching: 'തിരയുന്നു...',
        title: 'സ്കാനർ സജീവമാണ്. ബാർക്കോഡ്‌ ആകയിലേക്ക് സൂം ചെയ്യുക!'
    },
    historyFilter: {
        SelectBuyer: 'വിൽപ്പനക്കാരനെ തിരഞ്ഞെടുക്കുക',
        SelectVendor: 'വിൽപ്പനക്കാരനെ തിരഞ്ഞെടുക്കുക'
    },
    productStockFilter: {
        StockLowtoHigh: 'സ്റ്റോക്ക് കുറഞ്ഞത് മുതൽ ഉയർന്നത് വരെ',
        StockHightoLow: 'സ്റ്റോക്ക് ഉയർന്നത് മുതൽ കുറഞ്ഞത് വരെ',
        AlphabetAZ: 'അക്ഷരമാല എ-ശേഷം ജെ',
        AlphabetZA: 'അക്ഷരമാല ജെ-ശേഷം എ'
    },
    minimizeTitle: 'സ്കാനർ സജീവമാണ്',
    security_guarantee: {
        title: 'തത്ത്വം സുരക്ഷിതമാണെന്ന് ഉറപ്പ്'
    },
    invoice: {
        invoicePlaceholder: 'ഇൻവോയ്സ് നമ്പർ',
        invoiceTitle: 'ഇൻവോയ്സ്'
    },
    stocklowreport: {
        no_stocklowreport_one: 'സുരക്ഷിത സ്റ്റോക്ക് ക്രമീകരണങ്ങളോടെയുള്ള ഇനങ്ങൾ ഒന്നുമില്ല.',
        no_stocklowreport_two: 'ദയവായി സുരക്ഷിത സ്റ്റോക്ക് അളവ് ചേർക്കുക.'
    },
    low_alert_stock: 'സുരക്ഷിത സ്റ്റോക്ക് അളവ് നൽകുക',
    lowStockAlertNotify: 'സ്റ്റോക്ക് നിശ്ചിത അളവിന് താഴെയായാൽ നിങ്ങൾക്ക് അറിയിപ്പ് ലഭിക്കും.',
    applyButtonStockAlert: 'പ്രയോഗിക്കുക',
    cancelButtonStockAlert: 'റദ്ദാക്കുക',
    searchProductLabel: 'ഉൽപ്പന്നം തിരയുക...',
    successfullyAddedSafetyStock: 'സുരക്ഷിത സ്റ്റോക്ക് വിജയകരമായി അപ്ഡേറ്റ് ചെയ്തു',
    safety_Stock: 'സുരക്ഷ സ്റ്റോക്ക്',
    safety_stock_placeholder: 'സുരക്ഷ സ്റ്റോക്ക് നൽകുക',
    lowStockDetailsInfo: {
        ProductCode: 'ഉൽപ്പന്ന കോഡ്',
        SafetyStock: 'സേഫ്റ്റി സ്റ്റോക്ക്',
        Stock: 'സ്റ്റോക്ക്',
        CostPrice: 'ചെലവിന്റെ വില',
        SellingPrice: 'വില്പന വില',
        Category: 'വിഭാഗം',
        Description: 'വിവരണം',
        ExpirationDate: 'കാലഹരണപ്പെടുന്ന തീയതി'
    },
    CustomLang: {
        Attribute: 'ഗുണം',
        AddAttribute: 'ഗുണം ചേർക്കുക',
        EditAttribute: 'ഗുണം തിരുത്തുക',
        CustomLabePlaceholder: 'ഗുണത്തിന്റെ പേര് നൽകുക',
        CustomLabePlaceholderSelect: 'തരം തിരഞ്ഞെടുക്കുക',
        AddMoreAttribute: 'കൂടുതൽ ഗുണങ്ങൾ ചേർക്കുക',
        ItemAttribute: 'ഉൽപ്പന്ന ഗുണം',
        TableName: 'പേര്',
        TableType: 'തരം',
        TableAction: 'പ്രവൃത്തി',
        TableDelete: 'അടയ്ക്കുക',
        TableEdit: 'തിരുത്തുക',
        deleteMessage: 'ഗുണം വിജയകരമായി നീക്കം ചെയ്തു',
        editSuccessMessage: 'ഗുണം വിജയകരമായി അപ്‌ഡേറ്റ് ചെയ്തു',
        AddSuccessMessage: 'ഗുണം വിജയകരമായി ചേർത്തു',
        AddAtt: 'ചേർക്കുക',
        EditAtt: 'തിരുത്തുക',
        SubmitAtt: 'സമർപ്പിക്കുകയാണ്...'
    },
    Expenses: {
        ExpensesTitle: 'ചെലവുകൾ',
        AddExpenses: 'ചെലവുകൾ ചേർക്കുക',
        ExpenseCategory: 'ചെലവ് വിഭാഗം',
        NoExpenseCategory: 'ചെലവുകളുടെ വിഭാഗമില്ല',
        AddExpenseCategory: 'ചെലവ് വിഭാഗം ചേർക്കുക',
        SelectExpenseCategory: 'ചെലവ് വിഭാഗം തിരഞ്ഞെടുക്കുക',
        NoExpenses: 'ചെലവുകൾ ഇല്ല',
        ExpensesSuccess: 'ചെലവ് വിജയകരമായി സൃഷ്ടിച്ചു',
        ExpensesSuccessEdit: 'ചെലവ് വിജയകരമായി അപ്‌ഡേറ്റ് ചെയ്തു',
        ExpensesCategoryName: 'ചെലവ് വിഭാഗത്തിന്റെ പേര്',
        ExpensesSuccessDelete: 'ചെലവ് വിജയകരമായി ഇല്ലാതാക്കി',
        TotalSpentThisWeek: 'ഈ ആഴ്ച മുഴുവൻ ചെലവിച്ചത്'
    },
    Manufacturing: {
        AddManufacture: 'നിർമാണ വിശദാംശങ്ങൾ ചേർക്കുക',
        Manufacturing: 'നിർമാണം',
        RawMaterials: 'അസംസ്കൃത വസ്തുക്കൾ',
        AdditonCosts: 'അനുബന്ധ ചെലവുകൾ',
        RawMaterial: 'അസംസ്കൃത വസ്തു',
        AdditonCost: 'അനുബന്ധ ചെലവ്',
        TotalCost: 'മൊത്തം ചെലവ്',
        AddRawMaterial: 'നിർമാണത്തിന് അസംസ്കൃത വസ്തു ചേർക്കുക',
        AddAdditonCost: 'അനുബന്ധ ചെലവ് ചേർക്കുക',
        AddMore: 'കൂടുതൽ ചേർക്കുക',
        Add: 'ചേർക്കുക',
        Update: 'അപ്ഡേറ്റ് ചെയ്യുക',
        Updating: 'സമർപ്പിക്കുന്നു...',
        ProductRequired: 'ഉൽപ്പന്നം ആവശ്യമാണ്',
        QuantityRequired: 'അളവ് ആവശ്യമാണ്',
        ChargeRequired: 'ചാർജ് ആവശ്യമാണ്',
        EstimatedCostRequired: 'അനുമാനിച്ച ചെലവ് ആവശ്യമാണ്',
        PurchasePriceRequired: 'വാങ്ങൽ വില ആവശ്യമാണ്',
        SelectCharge: 'ചാർജ് തിരഞ്ഞെടുക്കുക',
        EstimatedCost: 'അനുമാനിച്ച ചെലവ്',
        SelectProduct: 'ഉൽപ്പന്നം തിരഞ്ഞെടുക്കുക',
        Quantity: 'അളവ്',
        ManufactureDetails: 'നിർമാണ വിശദാംശങ്ങൾ',
        BuyingPrice: 'വാങ്ങൽ വില',
        LabourCharge: 'തൊഴിലാളി ചാർജ്',
        ElectricityCost: 'കറന്റ് ചെലവ്',
        PackagingCharge: 'പാക്കേജിംഗ് ചാർജ്',
        LogisticCost: 'ലോജിസ്റ്റിക് ചെലവ്',
        OtherCharges: 'മറ്റ് ചെലവുകൾ',
        Save: 'വിശദാംശങ്ങൾ സംരക്ഷിക്കുക',
        PurchasePrice: 'വാങ്ങൽ വില',
        Date: 'തീയതി',
        For: 'വേണ്ടി',
        PurchaseRate: 'വാങ്ങൽ നിരക്ക് (വില)',
        Scrolltoloadmore: 'കൂടുതൽ ലോഡ് ചെയ്യാൻ സ്ക്രോൾ ചെയ്യുക...',
        Noproductsfound: 'ഉൽപ്പന്നങ്ങൾ കണ്ടെത്തിയില്ല',
        ManufacturingQuantity: 'നിർമാണ അളവ്',
        ManufactureAddedSuccess: 'നിർമാണ വിശദാംശങ്ങൾ വിജയകരമായി പുതുക്കിയിട്ടുണ്ട്'
    },
    Warehouses: {
        transferOrder: 'ഇനം മാറ്റുക',
        AddTransferOrder: 'മാറ്റിയ ഇനങ്ങൾ ചേർക്കുക',
        NewWarehouse: 'പുതിയ ഗോഡൗൺ',
        newHead: 'ഒന്നിലധികം ഗോഡൗണുകൾ കൈകാര്യം ചെയ്യാൻ തുടങ്ങൂ!',
        headTwo: 'നിങ്ങളുടെ സ്റ്റോക്ക് എളുപ്പത്തിൽ ട്രാക്ക് ചെയ്യുക, പല ഗോഡൗണുകളിലും നിയന്ത്രിക്കുക',
        EnableWarehouse: 'ഗോഡൗൺ പ്രവർത്തനക്ഷമമാക്കുക',
        EnableWarehouse2: 'ഗോഡൗൺ പ്രവർത്തനക്ഷമമാക്കുക',
        OrganizationPrimary: 'സംഘടനയുടെ പ്രാഥമിക ഗോഡൗൺ',
        Edit: 'തിരുത്തുക',
        AreEnableWarehouse: 'നിങ്ങൾക്ക് ഗോഡൗൺ പ്രവർത്തനക്ഷമമാക്കാൻ ഉറപ്പാണോ?',
        Confirm: 'സ്ഥിരീകരിക്കുക',
        Cancel: 'റദ്ദാക്കുക',
        WarehouseCreatedSuccessfully: 'ഗോഡൗൺ വിജയകരമായി സൃഷ്ടിച്ചു',
        MarkAsOP: 'സംഘടനയുടെ പ്രാഥമികമായി അടയാളപ്പെടുത്തുക',
        MarkAsInAct: 'നിഷ്ക്രിയമാക്കുക',
        MarkAsAct: 'സജീവമാക്കുക',
        MarkedAsInactive: 'ഗോഡൗൺ വിജയകരമായി നിഷ്ക്രിയമായി മാറ്റി',
        MarkedAsActive: 'ഗോഡൗൺ വിജയകരമായി സജീവമാക്കി',
        MarkedAsOP: 'ഗോഡൗൺ വിജയകരമായി പ്രാഥമികമായി അടയാളപ്പെടുത്തി',
        WareDeletedSuccess: 'ഗോഡൗൺ വിജയകരമായി നീക്കം ചെയ്തു',
        SuccessfullyUpdatedWarehouse: 'ഗോഡൗൺ വിജയകരമായി അപ്ഡേറ്റ് ചെയ്തു',
        SuccessfullyCreatedWarehouse: 'ഗോഡൗൺ വിജയകരമായി സൃഷ്ടിച്ചു',
        EditWarehouse: 'ഗോഡൗൺ തിരുത്തുക',
        AddWarehouse: 'ഗോഡൗൺ ചേർക്കുക',
        WarehouseNameIsRequired: 'ഗോഡൗൺ പേര് ആവശ്യമാണ്',
        EnterWarehouseName: 'ഗോഡൗൺ പേര് നൽകുക',
        EnterEmail: 'ഇമെയിൽ നൽകുക',
        EnterPhoneNumber: 'ഫോൺ നമ്പർ നൽകുക',
        Location: 'സ്ഥലം...',
        name: 'ഗോഡൗൺ പേര്',
        email: 'ഇമെയിൽ',
        phoneNumber: 'ഫോൺ നമ്പർ',
        location: 'സ്ഥലം'
    }
}

export default ml
