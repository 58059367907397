const featuresPay = {
    featureTitle: 'Make Business Decisions Based on Data',
    featureDescription:
        'Know which items are selling and providing the most profit, and access financial reports anytime, anywhere. Your business in the palm of your hand!',
    featureButton: 'Premium Subscription from the App',
    featureStartingPrice: '80',
    featureEndingPart: '30',
    featureStaringText: 'Starting from',
    featureEndingText: 'Thousand',

    features: [
        {
            title: 'Unlimited Items',
            description: 'Add all the items you own without any limits.'
        },
        {
            title: 'Unlimited Staff',
            description: 'Add Staff/Admin to the business without any limits.'
        },
        {
            title: 'Unlimited History',
            description: 'View changes in old stock records without any limits.'
        },
        {
            title: 'History Filter',
            description: 'Easily view history based on type, category, staff, and date.'
        },
        {
            title: 'Download to Excel',
            description: 'Get stock record history into Microsoft Excel with a single click.'
        },
        {
            title: 'Download to PDF',
            description: 'Get the latest items and stock list into PDF with a single click.'
        }
    ],
    warehousesGuide: [
        {
            desc: 'Create multiple warehouses for better stock management.'
        },
        {
            desc: 'Assign products to specific locations.'
        },
        {
            desc: 'Monitor inventory levels across warehouses.'
        }
    ],
    addVariantsGuide: [
        { desc: 'Add size, color, or material variants.' },
        { desc: 'Set stock levels per variant.' },
        { desc: 'Track across warehouses.' }
    ]
}

export default featuresPay
