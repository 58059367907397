import common from './common'
import featuresPay from './feature'

const en = {
    featuresPay,
    common,
    short: {
        finance: 'Finance',
        stock: 'Stock'
    },
    onboarding: {
        welcome: 'Welcome!',
        step: {
            product: {
                title: '65% Achieved',
                description: 'Only 2 steps left to start your great success!'
            },
            stock: {
                title: '95% Almost Done!!',
                description: 'Just one more step to start your great success! Finish it now.'
            }
        },
        business: {
            title: 'Business successfully created!',
            description: 'Great! Your business has been registered'
        },
        product: {
            title: 'Add your first product',
            description: 'Add a product to start recording stock'
        },
        stock: {
            title: 'Try adding stock',
            description: 'Start recording incoming and outgoing stock'
        },
        action: {
            add_business: {
                title: 'Create Business',
                description: 'Want to create a new business? Click here'
            },
            join_business: {
                title: 'Join Business',
                description: 'Have an invitation code? Join here'
            }
        }
    },
    business: {
        title: 'Select a business, create new, or join',
        add_business: {
            title: 'Create new business',
            name: 'Business name',
            name_placeholder: 'Enter name',
            category: 'Business category',
            category_placeholder: 'Select Category',
            button: 'Create Business'
        },
        edit_business: {
            title: 'Edit business'
        },
        join_business: {
            title: 'Join Business',
            input_code: 'Enter the invitation code below:',
            field_placeholder: 'Invitation code',
            confirm_title: 'Join',
            confirm_text: 'You will become part of the team at '
        },
        no_business: 'No business available',
        create_or_join: 'Create or join a business',
        message: {
            saved: 'Business saved successfully!'
        }
    },
    profile: {
        edit_profile: {
            title: 'Edit profile',
            name: 'Full Name',
            name_placeholder: 'Enter full name',
            email: 'Email',
            phone_number: 'Phone Number'
        },
        message: {
            required_name: 'Name is required'
        }
    },
    product: {
        choose_product: 'Choose Product',
        choose_product_empty: 'Select product and enter quantity',
        choose_product_empty_manufacturing: 'Select product and enter manufacturing quantity',
        search: {
            placeholder: 'Search by name',
            tooltip: 'Open scanner'
        },
        column: {
            image: 'Photo',
            name: 'Name',
            price: 'Price',
            buying_price: 'Cost Price',
            selling_price: 'Selling Price',
            sku: 'SKU',
            stock: 'Stock',
            quantity: 'Quantity',
            latest_stock: 'Remaining',
            product_deleted: 'Product has been deleted',
            info_not_found: 'Information not available',
            expired_date: 'Expiration Date',
            invoice: 'Inovice'
        },
        no_product: 'No Products Available',
        add_product: {
            sku: 'Product Code',
            sku_placeholder: 'Product Code. Example: 12345',
            name: 'Product Name',
            name_placeholder: 'Enter Product Name',
            initial_stock: 'Initial Stock',
            initial_stock_placeholder: 'Enter Initial Stock',
            buying_price: 'Cost Price',
            buying_price_placeholder: 'Enter Cost Price',
            selling_price: 'Selling Price',
            selling_price_placeholder: 'Enter Selling Price',
            category: 'Category',
            category_placeholder: 'Select Category',
            add_selling_price: 'Add Wholesale Price',
            expired_date: 'Expiration Date',
            expired_date_placeholder: 'Select Date',
            description: 'Description',
            description_placeholder: 'Enter Description',
            custom_label: 'Attribute',
            custom_label_placeholder: 'Enter Key',
            add_custom_label: 'Add More Attribute'
        },
        customerLabel: {},
        choose_selling_price: 'Choose Selling Price',
        selling_price: 'Selling Price',
        total_product: 'Total Products',
        total_quantity: 'Total Stock',
        total_buying_price: 'Total Cost Price',
        total_selling_price: 'Total Selling Price',
        message: {
            required_name: 'Product name is required',
            required_sku: 'SKU is required',
            required_category: 'SKU is required',
            required_quantity: 'Initial stock is required',
            invalid_input_qty: 'Invalid stock quantity',
            required_buying_price: 'Cost price is required',
            required_selling_price: 'Selling price is required',
            required_selling_price_name: 'Selling price name is required',
            required_selling_price_price: 'Selling price is required'
        },
        viewMore: 'View More',
        WarehouseDetails: 'Warehouse Details',
        WarehouseName: 'Warehouse Name',
        AddWarehouse: 'Add Warehouse',
        WarehouseQty: 'Warehouse Quantity',
        Warehouse: 'Warehouse',
        Warehouses: 'Warehouses',
        Variants: 'Variants',
        VariantName: 'Variant Name',
        productVariant: 'Product Variants',
        AddVariant: 'Add Variants',
        TranferItem: 'Transfer Items',
        AddUnit: 'New Unit',
        InitialStkWarehouse: 'Initial Stock required to add variants',
        InitialStockinwarehouse: 'Initial Stock in warehouse',
        TotalQuantity: 'Total Quantity:',
        AddNote: 'Add Note (Cost price)',
        AddNotetitle: 'Add Note',
        VariantSuccessMessage: 'Product Variant updated successfully',
        Next: 'Next',
        Submit: 'Submit',
        INACTIVE: 'INACTIVE',
        errorone: 'Oops! The requested quantity for',
        errortwo: "doesn't match the allocated warehouse quantities. Please adjust the quantity or restock.",
        SelectWarehouse: 'Select Warehouse',
        SelectVariant: 'Select Variant',
        Discount: 'Discount',
        DiscountDetails: 'Discount Details',
        FinalPrice: 'Final Price',
        DiscountType: 'Discount Type',
        DiscountPer: 'Discount %',
        DiscountValue: 'Discount Value',
        Discountonprice: 'Discount on price',
        Discountondiscount: 'Discount on discount',
        AdditionalDiscount: 'Additional Discount',
        SellingPrice: 'Selling Price:',
        Save: 'Save',
        OnOriginalPrice: 'On Original Price',
        OnDiscountedPrice: 'On Discounted Price'
    },
    note: {
        title: 'Notes',
        add: 'Add Note',
        no_note: 'No notes available'
    },
    settings: {
        category: 'Category',
        language: 'Language',
        switch_business: 'Switch Business',
        currencySelect: 'Currency',
        languageChangeSuccess: 'Currency Updated Successfully',
        languageChangeErrorMessage: 'Something Went Wrong While Updating Currency'
    },
    selectProductStock: 'Select Product',
    category: {
        category_list: {
            title: 'Product Categories'
        },
        add_category: {
            button: 'New Category',
            title: 'Add New Category'
        },
        edit_category: {
            title: 'Edit Category'
        },
        name: 'Category Name',
        name_placeholder: 'Enter Category Name',
        no_category: 'No categories available'
    },
    staff: {
        add: 'Add Staff',
        edit: 'Edit Staff',
        access: {
            stock: 'Stock',
            add_product: 'Add new product',
            edit_product: 'Edit product',
            delete_product: 'Delete product',
            view_capital_price: 'View Cost Price',
            add_category: 'Add new category',
            edit_category: 'Edit category',
            delete_category: 'Delete category',
            add_supplier: 'Add new vendor',
            edit_supplier: 'Edit vendor',
            delete_supplier: 'Delete vendor',
            add_customer: 'Add new customer',
            edit_customer: 'Edit customer',
            delete_customer: 'Delete customer',
            add_staff: 'Add staff',
            edit_staff: 'Edit staff',
            delete_staff: 'Delete staff',
            change_business_profile: 'Change business profile'
        },
        no_staff: 'No staff available.'
    },
    code: {
        title: 'New Staff Added!',
        subtitle: 'Share the invitation code below to join:',
        valid: 'Code valid for 48 hours only',
        share: 'Share on WhatsApp',
        copied: 'Copied!',
        invalid: 'Invalid code!'
    },
    history: {
        no_history: 'No history.',
        detail: {
            remove: 'Remove History',
            confirm: 'This feature will affect existing stock, history, and reports.'
        }
    },
    supplier: {
        add: {
            title: 'Add Vendor'
        },
        edit: {
            title: 'Edit Vendor'
        },
        new: 'New vendor',
        no_supplier: 'No vendors available.',
        bank: {
            title: 'Bank Details'
        },
        note: 'Notes',
        form: {
            name: {
                label: 'Vendor Name',
                placeholder: 'Enter Vendor Name',
                placheolder_not_mandatory: 'Enter name (optional)',
                error: {
                    required: 'Name cannot be empty'
                }
            },
            contact: {
                label: 'Contact Number',
                placeholder: '91934554432',
                error: {
                    required: 'Contact number cannot be empty',
                    invalid: 'Invalid number. Must include country code'
                }
            },
            bank_name: {
                label: 'Bank Name',
                placeholder: 'Enter Bank Name'
            },
            bank_account_name: {
                label: 'Account Holder Name',
                placeholder: 'Enter Account Holder Name'
            },
            bank_account_number: {
                label: 'Account Number',
                placeholder: 'Enter Account Number'
            },
            note: {
                label: 'Notes',
                placeholder: 'Add Notes'
            }
        },
        choose: 'Choose Vendor'
    },
    customer: {
        add: {
            title: 'Add Customer'
        },
        edit: {
            title: 'Edit Customer'
        },
        new: 'New customer',
        no_customer: 'No customers available.',
        note: 'Notes',
        form: {
            name: {
                label: 'Customer Name',
                placeholder: 'Enter Customer Name',
                placheolder_not_mandatory: 'Enter name (optional)',
                error: {
                    required: 'Name cannot be empty'
                }
            },
            contact: {
                label: 'Contact Number',
                placeholder: '91934554432',
                error: {
                    required: 'Contact number cannot be empty',
                    invalid: 'Invalid number. Must include country code'
                }
            },
            address: {
                label: 'Address',
                placeholder: 'Enter Address'
            },
            note: {
                label: 'Notes',
                placeholder: 'Add Notes'
            }
        },
        choose: 'Select Buyer',
        report: {
            view: 'View Buyer Report',
            title: 'Customer Report',
            no_report: 'There are no reports for buyers on the selected date.',
            column: {
                name: 'Name',
                total_transaction: 'Total Transactions',
                total_amount: 'Total Sales',
                total_profit: 'Total Profit'
            }
        }
    },
    confirm_delete: 'Are you sure you want to delete this data?',
    report: {
        title: 'Report'
    },
    accounting: {
        title: 'Accounting',
        view_report: 'View Financial Report',
        dashboard_title: 'Financial Report',
        stats: 'Statistics',
        transaction_report: 'Transaction Report',
        filter: {
            date: {
                placeholder: 'Select Date',
                submit: 'Apply',
                cancel: 'Reset Filter'
            },
            timeframe: {
                day: 'Daily',
                week: 'Weekly',
                month: 'Monthly',
                year: 'Yearly'
            }
        },
        total_sales: 'Total Sales',
        total_purchases: 'Total Purchases',
        total_profit: 'Total Profit',
        assets_value: 'Current Asset Value',
        table: {
            date: 'Date',
            sales: 'Sales',
            purchases: 'Purchases',
            profit: 'Profit'
        },
        no_data: 'No data available.'
    },
    stock_report: {
        title: 'Stock Report',
        view: 'View Stock Report',
        no_report: 'No stock records for the selected date or staff.',
        allStaffPlaceHolder: 'All Staff',
        column: {
            total_product: 'Current Product Types',
            total_product_quantity: 'Total Current Products'
        }
    },
    subscription: {
        paywall_small: {
            title: 'VIP Feature',
            description:
                'Your trial period for this feature has ended. Support the development of this local app by subscribing.',
            button: 'View Subscription Plans'
        }
    },
    payment: {
        order: {
            title: 'Payment'
        }
    },
    imageUploadMessage: {
        uploadSize: 'The image size must not exceed 5MB!',
        failedMessage: 'Failed to upload the item image, please try again!',
        successUpload: 'The image was uploaded successfully!',
        failedUIError: 'Failed to delete the image, please try again!',
        SuccessErrorImage: 'The image has been successfully deleted!'
    },
    productColumns: {
        SKULabel: 'SKU',
        nameLabel: 'Product Name',
        categoryLabel: 'Category',
        BuyingPriceLabel: 'Buying Price',
        SellingLabel: 'Selling Price',
        StockLabel: 'Stock'
    },
    historyColumns: {
        Transactionlabel: 'Transaction Type',
        Locallabel: 'Local Time',
        Createdlabel: 'Created By',
        Vendorlabel: 'Vendor Name',
        Buyerlabel: 'Buyer Name',
        SKUlabel: 'SKU',
        Invoicelabel: 'Invoice',
        Productlabel: 'Product Name',
        Categorylabel: 'Category',
        Buyinglabel: 'Buying Price',
        Sellinglabel: 'Selling Price',
        Initiallabel: 'Initial Stock',
        Finallabel: 'Final Stock',
        Stocklabel: 'Stock Change'
    },
    stockReportColumns: {
        skuLabel: 'SKU',
        productNameLabel: 'Product Name',
        categoryLabel: 'Category',
        buyingPriceLabel: 'Buying Price',
        sellingPriceLabel: 'Selling Price',
        stockInLabel: 'Stock In',
        stockOutLabel: 'Stock Out',
        remainingStockLabel: 'Remaining Stock'
    },
    accountingReportColumns: {
        labelDate: 'Date',
        labelSales: 'Sales',
        labelPurchases: 'Purchases',
        labelProfit: 'Profit'
    },
    customerReportColumns: {
        labelName: 'Name',
        labelTotalTransaction: 'Total Transaction',
        labelTotalSales: 'Total Sales',
        labelTotalProfit: 'Total Profit'
    },
    scannerDialog: {
        finished: 'Finished',
        reduce: 'Reduce',
        successfull: 'Successfull',
        searching: 'Searching...',
        title: 'Scanner active. Point it to the barcode!'
    },
    historyFilter: {
        SelectBuyer: 'Select Buyer',
        SelectVendor: 'Select Vendor'
    },
    productStockFilter: {
        StockLowtoHigh: 'Stock Low to High',
        StockHightoLow: 'Stock High to Low',
        AlphabetAZ: 'Alphabet A-Z',
        AlphabetZA: 'Alphabet Z-A'
    },
    minimizeTitle: 'Scanner active',
    security_guarantee: {
        title: 'Data Security Guaranteed'
    },
    invoice: {
        invoicePlaceholder: 'Invoice Number',
        invoiceTitle: 'Inovice'
    },
    stocklowreport: {
        no_stocklowreport_one: 'No items with Safety Stock settings.',
        no_stocklowreport_two: 'Please add Safety Stock quantity.'
    },
    low_alert_stock: 'Input safety stock quantity',
    lowStockAlertNotify: "You w'll get a notification when the stock drops below the set quantity",
    applyButtonStockAlert: 'Apply',
    cancelButtonStockAlert: 'Cancel',
    searchProductLabel: 'Search products...',
    successfullyAddedSafetyStock: 'Successfully updated Safety Stock',
    safety_Stock: 'Safety Stock',
    safety_stock_placeholder: 'Enter Safety Stock',
    lowStockDetailsInfo: {
        ProductCode: 'Product Code',
        SafetyStock: 'Safety Stock',
        Stock: 'Stock',
        CostPrice: 'Cost Price',
        SellingPrice: 'Selling Price',
        Category: 'Category',
        Description: 'Description',
        ExpirationDate: 'Expiration Date'
    },
    CustomLang: {
        Attribute: 'Attribute',
        AddAttribute: 'Add Attribute',
        EditAttribute: 'Edit Attribute',
        CustomLabePlaceholder: 'Enter Attribute Name',
        CustomLabePlaceholderSelect: 'Select Type',
        AddMoreAttribute: 'Add More Attribute',
        ItemAttribute: 'Item Attribute',
        TableName: 'Name',
        TableType: 'Type',
        TableAction: 'Action',
        TableDelete: 'Delete',
        TableEdit: 'Edit',
        deleteMessage: 'Attribute Deleted Successfully',
        editSuccessMessage: 'Attribute Updated Successfully',
        AddSuccessMessage: 'Attribute Added Successfully',
        AddAtt: 'Add',
        EditAtt: 'Edit',
        SubmitAtt: 'Submitting...'
    },
    Expenses: {
        ExpensesTitle: 'Expenses',
        AddExpenses: 'Add Expenses',
        ExpenseCategory: 'Expense category',
        NoExpenseCategory: 'No expense category',
        AddExpenseCategory: 'Add expense category',
        SelectExpenseCategory: 'Select expense category',
        NoExpenses: 'No Expenses',
        ExpensesSuccess: 'Expenses created successfully',
        ExpensesSuccessEdit: 'Expenses updated successfully',
        ExpensesCategoryName: 'Expenses category name',
        ExpensesSuccessDelete: 'Expenses Deleted successfully'
    },
    Manufacturing: {
        AddManufacture: 'Add Manufacturing Details',
        Manufacturing: 'Manufacturing',
        RawMaterials: 'Raw Materials',
        AdditonCosts: 'Additional Costs',
        RawMaterial: 'Raw Material',
        AdditonCost: 'Additional Cost',
        TotalCost: 'Total Cost',
        AddRawMaterial: 'Add raw material for',
        AddAdditonCost: 'Add additional cost for',
        AddMore: 'Add more',
        Add: 'Add',
        Update: 'Update',
        Updating: 'Submitting...',
        ProductRequired: 'Product is required',
        QuantityRequired: 'Quantity is required',
        ChargeRequired: 'Charge is required',
        EstimatedCostRequired: 'Estimated Cost is required',
        PurchasePriceRequired: 'Purchase Price is required',
        SelectCharge: 'Select Charge',
        EstimatedCost: 'Estimated Cost',
        SelectProduct: 'Select a Product',
        Quantity: 'Quantity',
        ManufactureDetails: 'Manufacturing Details',
        BuyingPrice: 'Buying Price',
        LabourCharge: 'Labour Charge',
        ElectricityCost: 'Electricity Cost',
        PackagingCharge: 'Packaging Charge',
        LogisticCost: 'Logistic Cost',
        OtherCharges: 'Other Charges',
        Save: 'Save details',
        PurchasePrice: 'Purchase Price',
        Date: 'Date',
        For: 'For',
        PurchaseRate: 'Purchase Rate (Price)',
        Scrolltoloadmore: 'Scroll to load more...',
        Noproductsfound: 'No products found',
        ManufacturingQuantity: 'Manufacturing Quantity',
        ManufactureAddedSuccess: 'Manufacturing Details updated successfully'
    },
    Warehouses: {
        transferOrder: 'Transfer Items',
        AddTransferOrder: 'Add Transfer Items',
        NewWarehouse: 'New Warehouse',
        newHead: 'Start managing multiple warehouses!',
        headTwo: 'Seamlessly track and manage your inventory across multiple warehouses',
        EnableWarehouse: 'ENABLE WAREHOUSE',
        EnableWarehouse2: 'Enable Warehouse',
        OrganizationPrimary: "Organization's Primary",
        Edit: 'Edit',
        AreEnableWarehouse: 'Are you sure you want to enable warehouse?',
        Confirm: 'Confirm',
        Cancel: 'Cancel',
        WarehouseCreatedSuccessfully: 'Warehouse created successfully',
        MarkAsOP: "Mark as Organization's Primary",
        MarkAsInAct: 'Mark as Inactive',
        MarkAsAct: 'Mark as active',
        MarkedAsInactive: 'Warehouse marked as inactive successfully',
        MarkedAsActive: 'Warehouse marked as active successfully',
        MarkedAsOP: 'Warehouse marked as active successfully',
        WareDeletedSuccess: 'Warehouse deleted successfully',
        SuccessfullyUpdatedWarehouse: 'Successfully updated warehouse',
        SuccessfullyCreatedWarehouse: 'Successfully created warehouse',
        EditWarehouse: 'Edit Warehouse',
        AddWarehouse: 'Add Warehouse',
        WarehouseNameIsRequired: 'Warehouse Name is required',
        EnterWarehouseName: 'Enter Warehouse name',
        EnterEmail: 'Enter email',
        EnterPhoneNumber: 'Enter phone number',
        Location: 'Location...',
        name: 'Warehouse name',
        email: 'Email',
        phoneNumber: 'Phone number',
        location: 'Location'
    },
    TransferItem: {
        transferItem: 'Transfer Items',
        Date: 'Date',
        Reason: 'Reason',
        Name: 'Name',
        QuantityTransfered: 'Quantity Transfered',
        SWarehouse: 'Source Warehouse',
        SelectSWarehouse: 'Select Source Warehouse',
        DWarehouse: 'Destination Warehouse',
        SelectDWarehouse: 'Select Destination Warehouse',
        CreatedBy: 'Created By',
        CreatedTime: 'Created Time',
        UpdatedTime: 'Updated Time',
        NoOrderFound: 'No Orders Found',
        New: 'New',
        WarehouseItemTransfer: 'Warehouse Item Transfer',
        EnterReason: 'Enter Reason',
        ItemDetails: 'Item Details',
        CurrentAvailable: 'Current Availability',
        TransferQty: 'Transfer Quantity',
        SStock: 'Source Stock'
    // TransferQty: '',
    }
}

export default en
