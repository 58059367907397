const TransferOrder = () => {
    return (
        <svg
            fill="#000000"
            height="800px"
            width="800px"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 489.8 489.8"
            xmlSpace="preserve">
            <g>
                <g>
                    <path
                        d="M347.6,3.6c-4.8-4.8-12.5-4.8-17.3,0l-37.1,37.1c-4.8,4.8-4.8,12.5,0,17.3l37.1,37.1c2.4,2.4,5.5,3.6,8.7,3.6
               s6.3-1.2,8.7-3.6c4.8-4.8,4.8-12.5,0-17.3l-16.2-16.2h61.9c39.4,0,71.5,32.1,71.5,71.5v43.8c0,6.8,5.5,12.3,12.3,12.3
               s12.3-5.5,12.3-12.3v-43.8c0-52.9-43.1-96-96-96h-61.9l16.2-16.2C352.4,16.2,352.4,8.4,347.6,3.6z"
                    />
                    <path
                        d="M249.6,263.2v214.3c0,6.8,5.5,12.3,12.3,12.3h214.3c6.8,0,12.3-5.5,12.3-12.3V263.2c0-6.8-5.5-12.3-12.3-12.3H261.8
               C255.1,251,249.6,256.5,249.6,263.2z M274.1,275.5h189.7v189.8H274.1V275.5z"
                    />
                    <path
                        d="M0.3,356.7c0,52.9,43.1,96,96,96h61.9L142,468.9c-4.8,4.8-4.8,12.5,0,17.3c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6
               l37.1-37.1c2.3-2.3,3.6-5.4,3.6-8.7s-1.3-6.4-3.6-8.7l-37.1-37.1c-4.8-4.8-12.5-4.8-17.3,0s-4.8,12.5,0,17.3l16.2,16.2H96.4
               c-39.4,0-71.5-32.1-71.5-71.5v-43.7c0-6.8-5.5-12.3-12.3-12.3s-12.3,5.5-12.3,12.3L0.3,356.7L0.3,356.7z"
                    />
                    <path
                        d="M227.8,0H13.5C6.7,0,1.2,5.5,1.2,12.3v214.3c0,6.8,5.5,12.3,12.3,12.3h214.3c6.8,0,12.3-5.5,12.3-12.3V12.3
               C240,5.5,234.5,0,227.8,0z M215.5,214.3H25.8V24.5h189.8v189.8H215.5z"
                    />
                </g>
            </g>
        </svg>
    )
}

export default TransferOrder
