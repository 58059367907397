import common from './common'
import featuresPay from './feature'

const hi = {
    featuresPay,
    common,
    short: {
        finance: 'वित्त',
        stock: 'स्टॉक'
    },
    onboarding: {
        welcome: 'स्वागत है!',
        step: {
            product: {
                title: '65% प्राप्त हुआ',
                description: 'आपकी महान सफलता की शुरुआत के लिए केवल 2 कदम बाकी हैं!'
            },
            stock: {
                title: '95% लगभग पूरा!!',
                description: 'आपकी महान सफलता की शुरुआत के लिए बस एक कदम और बचा है! अभी इसे समाप्त करें।'
            }
        },
        business: {
            title: 'व्यापार सफलतापूर्वक बनाया गया!',
            description: 'अभिनंदन! आपका व्यापार पंजीकृत हो चुका है।'
        },
        product: {
            title: 'अपना पहला उत्पाद जोड़ें',
            description: 'स्टॉक रिकॉर्ड करने के लिए एक उत्पाद जोड़ें।'
        },
        stock: {
            title: 'स्टॉक जोड़ने की कोशिश करें',
            description: 'आने वाले और जाने वाले स्टॉक को रिकॉर्ड करना शुरू करें।'
        },
        action: {
            add_business: {
                title: 'व्यापार बनाएं',
                description: 'क्या आप एक नया व्यापार बनाना चाहते हैं? यहाँ क्लिक करें।'
            },
            join_business: {
                title: 'व्यापार में शामिल हों',
                description: 'क्या आपके पास एक निमंत्रण कोड है? यहाँ शामिल हों।'
            }
        }
    },

    business: {
        title: 'एक व्यापार चुनें, नया बनाएं, या शामिल हों',
        add_business: {
            title: 'नया व्यापार बनाएं',
            name: 'व्यापार का नाम',
            name_placeholder: 'नाम दर्ज करें',
            category: 'व्यापार श्रेणी',
            category_placeholder: 'श्रेणी चुनें',
            button: 'व्यापार बनाएं'
        },
        edit_business: {
            title: 'व्यापार संपादित करें'
        },
        join_business: {
            title: 'व्यापार में शामिल हों',
            input_code: 'नीचे निमंत्रण कोड दर्ज करें:',
            field_placeholder: 'निमंत्रण कोड',
            confirm_title: 'शामिल हों',
            confirm_text: 'आप टीम का हिस्सा बन जाएंगे '
        },
        no_business: 'कोई व्यापार उपलब्ध नहीं है',
        create_or_join: 'एक व्यापार बनाएं या शामिल हों',
        message: {
            saved: 'व्यापार सफलतापूर्वक सहेजा गया!'
        }
    },
    profile: {
        edit_profile: {
            title: 'प्रोफ़ाइल संपादित करें',
            name: 'पूरा नाम',
            name_placeholder: 'पूरा नाम दर्ज करें',
            email: 'ईमेल',
            phone_number: 'फोन नंबर'
        },
        message: {
            required_name: 'नाम आवश्यक है'
        }
    },
    product: {
        choose_product: 'उत्पाद चुनें',
        choose_product_empty: 'उत्पाद चुनें और मात्रा दर्ज करें',
        search: {
            placeholder: 'नाम से खोजें',
            tooltip: 'स्कैनर खोलें'
        },
        column: {
            image: 'फोटो',
            name: 'नाम',
            price: 'कीमत',
            buying_price: 'खरीद मूल्य',
            selling_price: 'बेचने की कीमत',
            sku: 'SKU',
            stock: 'स्टॉक',
            quantity: 'मात्रा',
            latest_stock: 'शेष',
            product_deleted: 'उत्पाद हटा दिया गया है',
            info_not_found: 'जानकारी उपलब्ध नहीं है',
            expired_date: 'समाप्ति तिथि',
            invoice: 'चालान'
        },
        no_product: 'कोई उत्पाद उपलब्ध नहीं है',
        add_product: {
            sku: 'उत्पाद कोड',
            sku_placeholder: 'उत्पाद कोड। उदाहरण: 12345',
            name: 'उत्पाद नाम',
            name_placeholder: 'उत्पाद नाम दर्ज करें',
            initial_stock: 'आरंभिक स्टॉक',
            initial_stock_placeholder: 'आरंभिक स्टॉक दर्ज करें',
            buying_price: 'खरीद मूल्य',
            buying_price_placeholder: 'खरीद मूल्य दर्ज करें',
            selling_price: 'बेचने की कीमत',
            selling_price_placeholder: 'बेचने की कीमत दर्ज करें',
            category: 'श्रेणी',
            category_placeholder: 'श्रेणी चुनें',
            add_selling_price: 'थोक मूल्य जोड़ें',
            expired_date: 'समाप्ति तिथि',
            expired_date_placeholder: 'तारीख चुनें',
            description: 'विवरण',
            description_placeholder: 'विवरण दर्ज करें'
        },
        choose_selling_price: 'बेचने की कीमत चुनें',
        selling_price: 'बेचने की कीमत',
        total_product: 'कुल उत्पाद',
        total_quantity: 'कुल स्टॉक',
        total_buying_price: 'कुल खरीद मूल्य',
        total_selling_price: 'कुल बेचने की कीमत',
        message: {
            required_name: 'उत्पाद का नाम आवश्यक है',
            required_sku: 'SKU आवश्यक है',
            required_quantity: 'आरंभिक स्टॉक आवश्यक है',
            invalid_input_qty: 'अवैध स्टॉक मात्रा',
            required_buying_price: 'खरीद मूल्य आवश्यक है',
            required_selling_price: 'बेचने की कीमत आवश्यक है',
            required_selling_price_name: 'बेचने की कीमत का नाम आवश्यक है',
            required_selling_price_price: 'बेचने की कीमत आवश्यक है'
        },
        viewMore: 'अधिक देखें',
        WarehouseDetails: 'गोदाम विवरण',
        WarehouseName: 'गोदाम का नाम',
        AddWarehouse: 'गोदाम जोड़ें',
        WarehouseQty: 'गोदाम मात्रा',
        Warehouse: 'गोदाम',
        Warehouses: 'गोदाम',
        Variants: 'संस्करण',
        VariantName: 'संस्करण का नाम',
        productVariant: 'उत्पाद संस्करण',
        AddVariant: 'संस्करण जोड़ें',
        TranferItem: 'आइटम स्थानांतरित करें',
        AddUnit: 'नई इकाई',
        InitialStkWarehouse: 'संस्करण जोड़ने के लिए प्रारंभिक स्टॉक आवश्यक है',
        InitialStockinwarehouse: 'गोदाम में प्रारंभिक स्टॉक',
        TotalQuantity: 'कुल मात्रा:',
        AddNote: 'नोट जोड़ें (लागत मूल्य)',
        AddNotetitle: 'नोट जोड़ें',
        VariantSuccessMessage: 'उत्पाद संस्करण सफलतापूर्वक अपडेट किया गया',
        Next: 'अगला',
        Submit: 'सबमिट करें',
        INACTIVE: 'निष्क्रिय',
        errorone: 'ओह! अनुरोधित मात्रा',
        errortwo: 'आवंटित गोदाम मात्रा से मेल नहीं खाती। कृपया मात्रा समायोजित करें या पुनः भंडार करें।',
        SelectWarehouse: 'गोदाम चुनें',
        SelectVariant: 'वेरिएंट चुनें',
        Discount: 'छूट',
        DiscountDetails: 'छूट विवरण',
        FinalPrice: 'अंतिम मूल्य',
        DiscountType: 'छूट प्रकार',
        DiscountPer: 'छूट %',
        DiscountValue: 'छूट राशि',
        Discountonprice: 'कीमत पर छूट',
        Discountondiscount: 'छूट पर छूट',
        AdditionalDiscount: 'अतिरिक्त छूट',
        SellingPrice: 'विक्रय मूल्य:',
        Save: 'सहेजें',
        OnOriginalPrice: 'मूल कीमत पर',
        OnDiscountedPrice: 'छूट वाली कीमत पर'
    },
    note: {
        title: 'नोट्स',
        add: 'नोट जोड़ें',
        no_note: 'कोई नोट उपलब्ध नहीं है'
    },
    settings: {
        category: 'श्रेणी',
        language: 'भाषा',
        switch_business: 'व्यापार बदलें',
        currencySelect: 'मुद्रा',
        languageChangeSuccess: 'मुद्रा सफलतापूर्वक अपडेट हो गई।',
        languageChangeErrorMessage: 'मुद्रा अपडेट करते समय कुछ गलती हो गई।'
    },
    selectProductStock: 'उत्पाद चुनें',
    category: {
        category_list: {
            title: 'उत्पाद श्रेणियाँ'
        },
        add_category: {
            button: 'नई श्रेणी',
            title: 'नई श्रेणी जोड़ें'
        },
        edit_category: {
            title: 'श्रेणी संपादित करें'
        },
        name: 'श्रेणी का नाम',
        name_placeholder: 'श्रेणी का नाम दर्ज करें',
        no_category: 'कोई श्रेणियाँ उपलब्ध नहीं हैं'
    },

    staff: {
        add: 'स्टाफ जोड़ें',
        edit: 'स्टाफ संपादित करें',
        access: {
            stock: 'स्टॉक',
            add_product: 'नया उत्पाद जोड़ें',
            edit_product: 'उत्पाद संपादित करें',
            delete_product: 'उत्पाद हटाएं',
            view_capital_price: 'लागत मूल्य देखें',
            add_category: 'नई श्रेणी जोड़ें',
            edit_category: 'श्रेणी संपादित करें',
            delete_category: 'श्रेणी हटाएं',
            add_supplier: 'नया विक्रेता जोड़ें',
            edit_supplier: 'विक्रेता संपादित करें',
            delete_supplier: 'विक्रेता हटाएं',
            add_customer: 'नया ग्राहक जोड़ें',
            edit_customer: 'ग्राहक संपादित करें',
            delete_customer: 'ग्राहक हटाएं',
            add_staff: 'स्टाफ जोड़ें',
            edit_staff: 'स्टाफ संपादित करें',
            delete_staff: 'स्टाफ हटाएं',
            change_business_profile: 'व्यापार प्रोफ़ाइल बदलें'
        },
        no_staff: 'कोई स्टाफ उपलब्ध नहीं है।'
    },
    code: {
        title: 'नया स्टाफ जोड़ा गया!',
        subtitle: 'शामिल होने के लिए नीचे दिए गए आमंत्रण कोड को साझा करें:',
        valid: 'कोड केवल 48 घंटे के लिए मान्य है',
        share: 'व्हाट्सएप पर साझा करें',
        copied: 'कॉपी किया गया!',
        invalid: 'अमान्य कोड!'
    },
    history: {
        no_history: 'कोई इतिहास नहीं।',
        detail: {
            remove: 'इतिहास हटाएं',
            confirm: 'यह सुविधा मौजूदा स्टॉक, इतिहास, और रिपोर्टों को प्रभावित करेगी।'
        }
    },
    supplier: {
        add: {
            title: 'विक्रेता जोड़ें'
        },
        edit: {
            title: 'विक्रेता संपादित करें'
        },
        new: 'नया विक्रेता',
        no_supplier: 'कोई विक्रेता उपलब्ध नहीं है।',
        bank: {
            title: 'बैंक विवरण'
        },
        note: 'नोट्स',
        form: {
            name: {
                label: 'विक्रेता का नाम',
                placeholder: 'विक्रेता का नाम दर्ज करें',
                placheolder_not_mandatory: 'नाम दर्ज करें (वैकल्पिक)',
                error: {
                    required: 'नाम खाली नहीं हो सकता'
                }
            },
            contact: {
                label: 'संपर्क नंबर',
                placeholder: '6281234567890',
                error: {
                    required: 'संपर्क नंबर खाली नहीं हो सकता',
                    invalid: 'अमान्य नंबर। इसमें देश का कोड शामिल होना चाहिए'
                }
            },
            bank_name: {
                label: 'बैंक का नाम',
                placeholder: 'बैंक का नाम दर्ज करें'
            },
            bank_account_name: {
                label: 'खाता धारक का नाम',
                placeholder: 'खाता धारक का नाम दर्ज करें'
            },
            bank_account_number: {
                label: 'खाता नंबर',
                placeholder: 'खाता नंबर दर्ज करें'
            },
            note: {
                label: 'नोट्स',
                placeholder: 'नोट्स जोड़ें'
            }
        },
        choose: 'विक्रेता चुनें'
    },
    customer: {
        add: {
            title: 'ग्राहक जोड़ें'
        },
        edit: {
            title: 'ग्राहक संपादित करें'
        },
        new: 'नया ग्राहक',
        no_customer: 'कोई ग्राहक उपलब्ध नहीं है।',
        note: 'नोट्स',
        form: {
            name: {
                label: 'ग्राहक का नाम',
                placeholder: 'ग्राहक का नाम दर्ज करें',
                placheolder_not_mandatory: 'नाम दर्ज करें (वैकल्पिक)',
                error: {
                    required: 'नाम खाली नहीं हो सकता'
                }
            },
            contact: {
                label: 'संपर्क नंबर',
                placeholder: '6281234567890',
                error: {
                    required: 'संपर्क नंबर खाली नहीं हो सकता',
                    invalid: 'अमान्य नंबर। इसमें देश का कोड शामिल होना चाहिए'
                }
            },
            address: {
                label: 'पता',
                placeholder: 'पता दर्ज करें'
            },
            note: {
                label: 'नोट्स',
                placeholder: 'नोट्स जोड़ें'
            }
        },
        choose: 'ग्राहक चुनें',
        report: {
            view: 'खरीदार रिपोर्ट देखें',
            title: 'खरीदार रिपोर्ट',
            no_report: 'चुने हुए तारीख पर खरीदार के लिए कोई रिपोर्ट नहीं है।',
            column: {
                name: 'नाम',
                total_transaction: 'कुल लेन-देन',
                total_amount: 'कुल बिक्री',
                total_profit: 'कुल लाभ'
            }
        }
    },
    confirm_delete: 'क्या आप सुनिश्चित हैं कि आप इस डेटा को हटाना चाहते हैं?',
    report: {
        title: 'रिपोर्ट'
    },
    accounting: {
        title: 'लेखांकन',
        view_report: 'वित्तीय रिपोर्ट देखें',
        dashboard_title: 'वित्तीय रिपोर्ट',
        stats: 'आंकड़े',
        transaction_report: 'लेनदेन रिपोर्ट',
        filter: {
            date: {
                placeholder: 'तारीख़ चुनें',
                submit: 'लागू करें',
                cancel: 'फ़िल्टर रिसेट करें'
            },
            timeframe: {
                day: 'दैनिक',
                week: 'साप्ताहिक',
                month: 'मासिक',
                year: 'वार्षिक'
            }
        },
        total_sales: 'कुल बिक्री',
        total_purchases: 'कुल खरीद',
        total_profit: 'कुल लाभ',
        assets_value: 'वर्तमान संपत्ति मूल्य',
        table: {
            date: 'तारीख़',
            sales: 'बिक्री',
            purchases: 'खरीद',
            profit: 'लाभ'
        },
        no_data: 'कोई डेटा उपलब्ध नहीं है।'
    },
    stock_report: {
        title: 'स्टॉक रिपोर्ट',
        view: 'स्टॉक रिपोर्ट देखें',
        allStaffPlaceHolder: 'सभी स्टाफ',
        no_report: 'चुने गए दिनांक या स्टाफ के लिए कोई स्टॉक रिकॉर्ड नहीं हैं।',
        column: {
            total_product: 'वर्तमान उत्पाद प्रकार',
            total_product_quantity: 'कुल वर्तमान उत्पाद'
        }
    },
    subscription: {
        paywall_small: {
            title: 'वीआईपी फीचर',
            description:
                'इस फीचर के लिए आपका ट्रायल अवधि समाप्त हो गया है। इस स्थानीय ऐप के विकास का समर्थन करने के लिए सब्सक्राइब करें।',
            button: 'सब्सक्रिप्शन योजनाएँ देखें'
        }
    },
    payment: {
        order: {
            title: 'भुगतान'
        }
    },
    imageUploadMessage: {
        uploadSize: 'छवि का आकार 5MB से अधिक नहीं होना चाहिए!',
        failedMessage: 'आइटम छवि अपलोड करने में विफल, कृपया फिर से प्रयास करें!',
        successUpload: 'छवि सफलतापूर्वक अपलोड की गई!',
        failedUIError: 'छवि को हटाने में विफल, कृपया फिर से प्रयास करें!',
        SuccessErrorImage: 'छवि सफलतापूर्वक हटा दी गई है!'
    },
    productColumns: {
        SKULabel: 'SKU',
        nameLabel: 'उत्पाद का नाम',
        categoryLabel: 'श्रेणी',
        BuyingPriceLabel: 'खरीद मूल्य',
        SellingLabel: 'बेचने की कीमत',
        StockLabel: 'स्टॉक'
    },
    historyColumns: {
        Transactionlabel: 'लेन-देन प्रकार',
        Locallabel: 'स्थानीय समय',
        Createdlabel: 'द्वारा बनाया गया',
        Vendorlabel: 'विक्रेता का नाम',
        Buyerlabel: 'खरीदार का नाम',
        SKUlabel: 'SKU',
        Productlabel: 'उत्पाद का नाम',
        Categorylabel: 'श्रेणी',
        Buyinglabel: 'खरीद मूल्य',
        Sellinglabel: 'बिक्री मूल्य',
        Initiallabel: 'प्रारंभिक स्टॉक',
        Finallabel: 'अंतिम स्टॉक',
        Stocklabel: 'स्टॉक परिवर्तन'
    },
    stockReportColumns: {
        skuLabel: 'SKU',
        productNameLabel: 'उत्पाद का नाम',
        categoryLabel: 'श्रेणी',
        buyingPriceLabel: 'खरीद मूल्य',
        sellingPriceLabel: 'बेचने का मूल्य',
        stockInLabel: 'स्टॉक इन',
        stockOutLabel: 'स्टॉक आउट',
        remainingStockLabel: 'बचा हुआ स्टॉक'
    },
    accountingReportColumns: {
        labelDate: 'तारीख',
        labelSales: 'बिक्री',
        labelPurchases: 'खरीद',
        labelProfit: 'लाभ'
    },
    customerReportColumns: {
        labelName: 'नाम',
        labelTotalTransaction: 'कुल लेन-देन',
        labelTotalSales: 'कुल बिक्री',
        labelTotalProfit: 'कुल लाभ'
    },
    scannerDialog: {
        finished: 'समाप्त',
        reduce: 'कम करो',
        successfull: 'सफल',
        searching: 'खोज रहा...',
        title: 'स्कैनर सक्रिय है। इसे बारकोड की ओर निर्देशित करें!'
    },
    historyFilter: {
        SelectBuyer: 'खरीदार चुनें',
        SelectVendor: 'विक्रेता चुनें'
    },
    productStockFilter: {
        StockLowtoHigh: 'स्टॉक कम से अधिक',
        StockHightoLow: 'स्टॉक अधिक से कम',
        AlphabetAZ: 'अक्षर A-Z',
        AlphabetZA: 'अक्षर Z-A'
    },
    minimizeTitle: 'स्कैनर सक्रिय',
    security_guarantee: {
        title: 'डेटा सुरक्षा सुनिश्चित है'
    },
    invoice: {
        invoicePlaceholder: 'चालान नंबर',
        invoiceTitle: 'चालान'
    },
    stocklowreport: {
        no_stocklowreport_one: 'सुरक्षा स्टॉक सेटिंग्स वाले कोई आइटम नहीं हैं।',
        no_stocklowreport_two: 'कृपया सुरक्षा स्टॉक मात्रा जोड़ें।'
    },
    low_alert_stock: 'सुरक्षा स्टॉक मात्रा दर्ज करें',
    lowStockAlertNotify: 'जब स्टॉक निर्धारित मात्रा से कम हो जाएगा, तो आपको एक सूचना मिलेगी।',
    applyButtonStockAlert: 'लागू करें',
    cancelButtonStockAlert: 'रद्द करें',
    searchProductLabel: 'उत्पाद खोजें...',
    successfullyAddedSafetyStock: 'सुरक्षा स्टॉक सफलतापूर्वक अपडेट किया गया',
    safety_Stock: 'सुरक्षा स्टॉक',
    safety_stock_placeholder: 'सुरक्षा स्टॉक दर्ज करें',
    lowStockDetailsInfo: {
        ProductCode: 'उत्पाद कोड',
        SafetyStock: 'सुरक्षा स्टॉक',
        Stock: 'स्टॉक',
        CostPrice: 'लागत मूल्य',
        SellingPrice: 'बिक्री मूल्य',
        Category: 'श्रेणी',
        Description: 'विवरण',
        ExpirationDate: 'समाप्ति तिथि'
    },
    CustomLang: {
        Attribute: 'गुण',
        AddAttribute: 'गुण जोड़ें',
        EditAttribute: 'गुण संपादित करें',
        CustomLabePlaceholder: 'गुण का नाम दर्ज करें',
        CustomLabePlaceholderSelect: 'प्रकार चुनें',
        AddMoreAttribute: 'और गुण जोड़ें',
        ItemAttribute: 'वस्तु का गुण',
        TableName: 'नाम',
        TableType: 'प्रकार',
        TableAction: 'क्रिया',
        TableDelete: 'हटाएं',
        TableEdit: 'संपादित करें',
        deleteMessage: 'गुण सफलतापूर्वक हटाया गया',
        editSuccessMessage: 'गुण सफलतापूर्वक अपडेट किया गया',
        AddSuccessMessage: 'गुण सफलतापूर्वक जोड़ा गया',
        AddAtt: 'जोड़ें',
        EditAtt: 'संपादित करें',
        SubmitAtt: 'जमा कर रहे हैं...'
    },
    Expenses: {
        ExpensesTitle: 'खर्चे',
        AddExpenses: 'खर्चे जोड़ें',
        ExpenseCategory: 'खर्चे की श्रेणी',
        NoExpenseCategory: 'कोई खर्च श्रेणी नहीं',
        AddExpenseCategory: 'खर्चे की श्रेणी जोड़ें',
        SelectExpenseCategory: 'खर्चे की श्रेणी चुनें',
        NoExpenses: 'कोई खर्चा नहीं',
        ExpensesSuccess: 'खर्चा सफलतापूर्वक बनाया गया',
        ExpensesSuccessEdit: 'खर्चा सफलतापूर्वक अपडेट किया गया',
        ExpensesCategoryName: 'खर्चे की श्रेणी का नाम',
        ExpensesSuccessDelete: 'खर्चा सफलतापूर्वक हटाया गया',
        TotalSpentThisWeek: 'इस सप्ताह कुल खर्च'
    },
    Manufacturing: {
        AddManufacture: 'निर्माण विवरण जोड़ें',
        Manufacturing: 'निर्माण',
        RawMaterials: 'कच्चा माल',
        AdditonCosts: 'अतिरिक्त लागत',
        RawMaterial: 'कच्चा माल',
        AdditonCost: 'अतिरिक्त लागत',
        TotalCost: 'कुल लागत',
        AddRawMaterial: 'के लिए कच्चा माल जोड़ें',
        AddAdditonCost: 'के लिए अतिरिक्त लागत जोड़ें',
        AddMore: 'और जोड़ें',
        Add: 'जोड़ें',
        Update: 'अपडेट करें',
        Updating: 'सबमिट कर रहा है...',
        ProductRequired: 'उत्पाद आवश्यक है',
        QuantityRequired: 'मात्रा आवश्यक है',
        ChargeRequired: 'शुल्क आवश्यक है',
        EstimatedCostRequired: 'अनुमानित लागत आवश्यक है',
        PurchasePriceRequired: 'खरीद मूल्य आवश्यक है',
        SelectCharge: 'शुल्क चुनें',
        EstimatedCost: 'अनुमानित लागत',
        SelectProduct: 'एक उत्पाद चुनें',
        Quantity: 'मात्रा',
        ManufactureDetails: 'निर्माण विवरण',
        BuyingPrice: 'खरीद मूल्य',
        LabourCharge: 'मजदूरी शुल्क',
        ElectricityCost: 'बिजली लागत',
        PackagingCharge: 'पैकेजिंग शुल्क',
        LogisticCost: 'लॉजिस्टिक लागत',
        OtherCharges: 'अन्य शुल्क',
        Save: 'विवरण सहेजें',
        PurchasePrice: 'खरीद मूल्य',
        Date: 'तारीख',
        For: 'के लिए',
        PurchaseRate: 'खरीद दर (मूल्य)',
        Scrolltoloadmore: 'अधिक लोड करने के लिए स्क्रॉल करें...',
        Noproductsfound: 'कोई उत्पाद नहीं मिला',
        ManufacturingQuantity: 'निर्माण मात्रा',
        ManufactureAddedSuccess: 'निर्माण विवरण सफलतापूर्वक अपडेट किया गया'
    },
    Warehouses: {
        transferOrder: 'सामान स्थानांतरित करें',
        AddTransferOrder: 'सामान जोड़ें',
        NewWarehouse: 'नया गोदाम',
        newHead: 'एकाधिक गोदामों को प्रबंधित करना शुरू करें!',
        headTwo: 'अपने स्टॉक को कई गोदामों में सहजता से ट्रैक और प्रबंधित करें',
        EnableWarehouse: 'गोदाम सक्षम करें',
        EnableWarehouse2: 'गोदाम सक्षम करें',
        OrganizationPrimary: 'संगठन का प्राथमिक गोदाम',
        Edit: 'संपादित करें',
        AreEnableWarehouse: 'क्या आप वाकई गोदाम को सक्षम करना चाहते हैं?',
        Confirm: 'पुष्टि करें',
        Cancel: 'रद्द करें',
        WarehouseCreatedSuccessfully: 'गोदाम सफलतापूर्वक बनाया गया',
        MarkAsOP: 'संगठन का प्राथमिक गोदाम चिह्नित करें',
        MarkAsInAct: 'निष्क्रिय के रूप में चिह्नित करें',
        MarkAsAct: 'सक्रिय के रूप में चिह्नित करें',
        MarkedAsInactive: 'गोदाम को सफलतापूर्वक निष्क्रिय किया गया',
        MarkedAsActive: 'गोदाम को सफलतापूर्वक सक्रिय किया गया',
        MarkedAsOP: 'गोदाम को संगठन का प्राथमिक के रूप में सफलतापूर्वक चिह्नित किया गया',
        WareDeletedSuccess: 'गोदाम सफलतापूर्वक हटाया गया',
        SuccessfullyUpdatedWarehouse: 'गोदाम को सफलतापूर्वक अपडेट किया गया',
        SuccessfullyCreatedWarehouse: 'गोदाम को सफलतापूर्वक बनाया गया',
        EditWarehouse: 'गोदाम संपादित करें',
        AddWarehouse: 'गोदाम जोड़ें',
        WarehouseNameIsRequired: 'गोदाम का नाम आवश्यक है',
        EnterWarehouseName: 'गोदाम का नाम दर्ज करें',
        EnterEmail: 'ईमेल दर्ज करें',
        EnterPhoneNumber: 'फ़ोन नंबर दर्ज करें',
        Location: 'स्थान...',
        name: 'गोदाम का नाम',
        email: 'ईमेल',
        phoneNumber: 'फ़ोन नंबर',
        location: 'स्थान'
    }
}

export default hi
