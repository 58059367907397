import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { GoogleAuthProvider, OAuthProvider, signInWithPopup, signOut, User } from 'firebase/auth'
import jwtDecode from 'jwt-decode'
import { useRouter } from 'next/router'
import QueryString from 'qs'
import { createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    AuthTokenOtpless,
    ClearUserState,
    getCollection,
    GetProfile,
    OAuthLogin,
    OAuthRegister,
    UpdateProfile
} from '~/api'
import { Toast, ToastProps } from '~/components/Common/Toast'
import { firebaseAuth } from '~/config/firebase'
import { RequestInterceptor } from '~/lib/axios'
import $posthog from '~/lib/posthog'
import $sentry from '~/lib/sentry'
import locale from '~/locale'
import { RootState } from '~/redux/store'
import {
    BusinessAccessT,
    BusinessI,
    HistoryFilterI,
    HistoryI,
    ProductFilterI,
    ProductI,
    ProfileI,
    ProfileII
} from '~/types'
import { getAccessToken, getCurrentBusiness, multiPromiseResolver, setCurrentBusiness } from '~/utils'
import { localStore, StoreKey } from '~/utils/local-store'
import { $error, $log } from '~/utils/logger'
import { getPageMap } from '~/utils/page'

dayjs.extend(utc)

interface AuthContextProps {
    businesses: BusinessI[]
    profile?: ProfileI
    isAuthenticating: boolean
    isOnboardingProductAvailable: boolean
    loadProfile: () => Promise<ProfileII | undefined>
    authenticate: (token?: string, type?: 'oauth' | 'otpless', action?: 'login' | 'register') => Promise<void>
    currentBusiness: () => BusinessI | undefined
    checkAccess: (access: BusinessAccessT) => boolean
    googleSignIn: (
        action?: 'login' | 'register' | 'sync_email',
        setLoading?: (loading: boolean) => void
    ) => Promise<void>
    appleSignIn: (action?: 'login' | 'register' | 'sync_email') => Promise<void>
    logout: () => void
}

const AuthContext = createContext<AuthContextProps>({
    businesses: [],
    isAuthenticating: true,
    isOnboardingProductAvailable: false,
    loadProfile: () => Promise.resolve(undefined),
    authenticate: () => Promise.resolve(undefined),
    currentBusiness: () => undefined,
    checkAccess: () => false,
    googleSignIn: () => Promise.resolve(undefined),
    appleSignIn: () => Promise.resolve(undefined),
    logout: () => undefined
})

export const useAuth = () => useContext(AuthContext)

interface AuthProviderProps {
    children: JSX.Element | JSX.Element[]
}

const AuthProvider = ({ children }: AuthProviderProps) => {
    const router = useRouter()

    const [businesses, setBusinesses] = useState<BusinessI[]>([])

    const [profile, setProfile] = useState<ProfileI>()

    const [loading, setLoading] = useState(true)
    const [isOnboardingProductAvailable, setOnboardingProductAvailable] = useState(false)

    const language = useSelector((state: RootState) => state.language.language)
    // Assuming locale has a specific type, e.g. LocaleType
    const localeText = (locale[language as keyof typeof locale] || locale.en) as any
    const pageProps = getPageMap(localeText)[router.pathname] // Use dynamic pageMap based on language

    const [toast, setToast] = useState<ToastProps>({
        message: ''
    })

    useEffect(() => {
        checkPlatform()

        authenticate()

        $posthog.init()
    }, [])

    /**
     * Check platform if user visit from webview
     */
    const checkPlatform = () => {
        const query = QueryString.parse(window.location.search, {
            ignoreQueryPrefix: true
        })

        if (query.platform === 'webview') {
            const token = query.token
            const businessId = Number(query.businessId)

            if (token) {
                localStore.set(StoreKey.ACCESS_TOKEN, token as string)
            }

            if (businessId) {
                // localStore.set(StoreKey.SELECTED_BUSINESS, businessId as number)
                setCurrentBusiness(businessId as number)
            }

            localStore.set(StoreKey.PLATFORM, 'webview')

            // setTimeout(() => {
            //     router.replace(router.pathname, undefined, { shallow: true })
            // }, 500)
        }
    }

    /**
     * Google Sign In
     * @param action
     */
    const googleSignIn = async (
        action: 'login' | 'register' | 'sync_email' = 'login',
        setLoading?: (loading: boolean) => void // Explicitly typing setLoading
    ): Promise<void> => {
        const provider = new GoogleAuthProvider()
        provider.setCustomParameters({ prompt: 'select_account', locale: 'en' })

        firebaseAuth.languageCode = 'en'

        if (action !== 'sync_email') {
            $posthog.capture('otp_request', { method: 'google', type: action === 'login' ? 'login' : 'sign up' })
        }

        signInWithPopup(firebaseAuth, provider)
            .then(async (result) => {
                const user = result.user

                if (!user) {
                    throw new Error('User not found')
                }

                if (action === 'sync_email') {
                    syncEmail(user)
                    return
                }

                const token = await user.getIdToken()

                if (token) {
                    authenticate(token, 'oauth', action, user, setLoading)
                    setToast({
                        message: localeText?.common?.message?.login_success,
                        open: true,
                        timeOut: 4000,
                        type: 'success'
                    })
                }
            })
            .catch((error) => {
                if (typeof setLoading === 'function') {
                    setLoading(false)
                }
                $error(error)
                setToast({
                    message: 'Failed to connect to Google',
                    open: true,
                    timeOut: 4000,
                    type: 'error'
                })
            })
    }

    /**
     * Apple Sign In
     * @param action
     */
    const appleSignIn = async (action: 'login' | 'register' | 'sync_email' = 'login') => {
        const provider = new OAuthProvider('apple.com')
        provider.addScope('email')
        provider.addScope('name')
        provider.setCustomParameters({ prompt: 'select_account', locale: 'en' })

        firebaseAuth.languageCode = 'id'

        if (action !== 'sync_email') {
            $posthog.capture('otp_request', { method: 'apple', type: action === 'login' ? 'masuk' : 'daftar' })
        }

        signInWithPopup(firebaseAuth, provider)
            .then(async (result) => {
                const user = result.user

                if (!user) {
                    throw new Error('User not found')
                }

                if (action === 'sync_email') {
                    syncEmail(user)
                    return
                }

                const token = await user.getIdToken()

                if (token) {
                    authenticate(token, 'oauth', action)
                    setToast({
                        message: localeText?.common?.message?.login_success,
                        open: true,
                        timeOut: 4000,
                        type: 'success'
                    })
                }
            })
            .catch((error) => {
                $error(error)
                setToast({
                    message: 'Gagal koneksi dengan Apple',
                    open: true,
                    timeOut: 4000,
                    type: 'error'
                })
            })
    }

    /**
     * Authenticate the user and protect the route
     */
    const authenticate = async (
        token?: string,
        type?: 'oauth' | 'otpless',
        action?: 'login' | 'register',
        user?: any,
        setLoading?: (loading: boolean) => void
    ) => {
        let accessToken = localStore.get(StoreKey.ACCESS_TOKEN)

        $log({ token, type, action, accessToken })

        if (accessToken?.data) {
            RequestInterceptor()

            const authProfile = await loadProfile()

            if (authProfile) {
                const userId = getAccessToken(accessToken?.data)?.id
                $posthog.identify(
                    userId,
                    {
                        'Staff Name': authProfile?.data?.name,
                        'Phone Number': authProfile.data?.phoneNumber,
                        Email: authProfile.data?.email
                    },
                    {
                        'Registered Date': dayjs.utc().format('YYYY-MM-DD HH:mm:ss')
                    }
                )
            }
            redirect(accessToken.data, authProfile?.data)
            return
        }

        if (!token) {
            redirect()
            return
        }

        // for authenticated user

        const { data } =
            type === 'otpless'
                ? await AuthTokenOtpless(token, localeText)
                : action === 'login'
                ? await OAuthLogin(token, user, localeText, setLoading)
                : await OAuthRegister(token, localeText)

        if (!!data) {
            localStore.set(StoreKey.ACCESS_TOKEN, data?.data.token)
            RequestInterceptor()

            accessToken = localStore.get(StoreKey.ACCESS_TOKEN)

            const userId = data?.data?.user?.id

            $posthog.identify(
                userId,
                {
                    'Staff Name': data.name,
                    'Phone Number': data.phoneNumber,
                    Email: data.email
                },
                {
                    'Registered Date': dayjs.utc().format('YYYY-MM-DD HH:mm:ss')
                }
            )
            $posthog.capture('otp_confirm', {
                status: 'success',
                type: data.isNew ? 'new' : 'returning',
                user_id: userId
            })

            $sentry.setUser(userId)
        } else {
            logout()

            if (pageProps.title === 'Login') {
                if (action === 'register') {
                    setToast({
                        message: 'Failed to register',
                        open: true,
                        timeOut: 4000,
                        type: 'error'
                    })
                } else {
                    setToast({
                        message: 'Login failed.',
                        open: true,
                        timeOut: 4000,
                        type: 'error'
                    })
                }

                return
            }
        }

        const authProfile = await loadProfile()

        redirect(accessToken?.data, authProfile?.data)
    }

    /**
     * Load profile
     * @returns
     */
    const loadProfile = async () => {
        // const token = localStore.get(StoreKey.ACCESS_TOKEN )
        const { data: responseData, error } = await GetProfile(localeText)

        if (!!error || !responseData) {
            return
        }

        setProfile(responseData?.data)
        setBusinesses(responseData.data?.businesses)

        return responseData
    }

    /**
     * Get current business data
     * @returns current business
     */
    const currentBusiness = () => {
        const id = getCurrentBusiness()

        return businesses.find((item) => Number(item.businessId) === id)
    }

    /**
     * Check if current user has access
     * @param access
     * @returns
     */
    const checkAccess = (access: BusinessAccessT) => {
        const business = currentBusiness()

        if (!business) {
            return false
        }

        if (business.access.includes('all') || ['owner', 'admin'].includes(business.roles)) {
            return true
        }

        return business.access.includes(access)
    }

    const redirect = async (accessToken?: any, authProfile?: ProfileI) => {
        if (!pageProps) {
            setLoading(false)
            return
        }

        if (!accessToken || !authProfile) {
            ClearUserState()

            if (pageProps.auth) {
                window.location.href = '/login'
                return
            }

            setLoading(false)
            return
        }

        $log({ accessToken, authProfile, isPageAuth: pageProps.auth })

        try {
            jwtDecode(accessToken || '')

            const { is_onboard } = router.query

            const checkOnboarding = await isNeedOnboarding(authProfile)

            $log({ is_onboard, checkOnboarding })

            if (!pageProps.auth) {
                if (checkOnboarding) {
                    window.location.href = '/onboarding'
                    return
                }

                window.location.href = '/business'
                return
            }

            if (!is_onboard) {
                if (checkOnboarding) {
                    router.push('/onboarding').then(() => {
                        setLoading(false)
                    })
                    return
                }

                if (pageProps.title === localeText?.onboarding?.welcome) {
                    router.push('/business').then(() => {
                        setLoading(false)
                    })
                    return
                }
            }

            setLoading(false)
        } catch (err) {
            $error(err)
            ClearUserState()
            logout()

            if (pageProps.auth) {
                window.location.href = '/login'
                return
            }

            setLoading(false)
        }
    }

    const isNeedOnboarding = async (authProfile?: ProfileI) => {
        if (!authProfile) {
            return false
        }

        const selectedBusiness = authProfile.businesses.find((item) => item.roles === 'admin')

        const businessWithAdminRole = authProfile.businesses.filter((item) => item.roles === 'admin')

        if (!selectedBusiness || businessWithAdminRole.length !== 1) {
            return false
        }

        const onboardStep = localStore.get(StoreKey.ONBOARD_STEP)

        if (onboardStep?.data === '1') {
            return false
        }

        const [{ value: dataProducts }, { value: dataHistory }] = await multiPromiseResolver([
            getCollection<ProductI, ProductFilterI, 'products'>(
                `/api/business/product/all?businessId=${selectedBusiness?.businessId}`,
                {
                    limit: 1,
                    offset: 0
                }
            ),

            getCollection<HistoryI, HistoryFilterI, 'histories'>(
                `/api/business/product/stock/history?businessId=${selectedBusiness?.businessId}`,
                {
                    limit: 1,
                    offset: 0
                },
                'v1'
            )
        ])

        if (dataProducts?.meta.total !== 0) {
            setOnboardingProductAvailable(true)

            if (dataHistory?.histories?.length !== 0) {
                localStore.set(StoreKey.ONBOARD_STEP, '1')
                return false
            }
        }

        return true
    }

    const syncEmail = async (user: User | null) => {
        try {
            if (!user || !user.email) {
                throw new Error('User not found')
            }

            const { error } = await UpdateProfile(
                {
                    name: profile?.name || user.displayName || '',
                    imageUrl: profile?.imageUrl || user.photoURL || '',
                    phoneNumber: profile?.phoneNumber || '',
                    email: user.email
                },
                localeText
            )

            if (!!error) {
                throw new Error(error.message)
            }

            setToast({
                message: 'Email synchronization successful',
                open: true,
                timeOut: 4000,
                type: 'success'
            })

            window.location.reload()
        } catch (e) {
            $error(e)
            setToast({
                message: 'Email synchronization failed',
                open: true,
                timeOut: 4000,
                type: 'error'
            })
        }
    }

    const logout = () => {
        signOut(firebaseAuth)
    }

    return (
        <>
            <AuthContext.Provider
                value={{
                    businesses,
                    profile,
                    isAuthenticating: loading,
                    isOnboardingProductAvailable,
                    loadProfile,
                    authenticate,
                    currentBusiness,
                    checkAccess,
                    googleSignIn,
                    appleSignIn,
                    logout
                }}>
                {children}
            </AuthContext.Provider>

            <Toast
                {...toast}
                onClose={() => {
                    setToast({
                        message: ''
                    })
                }}></Toast>
        </>
    )
}

export default AuthProvider
