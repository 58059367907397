import axios from 'axios'

import { API_BASE_URL, API_BASE_URL_V2, AUTH_API_BASE_URL, OAUTH_API_BASE_URL } from '~/constants'
import locale from '~/locale'
import {
    ApiResponseI,
    AuthResponseI,
    BusinessAccessT,
    BusinessI,
    BusinessRequestBodyI,
    CategoryI,
    ChallanFormI,
    CollectionApiParams,
    CollectionApiResponse,
    CustomerFilterI,
    CustomerI,
    CustomerReportFilterI,
    CustomerReportI,
    CustomLabelI,
    CustomLabelRequestBodyI,
    DeleteCustomLabelI,
    DeleteExpenseI,
    ExpensesI,
    GetRepsonseCollectionI,
    HistoryFilterI,
    HistoryI,
    HistoryInvoiceParams,
    HistoryUpdateI,
    ManufactureUpdateI,
    ProdcutTotalI,
    ProductFilterI,
    ProductI,
    ProductRequestBodyI,
    ProductSellingPriceRequestBodyI,
    ProfileI,
    ProfileII,
    SingleApiResponse,
    StaffFilterI,
    StaffI,
    StockActionT,
    StockReportFilterI,
    StockReportI,
    SupplierFilterI,
    SupplierI,
    TransferOrderI,
    TransferOrderIDetails,
    UpdateStaffI,
    UpdateStockI,
    UserType,
    WarehouseI
} from '~/types'
import { localStore, StoreKey } from '~/utils/local-store'
import { $error, $log } from '~/utils/logger'

/**
 * Get auth token
 */
export const AuthToken = async (firebaseToken: string): Promise<ApiResponseI<AuthResponseI>> => {
    return axios
        .post<AuthResponseI>(`${AUTH_API_BASE_URL}/firebase`, {
            firebaseToken
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: locale.id.common.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: locale.id.common.request_error
                }
            }
        })
}

export const AuthTokenOtpless = async (token: string, localeText: any): Promise<ApiResponseI<AuthResponseI>> => {
    return axios
        .post<AuthResponseI>(`${AUTH_API_BASE_URL}/otpless`, {
            token
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
}

/**
 * Oauth Login
 */
export const OAuthLogin = async (
    token: string,
    user: UserType,
    localeText: any,
    setLoading?: (loading: boolean) => void
): Promise<ApiResponseI<AuthResponseI>> => {
    return axios
        .post<AuthResponseI>(`${API_BASE_URL}/api/user/login`, {
            email: user?.email,
            name: user?.displayName,
            profileImage: user?.photoURL
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            setLoading?.(false)

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
}

/**
 * Oauth Register
 */
export const OAuthRegister = async (token: string, localeText: any): Promise<ApiResponseI<AuthResponseI>> => {
    return axios
        .post<AuthResponseI>(`${OAUTH_API_BASE_URL}/firebase/register`, {
            token
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
}

/**
 * Get all business
 */
export const GetBusiness = async (): Promise<ApiResponseI<BusinessI[]>> => {
    return axios
        .get<BusinessI[]>(`${API_BASE_URL}/api/business`)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: locale.id.common.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: locale.id.common.request_error
                }
            }
        })
}

/**
 * Create business
 */
export const CreateBusiness = async (data: BusinessRequestBodyI, localeText: any): Promise<ApiResponseI<BusinessI>> => {
    return axios
        .post<{ data: any } & BusinessI>(`${API_BASE_URL}/api/business`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data
                }
            }

            return {
                error: {
                    message: localeText.common.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText.common.request_error
                }
            }
        })
}

/**
 * Update business
 */
export const UpdateBusiness = async (
    businessId: number,
    data: BusinessRequestBodyI,
    localeText: any
): Promise<ApiResponseI<boolean>> => {
    return axios

        .patch<boolean>(`${API_BASE_URL}/api/business?businessId=${businessId}`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText.common.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)

            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText.common.request_error
                }
            }
        })
}

/**
 * Get all GetProductForStockChange
 */
export const GetProductForStockChange = async (
    businessId: string,
    params: CollectionApiParams<ProductFilterI>,
    localeText: any
): Promise<ApiResponseI<GetRepsonseCollectionI<'products', ProductI>>> => {
    return axios
        .get<{ data: any } & GetRepsonseCollectionI<'products', ProductI>>(
            `${API_BASE_URL}/api/business/product/all?businessId=${businessId}`,
            {
                params
            }
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)

            const error = e?.response?.data?.message
            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Get all Low Stock alert
 */
export const GetLowStockAlert = async (
    businessId: number,
    params: ProductFilterI,
    localeText: any
): Promise<ApiResponseI<GetRepsonseCollectionI<'products', ProductI>>> => {
    return axios
        .get<{ data: any } & GetRepsonseCollectionI<'products', ProductI>>(
            `${API_BASE_URL}/api/business/product/all?businessId=${businessId}`,
            {
                params
            }
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)

            const error = e?.response?.data?.message
            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Get all Expenses
 */
export const GetAllExpenses = async (
    businessId: number,
    params: ProductFilterI,
    localeText: any
): Promise<ApiResponseI<GetRepsonseCollectionI<'expenses', ExpensesI>>> => {
    return axios
        .get<{ data: any } & GetRepsonseCollectionI<'expenses', ExpensesI>>(
            `${API_BASE_URL}/api/business/product/expense?businessId=${businessId}`,
            {
                params
            }
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)

            const error = e?.response?.data?.message
            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Get all Expenses Category
 */
export const GetAllExpensesCategory = async (
    businessId: number,
    localeText: any
): Promise<ApiResponseI<GetRepsonseCollectionI<'data', CategoryI>>> => {
    return axios
        .get<GetRepsonseCollectionI<'data', CategoryI>>(
            `${API_BASE_URL}/api/business/product/expense/category?businessId=${businessId}`
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Add category
 */
export const CreateExpenseCategory = async (
    businessId: number,
    data: CategoryI,
    localeText: any
): Promise<ApiResponseI<boolean>> => {
    return axios
        .post<boolean>(`${API_BASE_URL}/api/business/product/expense/category?businessId=${businessId}`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Edit category
 */
export const EditExpenseCategory = async (
    businessId: number,
    data: CategoryI,
    localeText: any
): Promise<ApiResponseI<boolean>> => {
    return axios
        .patch<boolean>(`${API_BASE_URL}/api/business/product/expense/category?businessId=${businessId}`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Add Expense
 */
export const CreateExepense = async (
    businessId: number,
    data: Partial<ExpensesI>,
    localeText: any
): Promise<ApiResponseI<ExpensesI>> => {
    return axios
        .post<{ data: any } & ExpensesI>(`${API_BASE_URL}/api/business/product/expense?businessId=${businessId}`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data
                }
            }
            return {
                error: {
                    message: localeText?.common.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message
            return {
                error: {
                    message: error
                }
            }
        })
}

/**
 * Edit Expense
 */
export const EditExepense = async (
    businessId: number,
    data: Partial<ExpensesI>,
    localeText: any
): Promise<ApiResponseI<ExpensesI>> => {
    return axios
        .patch<ExpensesI>(`${API_BASE_URL}/api/business/product/expense?businessId=${businessId}`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }
            return {
                error: {
                    message: localeText?.common.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message
            return {
                error: {
                    message: error
                }
            }
        })
}

/**
 * Delete Expense
 */
export const DeleteExepense = async (
    businessId: number,
    params: DeleteExpenseI,
    localeText: any
): Promise<ApiResponseI<boolean>> => {
    return axios
        .delete(`${API_BASE_URL}/api/business/product/expense?businessId=${businessId}`, {
            data: params
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Update UpdateSafetyStock
 */
export const UpdateSafetyStock = async (
    businessId: number,
    params: Partial<ProductRequestBodyI>,
    localeText: any
): Promise<ApiResponseI<ProductI>> => {
    return axios
        .patch<ProductI>(`${API_BASE_URL}/api/business/product?businessId=${businessId}`, params)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }
            return {
                error: {
                    message: localeText?.common.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message
            return {
                error: {
                    message: error
                }
            }
        })
}

/**
 * Get all products
 */
export const GetProduct = async (
    businessId: string,
    params: ProductFilterI,
    localeText: any
): Promise<ApiResponseI<GetRepsonseCollectionI<'products', ProductI>>> => {
    return axios
        .get<{ data: any } & GetRepsonseCollectionI<'products', ProductI>>(
            `${API_BASE_URL}/api/business/product/all?businessId=${businessId}`,
            {
                params
            }
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)

            const error = e?.response?.data?.message
            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Get product by id
 */
export const GetProductById = async (
    businessId: number,
    productId: number,
    localeText: any
): Promise<ApiResponseI<ProductI>> => {
    return axios
        .get<{ data: any } & ProductI>(
            `${API_BASE_URL}/api/business/product?businessId=${businessId}&productId=${productId}`
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res?.data?.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)

            const error = e?.response?.data?.message
            return {
                error: {
                    message: error
                }
            }
        })
}

/**
 * Create product
 */
export const CreateProduct = async (
    businessId: number,
    data: Partial<ProductRequestBodyI>,
    localeText: any
): Promise<ApiResponseI<ProductI>> => {
    const params: Omit<Partial<ProductRequestBodyI>, 'data'> = {
        buyingPrice: data.buyingPrice,
        imageUrl: data.imageUrl,
        name: data.name,
        quantity: data.quantity,
        note: data.note,
        sellingPrice: data.sellingPrice,
        sku: data.sku,
        categoryId: data.categoryId,
        unitId: data.unitId,
        expiredDate: data.expiredDate,
        description: data.description,
        bundleDetails: data.bundleDetails,
        safetyStock: data.safetyStock,
        additionalCost: data.additionalCost,
        isBundle: data.isBundle,
        ...(data.variants?.some((variant) => variant.warehouseDetails && variant.warehouseDetails.length > 0)
            ? {}
            : {
                  warehouseDetails: data.warehouseDetails
              }),
        variants: data.variants
    }

    return axios
        .post<ProductI>(`${API_BASE_URL}/api/business/product?businessId=${businessId}`, params)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data
                }
            }

            return {
                error: {
                    message: localeText?.common.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message
            return {
                error: {
                    message: error
                }
            }
        })
}

/**
 * Update product
 */
export const UpdateProduct = async (
    businessId: number,
    params: Partial<ProductRequestBodyI>,
    localeText: any
): Promise<ApiResponseI<ProductI>> => {
    return axios
        .patch<ProductI>(`${API_BASE_URL}/api/business/product?businessId=${businessId}`, params)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: localeText?.common.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message
            return {
                error: {
                    message: error
                }
            }
        })
}

// Add custoC Label
export const AddCustomLabel = async (
    businessId: number,
    params: Partial<CustomLabelRequestBodyI>,
    localeText: any
): Promise<ApiResponseI<CustomLabelI>> => {
    return axios
        .post<CustomLabelI>(`${API_BASE_URL}/api/business/product/customLabel?businessId=${businessId}`, params)

        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: localeText?.common.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message
            return {
                error: {
                    message: error
                }
            }
        })
}

export const UpdateCustomLabel = async (
    businessId: number,
    params: Partial<CustomLabelRequestBodyI>,
    localeText: any
): Promise<ApiResponseI<CustomLabelI>> => {
    return axios
        .patch<CustomLabelI>(`${API_BASE_URL}/api/business/product/customLabel?businessId=${businessId}`, params)

        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: localeText?.common.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message
            return {
                error: {
                    message: error
                }
            }
        })
}

export const DeleteCustomLabel = async (
    businessId: number,
    params: DeleteCustomLabelI,
    localeText: any
): Promise<ApiResponseI<boolean>> => {
    return axios
        .delete(`${API_BASE_URL}/api/business/product/customLabel?businessId=${businessId}`, {
            data: params
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

export const GetCustomLabel = async (
    businessId: number,
    localeText: any
): Promise<ApiResponseI<GetRepsonseCollectionI<'labels', CustomLabelI>>> => {
    return axios
        .get<{ data: any } & GetRepsonseCollectionI<'labels', CustomLabelI>>(
            `${API_BASE_URL}/api/business/product/customLabel?businessId=${businessId}`
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}
/**
 * Add selling prices
 * @param businessId
 * @param productId
 * @param data
 * @returns
 */
export const AddSellingPrices = async (
    businessId: number,
    productId: string,
    data: ProductSellingPriceRequestBodyI[]
): Promise<boolean> => {
    return axios
        .post<boolean>(`${API_BASE_URL}/api/business/product/prices/bulk?businessId=${businessId}`, {
            productId,
            sellingPrices: data.map((item) => ({
                name: item.name,
                sellingPrice: item.price
            }))
        })
        .then((res) => {
            if (res.status === 200) {
                return true
            }

            return false
        })
        .catch((e) => {
            $error(e)

            return false
        })
}

/**
 * Update selling prices
 * @param businessId
 * @param data
 * @returns
 */
export const UpdateSellingPrices = async (
    businessId: number,
    data: ProductSellingPriceRequestBodyI[]
): Promise<boolean> => {
    return axios
        .patch<boolean>(`${API_BASE_URL}/api/business/product/prices/bulk?businessId=${businessId}`, {
            products: data.map((item) => ({
                productPriceID: item.id,
                productPriceName: item.name,
                sellingPrice: item.price
            }))
        })
        .then((res) => {
            if (res.status === 200) {
                return true
            }

            return false
        })
        .catch((e) => {
            $error(e)

            return false
        })
}

/**
 * Delete selling prices
 * @param businessId
 * @param data
 * @returns
 */
export const DeleteSellingPrices = async (businessId: number, data: string[]): Promise<boolean> => {
    return axios
        .delete<boolean>(`${API_BASE_URL}/api/business/product/prices/bulk?businessId=${businessId}`, {
            data: {
                productPriceIDS: data
            }
        })
        .then((res) => {
            if (res.status === 200) {
                return true
            }

            return false
        })
        .catch((e) => {
            $error(e)

            return false
        })
}

/**
 * Delete product
 */
export const DeleteProduct = async (
    businessId: number,
    productId: string,
    localeText: any
): Promise<ApiResponseI<boolean>> => {
    return axios
        .delete(`${API_BASE_URL}/api/business/product?businessId=${businessId}`, {
            data: {
                productId
            }
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText.common.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText.common.request_error
                }
            }
        })
}

/**
 * Get total product
 */
export const GetTotalProduct = async (businessId: number, localeText: any): Promise<ApiResponseI<number>> => {
    return axios
        .get<{ data: { data: []; totalCount: number } }>(
            `${API_BASE_URL}/api/business/product/total?businessId=${businessId}`
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data.totalCount
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Get all units
 */
export const GetUnits = async (
    businessId: number,
    localeText: any
): Promise<ApiResponseI<GetRepsonseCollectionI<'data', CategoryI>>> => {
    return axios
        .get<GetRepsonseCollectionI<'data', CategoryI>>(
            `${API_BASE_URL}/api/business/product/units?businessId=${businessId}`
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Get all categories
 */
export const GetCategory = async (
    businessId: number,
    localeText: any
): Promise<ApiResponseI<GetRepsonseCollectionI<'data', CategoryI>>> => {
    return axios
        .get<GetRepsonseCollectionI<'data', CategoryI>>(
            `${API_BASE_URL}/api/business/product/category?businessId=${businessId}`
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}
/**
 * Get Currency
 */
export const GetCurrency = async (localeText: any): Promise<ApiResponseI<string>> => {
    return axios
        .get(`${API_BASE_URL}/api/business/currency`)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}
/* Get Currency
 */
export const GetCurrencySymbol = async (currencyCode: string, localeText: any): Promise<ApiResponseI<string>> => {
    return axios
        .get(`${API_BASE_URL}/api/business/currency?code=${currencyCode}`)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

export const setCurrency = async (
    currencyCode: string,
    localeText: any,
    businessId: number
): Promise<ApiResponseI<string>> => {
    return axios
        .post(`${API_BASE_URL}/api/business/currency?businessId=${businessId}`, { code: currencyCode })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Create unit
 */
export const CreateUnit = async (
    businessId: number,
    data: CategoryI,
    localeText: any
): Promise<ApiResponseI<CategoryI>> => {
    const params: Omit<CategoryI, 'id'> = {
        name: data.name
    }

    return axios
        .post<CategoryI>(`${API_BASE_URL}/api/business/product/units?businessId=${businessId}`, params)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: localeText?.common.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common.request_error
                }
            }
        })
}
/**
 * Create category
 */
export const CreateCategory = async (
    businessId: number,
    data: CategoryI,
    localeText: any
): Promise<ApiResponseI<CategoryI>> => {
    const params: Omit<CategoryI, 'id'> = {
        name: data.name
    }
    return axios

        .post<CategoryI>(`${API_BASE_URL}/api/business/product/category?businessId=${businessId}`, params)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: localeText?.common.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common.request_error
                }
            }
        })
}

/**
 * Edit category
 */
export const EditCategory = async (
    businessId: number,
    data: CategoryI,
    localeText: any
): Promise<ApiResponseI<boolean>> => {
    return axios
        .patch<boolean>(`${API_BASE_URL}/api/business/product/category?businessId=${businessId}`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Delete category
 */
export const DeleteCategory = async (
    businessId: number,
    categoryId: string,
    localeText: any
): Promise<ApiResponseI<boolean>> => {
    return axios
        .delete(`${API_BASE_URL}/api/business/product/category?businessId=${businessId}`, {
            data: {
                id: categoryId
            }
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

// add prefix

export const AddPrefix = async (
    businessId: number,
    data: ChallanFormI,
    localeText: any
): Promise<ApiResponseI<string>> => {
    return axios
        .post<{ data: { prefixes: { prefixId: string } } } & ChallanFormI>(
            `${API_BASE_URL}/api/business/product/challan/prefix?businessId=${businessId}`,
            data
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data.prefixes.prefixId
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}
/**
 * Update stock
 */
// .patch<boolean>(`${API_BASE_URL}/business/${businessId}/product/stock/${type.replace('stock_', '')}`, data)

export const UpdateStock = async (
    businessId: number,
    data: UpdateStockI,
    type: StockActionT,
    localeText: any
): Promise<ApiResponseI<boolean>> => {
    return axios
        .patch<boolean>(
            `${API_BASE_URL}/api/business/product/stock/${type.replace('stock_', '')}?businessId=${businessId}`,
            data
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

export const UpdateManufacture = async (
    businessId: number,
    data: ManufactureUpdateI,
    localeText: any
): Promise<ApiResponseI<boolean>> => {
    return axios
        .patch<boolean>(`${API_BASE_URL}/api/business/product/stock/manufacture?businessId=${businessId}`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Get all histories
 */
export const GetHistory = async (
    businessId: number,
    params: HistoryFilterI,
    localeText: any
): Promise<ApiResponseI<GetRepsonseCollectionI<'histories', HistoryI>>> => {
    return axios
        .get<{ data: any } & GetRepsonseCollectionI<'histories', HistoryI>>(
            `${API_BASE_URL}/api/business/product/stock/history`,
            {
                params
            }
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

export const UpdateHistory = async (businessId: number, data: HistoryUpdateI): Promise<ApiResponseI<boolean>> => {
    return axios.patch<boolean>(
        `${API_BASE_URL}/api/business/product/stock/history/edit?businessId=${businessId}`,
        data
    )
}

/**
 * Get all staffs
 */
export const GetStaff = async (
    businessId: number,
    params: StaffFilterI,
    localeText: any
): Promise<ApiResponseI<GetRepsonseCollectionI<'staffs', StaffI>>> => {
    return axios
        .get<{ data: any } & GetRepsonseCollectionI<'staffs', StaffI>>(
            `${API_BASE_URL}/api/business/staff?businessId=${businessId}`,
            {
                params
            }
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data?.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Update stock
 */
export const UpdateStaff = async (
    businessId: number,
    data: UpdateStaffI,
    localeText: any
): Promise<ApiResponseI<boolean>> => {
    return axios
        .patch<boolean>(`${API_BASE_URL}/api/business/staff?businessId=${businessId}`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Delete staff
 */
export const DeleteStaff = async (
    businessId: number,
    staffId: string,
    localeText: any
): Promise<ApiResponseI<boolean>> => {
    return axios
        .delete(`${API_BASE_URL}/api/business/staff?businessId=${businessId}`, {
            data: {
                staffId
            }
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Get total staff
 */
export const GetTotalStaff = async (businessId: number, localeText: any): Promise<ApiResponseI<number>> => {
    return axios
        .get<{ data: { data: []; Total: number } }>(`${API_BASE_URL}/api/business/staff/total?businessId=${businessId}`)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data.Total
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Create join code
 */
export const CreateJoinCode = async (
    businessId: number,
    access: BusinessAccessT[],
    localeText: any
): Promise<ApiResponseI<string>> => {
    return axios
        .post<{ data: { joinCode: string } }>(`${API_BASE_URL}/api/business/joincode?businessId=${businessId}`, {
            access
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data.joinCode
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Check join code
 */
export const CheckJoinCode = async (joinCode: string, localeText: any): Promise<ApiResponseI<string>> => {
    return axios
        .post<{ data: { name: string } }>(`${API_BASE_URL}/api/business/checkjoin`, {
            joinCode
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data?.data.name
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Join business with code
 */

export const JoinBusinessWithCode = async (joinCode: string, localeText: any): Promise<ApiResponseI<BusinessI>> => {
    return axios
        .post<{ data: any } & BusinessI>(`${API_BASE_URL}/api/business/join`, {
            joinCode
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Get user profile
 */
export const GetProfile = async (localeText: any): Promise<ApiResponseI<ProfileII>> => {
    return axios
        .get<ProfileII>(`${API_BASE_URL}/api/user/profile`)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            console.error('Error:', e.response ? e.response.data : e.message)

            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

type UpdateProfileData = Omit<ProfileI, 'businesses'>

/**
 * Update user profile
 */
export const UpdateProfile = async (data: UpdateProfileData, localeText: any): Promise<ApiResponseI<boolean>> => {
    return axios
        .patch(`${API_BASE_URL}/api/user/profile`, { ...data, ProfileUrl: '' })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)

            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Upload Image
 */
interface UploadImageResponseI {
    data: string
}
export const UploadImage = async (formData: FormData, localeText: any): Promise<ApiResponseI<UploadImageResponseI>> => {
    return axios
        .post<{ data: any } & UploadImageResponseI>(`${API_BASE_URL}/api/upload`, formData, {
            headers: {
                'Content-Type': ' multipart/form-data'
            }
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)

            const error = e?.response?.data?.message
            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Delete Image
 */
export const DeleteImage = async (fileUrl: string, localeText: any): Promise<ApiResponseI<boolean>> => {
    return axios
        .delete(`${API_BASE_URL}/image`, {
            data: {
                fileUrl
            }
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)

            const error = e?.response?.data?.message
            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Get all suppliers
 */
export const GetSupplier = async (
    businessId: number,
    params: SupplierFilterI,
    localeText: any
): Promise<ApiResponseI<GetRepsonseCollectionI<'data', SupplierI>>> => {
    return axios
        .get<GetRepsonseCollectionI<'data', SupplierI>>(
            `${API_BASE_URL}/api/business/supplier?businessId=${businessId}`,
            {
                params
            }
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Create supplier
 */
export const CreateSupplier = async (
    businessId: number,
    data: Partial<SupplierI>,
    localeText: any
): Promise<ApiResponseI<SupplierI>> => {
    return axios
        .post<SupplierI>(`${API_BASE_URL}/api/business/supplier?businessId=${businessId}`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Update supplier
 */
export const UpdateSupplier = async (
    businessId: number,
    data: Partial<SupplierI>,
    localeText: any
): Promise<ApiResponseI<boolean>> => {
    return axios
        .patch<boolean>(`${API_BASE_URL}/api/business/supplier?businessId=${businessId}`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Delete supplier
 */
export const DeleteSupplier = async (
    businessId: number,
    supplierId: string,
    localeText: any
): Promise<ApiResponseI<boolean>> => {
    return axios
        .delete(`${API_BASE_URL}/api/business/supplier?businessId=${businessId}`, {
            data: {
                businessSupplierId: supplierId
            }
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Get all customers
 */
export const GetCustomer = async (
    businessId: number,
    params: CustomerFilterI,
    localeText: any
): Promise<ApiResponseI<GetRepsonseCollectionI<'data', CustomerI>>> => {
    return axios
        .get<GetRepsonseCollectionI<'data', CustomerI>>(
            `${API_BASE_URL}/api/business/customer?businessId=${businessId}`,
            {
                params
            }
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Customer Report
 */
export const GetCustomerReport = async (
    businessId: number,
    params: CustomerReportFilterI,
    localeText: any
): Promise<ApiResponseI<GetRepsonseCollectionI<'data', CustomerReportI>>> => {
    return axios
        .get<{ data: any } & GetRepsonseCollectionI<'data', CustomerReportI>>(
            `${API_BASE_URL}/api/business/reports/customer?businessId=${businessId}`,
            {
                params
            }
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data
                }
            }
            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
}

/**
 * Create customer
 */
export const CreateCustomer = async (
    businessId: number,
    data: Partial<CustomerI>,
    localeText: any
): Promise<ApiResponseI<CustomerI>> => {
    return axios

        .post<CustomerI>(`${API_BASE_URL}/api/business/customer?businessId=${businessId}`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: localeText.common.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const errorMessage = e.response.data.message
            return {
                error: {
                    message: errorMessage
                }
            }
        })
}

/**
 * Update customer
 */

export const UpdateCustomer = async (
    businessId: number,
    data: Partial<CustomerI>,
    localeText: any
): Promise<ApiResponseI<boolean>> => {
    return axios
        .patch<boolean>(`${API_BASE_URL}/api/business/customer?businessId=${businessId}`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const errorMessage = e.response.data.message
            return {
                error: {
                    message: errorMessage
                }
            }
        })
}

/**
 * Delete customer
 */
export const DeleteCustomer = async (
    businessId: number,
    customerId: string,
    localeText: any
): Promise<ApiResponseI<boolean>> => {
    return axios
        .delete(`${API_BASE_URL}/api/business/customer?businessId=${businessId}`, {
            data: {
                businessCustomerId: customerId
            }
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

// Get challan next number
export const GetChallanNextNumber = async (
    businessId: number,
    localeText: any
): Promise<ApiResponseI<GetRepsonseCollectionI<'data', WarehouseI>>> => {
    return axios
        .get<{ data: any } & GetRepsonseCollectionI<'data', WarehouseI>>(
            `${API_BASE_URL}/api/business/product/challan?businessId=${businessId}`
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res?.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)

            const error = e?.response?.data?.message
            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

// Get Warehouse
export const GetWareHouse = async (
    businessId: number,
    localeText: any,
    isActive?: boolean
): Promise<ApiResponseI<GetRepsonseCollectionI<'data', WarehouseI>>> => {
    return axios
        .get<{ data: any } & GetRepsonseCollectionI<'data', WarehouseI>>(
            `${API_BASE_URL}/api/business/product/warehouse/all?businessId=${businessId}${
                isActive ? `&isActive=${isActive}` : ''
            }`
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res?.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)

            const error = e?.response?.data?.message
            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

export const GetWareHouseByProductId = async (
    businessId: number,
    productId: number,
    localeText: any
): Promise<ApiResponseI<GetRepsonseCollectionI<'data', WarehouseI>>> => {
    return axios
        .get<{ data: any } & GetRepsonseCollectionI<'data', WarehouseI>>(
            `${API_BASE_URL}/api/business/product/warehouse?businessId=${businessId}&productId=${productId}`
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res?.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)

            const error = e?.response?.data?.message
            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

export const CreateWarehouse = async (
    businessId: number,
    data: Partial<WarehouseI>,
    localeText: any
): Promise<ApiResponseI<boolean>> => {
    return axios
        .post<boolean>(`${API_BASE_URL}/api/business/product/warehouse?businessId=${businessId}`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const errorMessage = e.response.data.message
            return {
                error: {
                    message: errorMessage
                }
            }
        })
}

export const UpdateWarehouse = async (
    businessId: number,
    params: Partial<WarehouseI>,
    localeText: any
): Promise<ApiResponseI<boolean>> => {
    return axios
        .patch<boolean>(`${API_BASE_URL}/api/business/product/warehouse?businessId=${businessId}`, params)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const errorMessage = e.response.data.message
            return {
                error: {
                    message: errorMessage
                }
            }
        })
}

export const DeleteWarehouse = async (
    businessId: number,
    params: Partial<WarehouseI>,
    localeText: any
): Promise<ApiResponseI<boolean>> => {
    return axios
        .delete<boolean>(`${API_BASE_URL}/api/business/product/warehouse/?businessId=${businessId}`, { data: params })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const errorMessage = e.response.data.message
            return {
                error: {
                    message: errorMessage
                }
            }
        })
}

/**
 * Tranfer Order
 */
export const TransferOrder = async (
    businessId: number,
    data: Partial<TransferOrderI>,
    localeText: any
): Promise<ApiResponseI<TransferOrderI>> => {
    return axios
        .post<ProductI>(`${API_BASE_URL}/api/business/product/warehouse/transferOrder?businessId=${businessId}`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data
                }
            }

            return {
                error: {
                    message: localeText?.common.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message
            return {
                error: {
                    message: error
                }
            }
        })
}

/**
 * Tranfer Order Update
 */
export const TransferOrderUpdate = async (
    businessId: number,
    data: Partial<TransferOrderI>,
    localeText: any
): Promise<ApiResponseI<TransferOrderI>> => {
    return axios
        .patch<ProductI>(`${API_BASE_URL}/api/business/product/warehouse/transferOrder?businessId=${businessId}`, data)
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data
                }
            }

            return {
                error: {
                    message: localeText?.common.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message
            return {
                error: {
                    message: error
                }
            }
        })
}

/**
 * Get Trasfer Order
 */
export const GetTransferOrder = async (
    businessId: number,
    params: any,
    localeText: any
): Promise<ApiResponseI<{ data: any } & GetRepsonseCollectionI<'data', TransferOrderIDetails>>> => {
    return axios
        .get<{ data: any } & GetRepsonseCollectionI<'data', TransferOrderIDetails>>(
            `${API_BASE_URL}/api/business/product/warehouse/transferOrder?businessId=${businessId}`,
            {
                params: params
            }
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)

            const error = e?.response?.data?.message
            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

export const GetTransferOrderById = async (
    businessId: number,
    orderId: number,
    localeText: any
): Promise<ApiResponseI<{ data: any } & GetRepsonseCollectionI<'data', TransferOrderIDetails>>> => {
    return axios
        .get<{ data: any } & GetRepsonseCollectionI<'data', TransferOrderIDetails>>(
            `${API_BASE_URL}/api/business/product/warehouse/transferOrder?businessId=${businessId}&transferOrder=${orderId}`
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res?.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)

            const error = e?.response?.data?.message
            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

export const ClearUserState = async () => {
    localStore.remove(StoreKey.ACCESS_TOKEN)
}

export interface BusinessReportParam {
    dateType: 'day' | 'week' | 'month' | 'year'
    orderBy: 'ASC' | 'DESC'
    startDate: string
    endDate: string
    timezone: string
}

export interface ReportData {
    date: string
    profit: number
    purchase: number
    sales: number
}

/**
 * Get all business report charts
 */
export const GetBusinessReportChart = async (
    businessId: number,
    params: BusinessReportParam,
    localeText: any
): Promise<ApiResponseI<ReportData[]>> => {
    return axios
        .get<{ data: any } & ReportData[]>(`${API_BASE_URL}/api/business/reports/chart?businessId=${businessId}`, {
            params
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

export interface SummaryData {
    currentAssetValue: number
    profitTotal: number
    purchaseTotal: number
    totalSales: number
}

/**
 * Get all business report summary
 */
export const GetBusinessReportSummary = async (
    businessId: number,
    params: { startDate: string; endDate: string; timezone: string },
    localeText: any
): Promise<ApiResponseI<SummaryData>> => {
    return axios
        .get<{ data: any } & SummaryData>(`${API_BASE_URL}/api/business/reports/summary?businessId=${businessId}`, {
            params
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Get all business report transaction
 */
export const GetBusinessReportTransaction = async (
    businessId: number,
    params: BusinessReportParam,
    localeText: any
): Promise<ApiResponseI<ReportData[]>> => {
    return axios
        .get<ReportData[]>(`${API_BASE_URL}/api/business/reports/transaction?businessId=${businessId}`, {
            params
        })
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Download History Excel Report
 */
export const DownloadHistoryExcelReport = async (
    params: HistoryFilterI,
    localeText: any,
    language: string,
    myCurrency: string,
    invoiceType?: string | null | undefined
): Promise<ApiResponseI<boolean>> => {
    return axios
        .post<Blob>(
            `/api/download/excel`,
            {
                ...params,
                localeText,
                language,
                myCurrency,
                invoiceType
            },
            {
                responseType: 'blob'
            }
        )
        .then((res) => {
            if (res.status === 200) {
                const pdfUrl = window.URL.createObjectURL(res.data)
                const link = document.createElement('a')
                link.href = pdfUrl

                link.setAttribute('download', params.fileName + '.xlsx')

                document.body.appendChild(link)

                link.click()

                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
}

export const DownloadProductPdfReport = async (
    params: ProductFilterI,
    filename: string,
    localeText: any
): Promise<ApiResponseI<boolean>> => {
    return axios
        .post<Blob>(
            `/api/download/pdf`,
            {
                params
            },
            {
                responseType: 'blob'
            }
        )
        .then((res) => {
            if (res.status === 200) {
                const pdfUrl = window.URL.createObjectURL(res.data)
                const link = document.createElement('a')
                link.href = pdfUrl
                link.setAttribute('download', `${filename}.pdf`)
                document.body.appendChild(link)
                link.click()
                return { data: true }
            }
            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
}

export const DownloadInvoicePdf = async (
    params: HistoryInvoiceParams,
    filename: string,
    localeText: any
): Promise<ApiResponseI<boolean>> => {
    return axios
        .post(`/api/download/invoice`, { ...params }, { responseType: 'blob' })
        .then((res) => {
            if (res.status === 200) {
                const blob = new Blob([res.data], { type: 'application/pdf' }) // Explicitly set type
                const pdfUrl = URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = pdfUrl
                link.setAttribute('download', `${filename}.pdf`)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link) // Cleanup
                URL.revokeObjectURL(pdfUrl) // Free up memory
                return { data: true }
            }
            return { error: { message: localeText?.common?.request_error } }
        })

        .catch((e) => {
            $error(e)
            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
}

/**
 * Get stock report
 */
export const GetStockReport = async (
    businessId: number,
    params: StockReportFilterI,
    localeText: any
): Promise<ApiResponseI<GetRepsonseCollectionI<'data', StockReportI>>> => {
    return axios
        .get<{ data: any } & GetRepsonseCollectionI<'data', StockReportI>>(
            `${API_BASE_URL}/api/business/product/stock/report?businessId=${businessId}`,
            {
                params
            }
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Get stock report
 */
export const GetProductTotal = async (
    businessId: number,
    localeText: any
): Promise<ApiResponseI<{ total: ProdcutTotalI }>> => {
    return axios
        .get<{ data: any } & { total: ProdcutTotalI }>(
            `${API_BASE_URL}/api/business/product/v2/total?businessId=${businessId}`
        )
        .then((res) => {
            if (res.status === 200) {
                return {
                    data: res.data.data.total
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)
            const error = e?.response?.data?.message

            return {
                error: {
                    message: error || localeText?.common?.request_error
                }
            }
        })
}

/**
 * Download Excel Report
 */
export const DownloadExcelReport: <T extends Record<string, any>>(
    params: T,
    fileName: string,
    template: string,
    endpoint: string,
    mapToData?: string | string[],
    localeText?: any,
    language?: string,
    myCurrency?: string
) => Promise<ApiResponseI<boolean>> = async (
    params,
    fileName,
    template,
    endpoint,
    mapToData,
    localeText,
    language,
    myCurrency
): Promise<ApiResponseI<boolean>> => {
    const searchParams = new URLSearchParams()

    Object.entries(params).forEach(([key, value]) => {
        if (key === 'staffId') {
            if (Array.isArray(value) && value.length > 0) {
                value.forEach((v) => searchParams.append(key, String(v)))
            }
        } else if (value !== undefined && value !== null && value !== '') {
            searchParams.append(key, String(value))
        }
    })
    return axios
        .post<Blob>(
            `/api/download/report`,
            {
                fileName: fileName,
                template,
                endpoint: `${endpoint}${searchParams.toString() ? '&' + searchParams.toString() : ''}`,

                mapToData,
                localeText,
                language,
                myCurrency
            },
            {
                responseType: 'blob'
            }
        )
        .then(async (res) => {
            if (res.status === 200) {
                try {
                    const buffer = Array.from(new Uint8Array(await res.data.arrayBuffer()))
                    AppChannel.postMessage('download:' + buffer)
                    $log('Download', 'Post Message', buffer)
                } catch (e) {
                    const fileUrl = window.URL.createObjectURL(res.data)
                    const link = document.createElement('a')
                    link.href = fileUrl

                    link.setAttribute('download', fileName + '.xlsx')

                    document.body.appendChild(link)

                    link.click()
                    $log('Download', 'Default')
                }

                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
}

/**
 * Download Product Excel Report
 */
export const DownloadProductExcelReport = async (
    params: ProductFilterI,
    businessId: string,
    fileName: string,
    localeText: any,
    language: string,
    myCurrency: string,
    invoiceType?: string | null | undefined
): Promise<ApiResponseI<boolean>> => {
    return axios
        .post<Blob>(
            `/api/download/product`,
            {
                params,
                businessId: businessId,
                fileName: fileName,
                localeText,
                language,
                myCurrency,
                invoiceType
            },
            {
                responseType: 'blob'
            }
        )
        .then((res) => {
            if (res.status === 200) {
                const pdfUrl = window.URL.createObjectURL(res.data)
                const link = document.createElement('a')
                link.href = pdfUrl

                link.setAttribute('download', fileName + '.xlsx')

                document.body.appendChild(link)

                link.click()

                return {
                    data: true
                }
            }

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
        .catch((e) => {
            $error(e)

            return {
                error: {
                    message: localeText?.common?.request_error
                }
            }
        })
}

/**
 * Get collection data
 * @param url
 * @param params
 * @returns
 */
export const getCollection = async <T, P, F extends string = 'data', W extends boolean = false>(
    url: string,
    params?: CollectionApiParams<P>,
    baseUrl: keyof typeof baseUrlMap = 'v1'
) => {
    const response = await axios.get<
        W extends false
            ? CollectionApiResponse<T, F> & { data: any }
            : { data?: CollectionApiResponse<T, F> & { data: any } }
    >(`${baseUrlMap[baseUrl]}${url}`, {
        params
    })

    return response?.data?.data
}
/**
 * Get single data
 * @param url
 * @returns
 */
export const getSingle = async <T>(url: string, baseUrl: keyof typeof baseUrlMap = 'v1') => {
    const response = await axios.get<SingleApiResponse<T>>(`${baseUrlMap[baseUrl]}${url}`)
    return response.data
}
const baseUrlMap = {
    v1: API_BASE_URL,
    v2: API_BASE_URL_V2,
    auth: AUTH_API_BASE_URL,
    oauth: OAUTH_API_BASE_URL
}
