const featuresPay = {
    featureTitle: 'Toma Decisiones Empresariales Basadas en Datos',
    featureDescription:
        'Conoce qué artículos se están vendiendo y proporcionando más ganancias, y accede a informes financieros en cualquier momento y lugar. ¡Tu negocio en la palma de tu mano!',
    featureButton: 'Suscripción Premium desde la App',
    featureStartingPrice: '80',
    featureEndingPart: '30',
    featureStaringText: 'A partir de',
    featureEndingText: 'Mil',

    features: [
        {
            title: 'Artículos Ilimitados',
            description: 'Agrega todos los artículos que posees sin ningún límite.'
        },
        {
            title: 'Personal Ilimitado',
            description: 'Agrega Personal/Administradores al negocio sin ningún límite.'
        },
        {
            title: 'Historial Ilimitado',
            description: 'Ve los cambios en los registros de stock antiguos sin ningún límite.'
        },
        {
            title: 'Filtro de Historial',
            description: 'Ve fácilmente el historial basado en tipo, categoría, personal y fecha.'
        },
        {
            title: 'Descargar a Excel',
            description: 'Obtén el historial de registros de stock en Microsoft Excel con un solo clic.'
        },
        {
            title: 'Descargar a PDF',
            description: 'Obtén la lista de los últimos artículos y stock en PDF con un solo clic.'
        }
    ],
    warehousesGuide: [
        {
            desc: 'Cree múltiples almacenes para una mejor gestión del stock.'
        },
        {
            desc: 'Asigne productos a ubicaciones específicas.'
        },
        {
            desc: 'Supervise los niveles de inventario en los almacenes.'
        }
    ],
    addVariantsGuide: [
        { desc: 'Agregue variantes de tamaño, color o material.' },
        { desc: 'Establezca niveles de stock por variante.' },
        { desc: 'Realice un seguimiento en los almacenes.' }
    ]
}

export default featuresPay
