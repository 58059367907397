const featuresPay = {
    featureTitle: 'Treffen Sie Geschäftsentscheidungen basierend auf Daten',
    featureDescription:
        'Erfahren Sie, welche Artikel verkauft werden und den meisten Gewinn bringen, und greifen Sie jederzeit und überall auf Finanzberichte zu. Ihr Geschäft in der Hand!',
    featureButton: 'Premium-Abonnement aus der App',
    featureStartingPrice: '80',
    featureEndingPart: '30',
    featureStaringText: 'Ab',
    featureEndingText: 'Tausend',

    features: [
        {
            title: 'Unbegrenzte Artikel',
            description: 'Fügen Sie alle Artikel hinzu, die Sie besitzen, ohne Einschränkungen.'
        },
        {
            title: 'Unbegrenztes Personal',
            description: 'Fügen Sie Mitarbeiter/Administratoren ohne Einschränkungen zum Unternehmen hinzu.'
        },
        {
            title: 'Unbegrenzte Historie',
            description: 'Sehen Sie Änderungen an alten Bestandsaufzeichnungen ohne Einschränkungen ein.'
        },
        {
            title: 'Historienfilter',
            description: 'Sehen Sie die Historie einfach basierend auf Typ, Kategorie, Personal und Datum ein.'
        },
        {
            title: 'Download nach Excel',
            description: 'Erhalten Sie die Bestandsaufzeichnungshistorie mit einem einzigen Klick in Microsoft Excel.'
        },
        {
            title: 'Download nach PDF',
            description: 'Erhalten Sie die neuesten Artikel- und Bestandslisten mit einem einzigen Klick im PDF-Format.'
        }
    ],
    warehousesGuide: [
        {
            desc: 'Erstellen Sie mehrere Lager für eine bessere Bestandsverwaltung.'
        },
        {
            desc: 'Weisen Sie Produkte bestimmten Standorten zu.'
        },
        {
            desc: 'Überwachen Sie die Lagerbestände in den Lagern.'
        }
    ],
    addVariantsGuide: [
        { desc: 'Fügen Sie Größen-, Farb- oder Materialvarianten hinzu.' },
        { desc: 'Legen Sie Lagerbestände pro Variante fest.' },
        { desc: 'Verfolgen Sie über Lager hinweg.' }
    ]
}

export default featuresPay
