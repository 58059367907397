import common from './common'
import featuresPay from './feature'

const es = {
    featuresPay,
    common,
    short: {
        finance: 'Finanzas',
        stock: 'Inventario'
    },
    onboarding: {
        welcome: '¡Bienvenido!',
        step: {
            product: {
                title: '65% Alcanzado',
                description: '¡Solo quedan 2 pasos para comenzar tu gran éxito!'
            },
            stock: {
                title: '95% Casi Listo!!',
                description: 'Solo un paso más para comenzar tu gran éxito. Termínalo ahora.'
            }
        },
        business: {
            title: '¡Negocio creado con éxito!',
            description: '¡Genial! Tu negocio ha sido registrado'
        },
        product: {
            title: 'Agrega tu primer producto',
            description: 'Agrega un producto para comenzar a registrar inventario'
        },
        stock: {
            title: 'Intenta agregar inventario',
            description: 'Comienza a registrar el inventario entrante y saliente'
        },
        action: {
            add_business: {
                title: 'Crear Negocio',
                description: '¿Quieres crear un nuevo negocio? Haz clic aquí'
            },
            join_business: {
                title: 'Unirse a un Negocio',
                description: '¿Tienes un código de invitación? Únete aquí'
            }
        }
    },
    business: {
        title: 'Selecciona un negocio, crea uno nuevo o únete',
        add_business: {
            title: 'Crear nuevo negocio',
            name: 'Nombre del negocio',
            name_placeholder: 'Ingresa el nombre',
            category: 'Categoría del negocio',
            category_placeholder: 'Selecciona la categoría',
            button: 'Crear negocio'
        },
        edit_business: {
            title: 'Editar negocio'
        },
        join_business: {
            title: 'Unirse a un negocio',
            input_code: 'Ingresa el código de invitación a continuación:',
            field_placeholder: 'Código de invitación',
            confirm_title: 'Unirse',
            confirm_text: 'Te convertirás en parte del equipo en '
        },
        no_business: 'No hay negocios disponibles',
        create_or_join: 'Crear o unirse a un negocio',
        message: {
            saved: '¡Negocio guardado con éxito!'
        }
    },
    profile: {
        edit_profile: {
            title: 'Editar perfil',
            name: 'Nombre completo',
            name_placeholder: 'Ingresa el nombre completo',
            email: 'Correo electrónico',
            phone_number: 'Número de teléfono'
        },
        message: {
            required_name: 'El nombre es obligatorio'
        }
    },
    product: {
        choose_product: 'Elegir producto',
        choose_product_empty: 'Selecciona un producto e ingresa la cantidad',
        search: {
            placeholder: 'Buscar por nombre',
            tooltip: 'Abrir escáner'
        },
        column: {
            image: 'Foto',
            name: 'Nombre',
            price: 'Precio',
            buying_price: 'Precio de costo',
            selling_price: 'Precio de venta',
            sku: 'SKU',
            stock: 'Stock',
            quantity: 'Cantidad',
            latest_stock: 'Restante',
            product_deleted: 'El producto ha sido eliminado',
            info_not_found: 'Información no disponible',
            expired_date: 'Fecha de expiración'
        },
        no_product: 'No hay productos disponibles',
        add_product: {
            sku: 'Código del producto',
            sku_placeholder: 'Código del producto. Ejemplo: 12345',
            name: 'Nombre del producto',
            name_placeholder: 'Ingresa el nombre del producto',
            initial_stock: 'Stock inicial',
            initial_stock_placeholder: 'Ingresa el stock inicial',
            buying_price: 'Precio de costo',
            buying_price_placeholder: 'Ingresa el precio de costo',
            selling_price: 'Precio de venta',
            selling_price_placeholder: 'Ingresa el precio de venta',
            category: 'Categoría',
            category_placeholder: 'Seleccionar categoría',
            add_selling_price: 'Agregar precio al por mayor',
            expired_date: 'Fecha de expiración',
            expired_date_placeholder: 'Seleccionar fecha',
            description: 'Descripción',
            description_placeholder: 'Ingresa la descripción'
        },
        choose_selling_price: 'Elegir precio de venta',
        selling_price: 'Precio de venta',
        total_product: 'Total de productos',
        total_quantity: 'Total de stock',
        total_buying_price: 'Total del precio de costo',
        total_selling_price: 'Total del precio de venta',
        message: {
            required_name: 'El nombre del producto es obligatorio',
            required_sku: 'El SKU es obligatorio',
            required_category: 'La categoría es obligatoria',
            required_quantity: 'El stock inicial es obligatorio',
            invalid_input_qty: 'Cantidad de stock inválida',
            required_buying_price: 'El precio de costo es obligatorio',
            required_selling_price: 'El precio de venta es obligatorio',
            required_selling_price_name: 'El nombre del precio de venta es obligatorio',
            required_selling_price_price: 'El precio de venta es obligatorio'
        },
        viewMore: 'Ver más',
        WarehouseDetails: 'Detalles del almacén',
        WarehouseName: 'Nombre del almacén',
        AddWarehouse: 'Agregar almacén',
        WarehouseQty: 'Cantidad de almacén',
        Warehouse: 'Almacén',
        Warehouses: 'Almacenes',
        Variants: 'Variantes',
        VariantName: 'Nombre de variante',
        productVariant: 'Variantes de producto',
        AddVariant: 'Agregar variantes',
        TranferItem: 'Transferir artículos',
        AddUnit: 'Nueva unidad',
        InitialStkWarehouse: 'Stock inicial requerido para agregar variantes',
        InitialStockinwarehouse: 'Stock inicial en almacén',
        TotalQuantity: 'Cantidad total:',
        AddNote: 'Agregar nota (precio de costo)',
        AddNotetitle: 'Agregar nota',
        VariantSuccessMessage: 'Variante del producto actualizada con éxito',
        Next: 'Siguiente',
        Submit: 'Enviar',
        INACTIVE: 'INACTIVO',
        errorone: '¡Vaya! La cantidad solicitada para',
        errortwo: 'no coincide con las cantidades asignadas del almacén. Ajuste la cantidad o reabastezca.',
        SelectWarehouse: 'Seleccionar Almacén',
        SelectVariant: 'Seleccionar Variante',
        Discount: 'Descuento',
        DiscountDetails: 'Detalles del descuento',
        FinalPrice: 'Precio final',
        DiscountType: 'Tipo de descuento',
        DiscountPer: 'Descuento %',
        DiscountValue: 'Valor del descuento',
        Discountonprice: 'Descuento en el precio',
        Discountondiscount: 'Descuento sobre descuento',
        AdditionalDiscount: 'Descuento adicional',
        SellingPrice: 'Precio de venta:',
        Save: 'Guardar',
        OnOriginalPrice: 'Sobre el precio original',
        OnDiscountedPrice: 'Sobre el precio con descuento'
    },
    note: {
        title: 'Notas',
        add: 'Agregar Nota',
        no_note: 'No hay notas disponibles'
    },
    settings: {
        category: 'Categoría',
        language: 'Idioma',
        switch_business: 'Cambiar Negocio',
        currencySelect: 'Moneda'
    },
    category: {
        category_list: {
            title: 'Categorías de Productos'
        },
        add_category: {
            button: 'Nueva Categoría',
            title: 'Agregar Nueva Categoría'
        },
        edit_category: {
            title: 'Editar Categoría'
        },
        name: 'Nombre de la Categoría',
        name_placeholder: 'Ingrese el Nombre de la Categoría',
        no_category: 'No hay categorías disponibles'
    },
    staff: {
        add: 'Agregar Personal',
        edit: 'Editar Personal',
        access: {
            stock: 'Inventario',
            add_product: 'Agregar nuevo producto',
            edit_product: 'Editar producto',
            delete_product: 'Eliminar producto',
            view_capital_price: 'Ver Precio de Costo',
            add_category: 'Agregar nueva categoría',
            edit_category: 'Editar categoría',
            delete_category: 'Eliminar categoría',
            add_supplier: 'Agregar nuevo proveedor',
            edit_supplier: 'Editar proveedor',
            delete_supplier: 'Eliminar proveedor',
            add_customer: 'Agregar nuevo cliente',
            edit_customer: 'Editar cliente',
            delete_customer: 'Eliminar cliente',
            add_staff: 'Agregar personal',
            edit_staff: 'Editar personal',
            delete_staff: 'Eliminar personal',
            change_business_profile: 'Cambiar perfil de negocio'
        },
        no_staff: 'No hay personal disponible.'
    },
    code: {
        title: '¡Nuevo Personal Agregado!',
        subtitle: 'Comparte el código de invitación a continuación para unirte:',
        valid: 'Código válido solo por 48 horas',
        share: 'Compartir en WhatsApp',
        copied: '¡Copiado!',
        invalid: '¡Código inválido!'
    },
    history: {
        no_history: 'No hay historial.',
        detail: {
            remove: 'Eliminar Historial',
            confirm: 'Esta función afectará el inventario existente, el historial y los informes.'
        }
    },
    supplier: {
        add: {
            title: 'Agregar Proveedor'
        },
        edit: {
            title: 'Editar Proveedor'
        },
        new: 'Nuevo proveedor',
        no_supplier: 'No hay proveedores disponibles.',
        bank: {
            title: 'Detalles Bancarios'
        },
        note: 'Notas',
        form: {
            name: {
                label: 'Nombre del Proveedor',
                placeholder: 'Ingrese el Nombre del Proveedor',
                placheolder_not_mandatory: 'Ingrese el nombre (opcional)',
                error: {
                    required: 'El nombre no puede estar vacío'
                }
            },
            contact: {
                label: 'Número de Contacto',
                placeholder: '91934554432',
                error: {
                    required: 'El número de contacto no puede estar vacío',
                    invalid: 'Número inválido. Debe incluir el código de país'
                }
            },
            bank_name: {
                label: 'Nombre del Banco',
                placeholder: 'Ingrese el Nombre del Banco'
            },
            bank_account_name: {
                label: 'Nombre del Titular de la Cuenta',
                placeholder: 'Ingrese el Nombre del Titular de la Cuenta'
            },
            bank_account_number: {
                label: 'Número de Cuenta',
                placeholder: 'Ingrese el Número de Cuenta'
            },
            note: {
                label: 'Notas',
                placeholder: 'Agregar Notas'
            }
        },
        choose: 'Elegir Proveedor'
    },
    customer: {
        add: {
            title: 'Agregar Cliente'
        },
        edit: {
            title: 'Editar Cliente'
        },
        new: 'Nuevo cliente',
        no_customer: 'No hay clientes disponibles.',
        note: 'Notas',
        form: {
            name: {
                label: 'Nombre del Cliente',
                placeholder: 'Ingrese el Nombre del Cliente',
                placheolder_not_mandatory: 'Ingrese el nombre (opcional)',
                error: {
                    required: 'El nombre no puede estar vacío'
                }
            },
            contact: {
                label: 'Número de Contacto',
                placeholder: '91934554432',
                error: {
                    required: 'El número de contacto no puede estar vacío',
                    invalid: 'Número inválido. Debe incluir el código de país'
                }
            },
            address: {
                label: 'Dirección',
                placeholder: 'Ingrese la Dirección'
            },
            note: {
                label: 'Notas',
                placeholder: 'Agregar Notas'
            }
        },
        choose: 'Seleccionar Comprador',
        report: {
            view: 'Ver Informe de Compradores',
            title: 'Informe de Compradores',
            no_report: 'No hay informes para compradores en la fecha seleccionada.',
            column: {
                name: 'Nombre',
                total_transaction: 'Total de Transacciones',
                total_amount: 'Total de Ventas',
                total_profit: 'Total de Ganancias'
            }
        }
    },
    confirm_delete: '¿Está seguro de que desea eliminar estos datos?',
    report: {
        title: 'Informe'
    },
    accounting: {
        title: 'Contabilidad',
        view_report: 'Ver Informe Financiero',
        dashboard_title: 'Informe Financiero',
        stats: 'Estadísticas',
        transaction_report: 'Informe de Transacciones',
        filter: {
            date: {
                placeholder: 'Seleccionar Fecha',
                submit: 'Aplicar',
                cancel: 'Restablecer Filtro'
            },
            timeframe: {
                day: 'Diario',
                week: 'Semanal',
                month: 'Mensual',
                year: 'Anual'
            }
        },
        total_sales: 'Ventas Totales',
        total_purchases: 'Compras Totales',
        total_profit: 'Beneficio Total',
        assets_value: 'Valor Actual de Activos',
        table: {
            date: 'Fecha',
            sales: 'Ventas',
            purchases: 'Compras',
            profit: 'Beneficio'
        },
        no_data: 'No hay datos disponibles.'
    },
    stock_report: {
        title: 'Informe de Stock',
        view: 'Ver Informe de Stock',
        no_report: 'No hay registros de stock para la fecha o personal seleccionado.',
        allStaffPlaceHolder: 'Todo el Personal',
        column: {
            total_product: 'Tipos de Productos Actuales',
            total_product_quantity: 'Total de Productos Actuales'
        }
    },
    subscription: {
        paywall_small: {
            title: 'Función VIP',
            description:
                'Tu período de prueba para esta función ha terminado. Apoya el desarrollo de esta aplicación local suscribiéndote.',
            button: 'Ver Planes de Suscripción'
        }
    },
    payment: {
        order: {
            title: 'Pago'
        }
    },
    imageUploadMessage: {
        uploadSize: '¡El tamaño de la imagen no debe exceder 5 MB!',
        failedMessage: 'No se pudo subir la imagen del artículo, ¡por favor intenta de nuevo!',
        successUpload: '¡La imagen se subió correctamente!',
        failedUIError: 'No se pudo eliminar la imagen, ¡por favor intenta de nuevo!',
        SuccessErrorImage: '¡La imagen ha sido eliminada con éxito!'
    },
    productColumns: {
        SKULabel: 'SKU',
        nameLabel: 'Nombre del Producto',
        categoryLabel: 'Categoría',
        BuyingPriceLabel: 'Precio de Compra',
        SellingLabel: 'Precio de Venta',
        StockLabel: 'Stock'
    },
    historyColumns: {
        Transactionlabel: 'Tipo de Transacción',
        Locallabel: 'Hora Local',
        Createdlabel: 'Creado Por',
        Vendorlabel: 'Nombre del Vendedor',
        Buyerlabel: 'Nombre del Comprador',
        SKUlabel: 'SKU',
        Productlabel: 'Nombre del Producto',
        Categorylabel: 'Categoría',
        Buyinglabel: 'Precio de Compra',
        Sellinglabel: 'Precio de Venta',
        Initiallabel: 'Stock Inicial',
        Finallabel: 'Stock Final',
        Stocklabel: 'Cambio de Stock'
    },
    stockReportColumns: {
        skuLabel: 'SKU',
        productNameLabel: 'Nombre del Producto',
        categoryLabel: 'Categoría',
        buyingPriceLabel: 'Precio de Compra',
        sellingPriceLabel: 'Precio de Venta',
        stockInLabel: 'Stock Entrante',
        stockOutLabel: 'Stock Saliente',
        remainingStockLabel: 'Stock Restante'
    },
    accountingReportColumns: {
        labelDate: 'Fecha',
        labelSales: 'Ventas',
        labelPurchases: 'Compras',
        labelProfit: 'Ganancia'
    },
    customerReportColumns: {
        labelName: 'Nombre',
        labelTotalTransaction: 'Transacción Total',
        labelTotalSales: 'Ventas Totales',
        labelTotalProfit: 'Ganancia Total'
    },
    scannerDialog: {
        finished: 'Terminado',
        reduce: 'Reducir',
        successfull: 'Exitoso',
        searching: 'Buscando...',
        title: 'Escáner activo. ¡Apúntalo al código de barras!'
    },
    historyFilter: {
        SelectBuyer: 'Seleccionar Comprador',
        SelectVendor: 'Seleccionar Vendedor'
    },
    productStockFilter: {
        StockLowtoHigh: 'Stock Bajo a Alto',
        StockHightoLow: 'Stock Alto a Bajo',
        AlphabetAZ: 'Alfabeto A-Z',
        AlphabetZA: 'Alfabeto Z-A'
    },
    minimizeTitle: 'Escáner activo',
    security_guarantee: {
        title: 'Seguridad de Datos Garantizada'
    },
    invoice: {
        invoicePlaceholder: 'Número de factura',
        invoiceTitle: 'Factura'
    },
    stocklowreport: {
        no_stocklowreport_one: 'No hay elementos con configuraciones de seguridad de stock.',
        no_stocklowreport_two: 'Por favor, agregue la cantidad de seguridad de stock.'
    },
    low_alert_stock: 'Ingrese la cantidad de seguridad de stock',
    lowStockAlertNotify: 'Recibirá una notificación cuando el stock esté por debajo de la cantidad establecida.',
    applyButtonStockAlert: 'Aplicar',
    cancelButtonStockAlert: 'Cancelar',
    searchProductLabel: 'Buscar producto...',
    successfullyAddedSafetyStock: 'Stock de seguridad actualizado con éxito',
    safety_Stock: 'Stock de Seguridad',
    safety_stock_placeholder: 'Ingrese Stock de Seguridad',
    lowStockDetailsInfo: {
        ProductCode: 'Código de Producto',
        SafetyStock: 'Stock de Seguridad',
        Stock: 'Inventario',
        CostPrice: 'Precio de Costo',
        SellingPrice: 'Precio de Venta',
        Category: 'Categoría',
        Description: 'Descripción',
        ExpirationDate: 'Fecha de Caducidad'
    },
    CustomLang: {
        Attribute: 'Atributo',
        AddAttribute: 'Agregar Atributo',
        EditAttribute: 'Editar Atributo',
        CustomLabePlaceholder: 'Ingrese el nombre del atributo',
        CustomLabePlaceholderSelect: 'Seleccionar tipo',
        AddMoreAttribute: 'Agregar más Atributo',
        ItemAttribute: 'Atributo del artículo',
        TableName: 'Nombre',
        TableType: 'Tipo',
        TableAction: 'Acción',
        TableDelete: 'Eliminar',
        TableEdit: 'Editar',
        deleteMessage: 'Atributo eliminado con éxito',
        editSuccessMessage: 'Atributo actualizado con éxito',
        AddSuccessMessage: 'Atributo agregado con éxito',
        AddAtt: 'Agregar',
        EditAtt: 'Editar',
        SubmitAtt: 'Enviando...'
    },
    Expenses: {
        ExpensesTitle: 'Gastos',
        AddExpenses: 'Agregar gastos',
        ExpenseCategory: 'Categoría de gastos',
        AddExpenseCategory: 'Agregar categoría de gastos',
        NoExpenseCategory: 'No hay categoría de gasto',
        SelectExpenseCategory: 'Seleccionar categoría de gastos',
        NoExpenses: 'No hay gastos',
        ExpensesSuccess: 'Gasto creado con éxito',
        ExpensesSuccessEdit: 'Gasto actualizado con éxito',
        ExpensesCategoryName: 'Nombre de la categoría de gastos',
        ExpensesSuccessDelete: 'Gasto eliminado con éxito',
        TotalSpentThisWeek: 'Total gastado esta semana'
    },
    Manufacturing: {
        AddManufacture: 'Agregar detalles de fabricación',
        Manufacturing: 'Fabricación',
        RawMaterials: 'Materias primas',
        AdditonCosts: 'Costos adicionales',
        RawMaterial: 'Materia prima',
        AdditonCost: 'Costo adicional',
        TotalCost: 'Costo total',
        AddRawMaterial: 'Agregar materia prima para',
        AddAdditonCost: 'Agregar costo adicional para',
        AddMore: 'Agregar más',
        Add: 'Agregar',
        Update: 'Actualizar',
        Updating: 'Enviando...',
        ProductRequired: 'El producto es obligatorio',
        QuantityRequired: 'La cantidad es obligatoria',
        ChargeRequired: 'El cargo es obligatorio',
        EstimatedCostRequired: 'El costo estimado es obligatorio',
        PurchasePriceRequired: 'El precio de compra es obligatorio',
        SelectCharge: 'Seleccionar cargo',
        EstimatedCost: 'Costo estimado',
        SelectProduct: 'Seleccionar un producto',
        Quantity: 'Cantidad',
        ManufactureDetails: 'Detalles de fabricación',
        BuyingPrice: 'Precio de compra',
        LabourCharge: 'Costo de mano de obra',
        ElectricityCost: 'Costo de electricidad',
        PackagingCharge: 'Costo de empaque',
        LogisticCost: 'Costo logístico',
        OtherCharges: 'Otros cargos',
        Save: 'Guardar detalles',
        PurchasePrice: 'Precio de compra',
        Date: 'Fecha',
        For: 'Para',
        PurchaseRate: 'Tasa de compra (Precio)',
        Scrolltoloadmore: 'Desplázate para cargar más...',
        Noproductsfound: 'No se encontraron productos',
        ManufacturingQuantity: 'Cantidad de fabricación',
        ManufactureAddedSuccess: 'Detalles de fabricación actualizados con éxito'
    },
    Warehouses: {
        transferOrder: 'Transferir artículos',
        AddTransferOrder: 'Agregar artículos a la transferencia',
        NewWarehouse: 'Nuevo almacén',
        newHead: '¡Empieza a gestionar múltiples almacenes!',
        headTwo: 'Realiza un seguimiento y gestiona tu inventario sin problemas en múltiples almacenes',
        EnableWarehouse: 'HABILITAR ALMACÉN',
        EnableWarehouse2: 'Habilitar almacén',
        OrganizationPrimary: 'Principal de la organización',
        Edit: 'Editar',
        AreEnableWarehouse: '¿Estás seguro de que quieres habilitar el almacén?',
        Confirm: 'Confirmar',
        Cancel: 'Cancelar',
        WarehouseCreatedSuccessfully: 'Almacén creado con éxito',
        MarkAsOP: 'Marcar como principal de la organización',
        MarkAsInAct: 'Marcar como inactivo',
        MarkAsAct: 'Marcar como activo',
        MarkedAsInactive: 'Almacén marcado como inactivo con éxito',
        MarkedAsActive: 'Almacén marcado como activo con éxito',
        MarkedAsOP: 'Almacén marcado como principal de la organización con éxito',
        WareDeletedSuccess: 'Almacén eliminado con éxito',
        SuccessfullyUpdatedWarehouse: 'Almacén actualizado con éxito',
        SuccessfullyCreatedWarehouse: 'Almacén creado con éxito',
        EditWarehouse: 'Editar almacén',
        AddWarehouse: 'Agregar almacén',
        WarehouseNameIsRequired: 'El nombre del almacén es obligatorio',
        EnterWarehouseName: 'Ingresa el nombre del almacén',
        EnterEmail: 'Ingresa el correo electrónico',
        EnterPhoneNumber: 'Ingresa el número de teléfono',
        Location: 'Ubicación...',
        name: 'Nombre del almacén',
        email: 'Correo electrónico',
        phoneNumber: 'Número de teléfono',
        location: 'Ubicación'
    }
}

export default es
