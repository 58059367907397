import common from './common'
import featuresPay from './feature'

const ar = {
    featuresPay,
    common,
    short: {
        finance: 'المالية',
        stock: 'المخزون'
    },
    onboarding: {
        welcome: 'مرحبًا!',
        step: {
            product: {
                title: '65% مكتمل',
                description: 'تبقى خطوتان فقط لبدء نجاحك العظيم!'
            },
            stock: {
                title: '95% شبه مكتمل!!',
                description: 'تبقى خطوة واحدة فقط لبدء نجاحك العظيم! أكملها الآن.'
            }
        },
        business: {
            title: 'تم إنشاء العمل بنجاح!',
            description: 'رائع! تم تسجيل عملك'
        },
        product: {
            title: 'أضف منتجك الأول',
            description: 'أضف منتجًا لبدء تسجيل المخزون'
        },
        stock: {
            title: 'جرّب إضافة مخزون',
            description: 'ابدأ بتسجيل المخزون الداخل والخارج'
        },
        action: {
            add_business: {
                title: 'إنشاء عمل',
                description: 'هل ترغب في إنشاء عمل جديد؟ انقر هنا'
            },
            join_business: {
                title: 'الانضمام إلى العمل',
                description: 'هل لديك رمز دعوة؟ انضم هنا'
            }
        }
    },
    business: {
        title: 'اختر عملاً، أنشئ جديدًا، أو انضم',
        add_business: {
            title: 'إنشاء عمل جديد',
            name: 'اسم العمل',
            name_placeholder: 'أدخل الاسم',
            category: 'فئة العمل',
            category_placeholder: 'اختر الفئة',
            button: 'إنشاء العمل'
        },
        edit_business: {
            title: 'تعديل العمل'
        },
        join_business: {
            title: 'الانضمام إلى العمل',
            input_code: 'أدخل رمز الدعوة أدناه:',
            field_placeholder: 'رمز الدعوة',
            confirm_title: 'انضم',
            confirm_text: 'ستصبح جزءًا من الفريق في '
        },
        no_business: 'لا توجد أعمال متاحة',
        create_or_join: 'إنشاء عمل أو الانضمام إليه',
        message: {
            saved: 'تم حفظ العمل بنجاح!'
        }
    },
    profile: {
        edit_profile: {
            title: 'تعديل الملف الشخصي',
            name: 'الاسم الكامل',
            name_placeholder: 'أدخل الاسم الكامل',
            email: 'البريد الإلكتروني',
            phone_number: 'رقم الهاتف'
        },
        message: {
            required_name: 'الاسم مطلوب'
        }
    },
    product: {
        choose_product: 'اختر المنتج',
        choose_product_empty: 'حدد المنتج وأدخل الكمية',
        search: {
            placeholder: 'ابحث بالاسم',
            tooltip: 'فتح الماسح الضوئي'
        },
        column: {
            image: 'صورة',
            name: 'الاسم',
            price: 'السعر',
            buying_price: 'سعر التكلفة',
            selling_price: 'سعر البيع',
            sku: 'رمز المنتج',
            stock: 'المخزون',
            quantity: 'الكمية',
            latest_stock: 'المتبقي',
            product_deleted: 'تم حذف المنتج',
            info_not_found: 'المعلومات غير متاحة',
            expired_date: 'تاريخ انتهاء الصلاحية'
        },
        no_product: 'لا توجد منتجات متاحة',
        add_product: {
            sku: 'رمز المنتج',
            sku_placeholder: 'رمز المنتج. مثال: 12345',
            name: 'اسم المنتج',
            name_placeholder: 'أدخل اسم المنتج',
            initial_stock: 'المخزون الأولي',
            initial_stock_placeholder: 'أدخل المخزون الأولي',
            buying_price: 'سعر التكلفة',
            buying_price_placeholder: 'أدخل سعر التكلفة',
            selling_price: 'سعر البيع',
            selling_price_placeholder: 'أدخل سعر البيع',
            category: 'الفئة',
            category_placeholder: 'اختر الفئة',
            add_selling_price: 'أضف سعر الجملة',
            expired_date: 'تاريخ انتهاء الصلاحية',
            expired_date_placeholder: 'اختر التاريخ',
            description: 'الوصف',
            description_placeholder: 'أدخل الوصف'
        },
        choose_selling_price: 'اختر سعر البيع',
        selling_price: 'سعر البيع',
        total_product: 'إجمالي المنتجات',
        total_quantity: 'إجمالي المخزون',
        total_buying_price: 'إجمالي سعر التكلفة',
        total_selling_price: 'إجمالي سعر البيع',
        message: {
            required_name: 'اسم المنتج مطلوب',
            required_sku: 'رمز المنتج مطلوب',
            required_category: 'الفئة مطلوبة',
            required_quantity: 'المخزون الأولي مطلوب',
            invalid_input_qty: 'كمية المخزون غير صالحة',
            required_buying_price: 'سعر التكلفة مطلوب',
            required_selling_price: 'سعر البيع مطلوب',
            required_selling_price_name: 'اسم سعر البيع مطلوب',
            required_selling_price_price: 'سعر البيع مطلوب'
        },
        viewMore: 'عرض المزيد',
        WarehouseDetails: 'تفاصيل المستودع',
        WarehouseName: 'اسم المستودع',
        AddWarehouse: 'إضافة مستودع',
        WarehouseQty: 'كمية المستودع',
        Warehouse: 'المستودع',
        Warehouses: 'المستودعات',
        Variants: 'المتغيرات',
        VariantName: 'اسم المتغير',
        productVariant: 'متغيرات المنتج',
        AddVariant: 'إضافة متغيرات',
        TranferItem: 'نقل العناصر',
        AddUnit: 'وحدة جديدة',
        InitialStkWarehouse: 'المخزون الأولي المطلوب لإضافة المتغيرات',
        InitialStockinwarehouse: 'المخزون الأولي في المستودع',
        TotalQuantity: 'إجمالي الكمية:',
        AddNote: 'إضافة ملاحظة (سعر التكلفة)',
        AddNotetitle: 'إضافة ملاحظة',
        VariantSuccessMessage: 'تم تحديث متغير المنتج بنجاح',
        Next: 'التالي',
        Submit: 'إرسال',
        INACTIVE: 'غير نشط',
        errorone: 'عذرًا! الكمية المطلوبة لـ',
        errortwo: 'لا تتطابق مع كميات المستودع المخصصة. يرجى ضبط الكمية أو إعادة تخزينها.',
        SelectWarehouse: 'اختر المستودع',
        SelectVariant: 'اختر النوع',
        Discount: 'خصم',
        DiscountDetails: 'تفاصيل الخصم',
        FinalPrice: 'السعر النهائي',
        DiscountType: 'نوع الخصم',
        DiscountPer: 'خصم %',
        DiscountValue: 'قيمة الخصم',
        Discountonprice: 'خصم على السعر',
        Discountondiscount: 'خصم على الخصم',
        AdditionalDiscount: 'خصم إضافي',
        SellingPrice: 'سعر البيع:',
        Save: 'حفظ',
        OnOriginalPrice: 'على السعر الأصلي',
        OnDiscountedPrice: 'على السعر المخفض'
    },
    note: {
        title: 'ملاحظات',
        add: 'إضافة ملاحظة',
        no_note: 'لا توجد ملاحظات متاحة'
    },
    settings: {
        category: 'فئة',
        language: 'لغة',
        switch_business: 'تبديل الأعمال',
        currencySelect: 'العملة'
    },
    category: {
        category_list: {
            title: 'فئات المنتجات'
        },
        add_category: {
            button: 'فئة جديدة',
            title: 'إضافة فئة جديدة'
        },
        edit_category: {
            title: 'تعديل الفئة'
        },
        name: 'اسم الفئة',
        name_placeholder: 'أدخل اسم الفئة',
        no_category: 'لا توجد فئات متاحة'
    },
    staff: {
        add: 'إضافة موظف',
        edit: 'تعديل موظف',
        access: {
            stock: 'المخزون',
            add_product: 'إضافة منتج جديد',
            edit_product: 'تعديل المنتج',
            delete_product: 'حذف المنتج',
            view_capital_price: 'عرض سعر التكلفة',
            add_category: 'إضافة فئة جديدة',
            edit_category: 'تعديل الفئة',
            delete_category: 'حذف الفئة',
            add_supplier: 'إضافة مورد جديد',
            edit_supplier: 'تعديل المورد',
            delete_supplier: 'حذف المورد',
            add_customer: 'إضافة عميل جديد',
            edit_customer: 'تعديل العميل',
            delete_customer: 'حذف العميل',
            add_staff: 'إضافة موظف',
            edit_staff: 'تعديل الموظف',
            delete_staff: 'حذف الموظف',
            change_business_profile: 'تغيير ملف الأعمال'
        },
        no_staff: 'لا يوجد موظفون متاحون.'
    },
    code: {
        title: 'تم إضافة موظف جديد!',
        subtitle: 'شارك رمز الدعوة أدناه للانضمام:',
        valid: 'الرمز صالح لمدة 48 ساعة فقط',
        share: 'مشاركة على واتساب',
        copied: 'تم النسخ!',
        invalid: 'رمز غير صالح!'
    },
    history: {
        no_history: 'لا توجد تاريخ.',
        detail: {
            remove: 'إزالة التاريخ',
            confirm: 'ستؤثر هذه الميزة على المخزون الحالي والتاريخ والتقارير.'
        }
    },
    supplier: {
        add: {
            title: 'إضافة بائع'
        },
        edit: {
            title: 'تعديل بائع'
        },
        new: 'بائع جديد',
        no_supplier: 'لا توجد بائعين متاحين.',
        bank: {
            title: 'تفاصيل البنك'
        },
        note: 'ملاحظات',
        form: {
            name: {
                label: 'اسم البائع',
                placeholder: 'أدخل اسم البائع',
                placheolder_not_mandatory: 'أدخل الاسم (اختياري)',
                error: {
                    required: 'لا يمكن أن يكون الاسم فارغاً'
                }
            },
            contact: {
                label: 'رقم الاتصال',
                placeholder: '91934554432',
                error: {
                    required: 'لا يمكن أن يكون رقم الاتصال فارغاً',
                    invalid: 'رقم غير صالح. يجب أن يتضمن رمز الدولة'
                }
            },
            bank_name: {
                label: 'اسم البنك',
                placeholder: 'أدخل اسم البنك'
            },
            bank_account_name: {
                label: 'اسم صاحب الحساب',
                placeholder: 'أدخل اسم صاحب الحساب'
            },
            bank_account_number: {
                label: 'رقم الحساب',
                placeholder: 'أدخل رقم الحساب'
            },
            note: {
                label: 'ملاحظات',
                placeholder: 'أضف ملاحظات'
            }
        },
        choose: 'اختر البائع'
    },
    customer: {
        add: {
            title: 'إضافة عميل'
        },
        edit: {
            title: 'تعديل عميل'
        },
        new: 'عميل جديد',
        no_customer: 'لا توجد عملاء متاحين.',
        note: 'ملاحظات',
        form: {
            name: {
                label: 'اسم العميل',
                placeholder: 'أدخل اسم العميل',
                placheolder_not_mandatory: 'أدخل الاسم (اختياري)',
                error: {
                    required: 'لا يمكن أن يكون الاسم فارغاً'
                }
            },
            contact: {
                label: 'رقم الاتصال',
                placeholder: '91934554432',
                error: {
                    required: 'لا يمكن أن يكون رقم الاتصال فارغاً',
                    invalid: 'رقم غير صالح. يجب أن يتضمن رمز الدولة'
                }
            },
            address: {
                label: 'العنوان',
                placeholder: 'أدخل العنوان'
            },
            note: {
                label: 'ملاحظات',
                placeholder: 'أضف ملاحظات'
            }
        },
        choose: 'اختر المشتري',
        report: {
            view: 'عرض تقرير المشتري',
            title: 'تقرير المشتري',
            no_report: 'لا توجد تقارير للمشترين في التاريخ المحدد.',
            column: {
                name: 'الاسم',
                total_transaction: 'إجمالي المعاملات',
                total_amount: 'إجمالي المبيعات',
                total_profit: 'إجمالي الربح'
            }
        }
    },
    confirm_delete: 'هل أنت متأكد أنك تريد حذف هذه البيانات؟',
    report: {
        title: 'التقرير'
    },
    accounting: {
        title: 'المحاسبة',
        view_report: 'عرض التقرير المالي',
        dashboard_title: 'التقرير المالي',
        stats: 'الإحصائيات',
        transaction_report: 'تقرير المعاملات',
        filter: {
            date: {
                placeholder: 'اختر التاريخ',
                submit: 'تطبيق',
                cancel: 'إعادة تعيين الفلتر'
            },
            timeframe: {
                day: 'يومي',
                week: 'أسبوعي',
                month: 'شهري',
                year: 'سنوي'
            }
        },
        total_sales: 'إجمالي المبيعات',
        total_purchases: 'إجمالي المشتريات',
        total_profit: 'إجمالي الربح',
        assets_value: 'قيمة الأصول الحالية',
        table: {
            date: 'التاريخ',
            sales: 'المبيعات',
            purchases: 'المشتريات',
            profit: 'الربح'
        },
        no_data: 'لا توجد بيانات متاحة.'
    },
    stock_report: {
        title: 'تقرير المخزون',
        view: 'عرض تقرير المخزون',
        no_report: 'لا توجد سجلات مخزون للتاريخ أو الموظف المحدد.',
        allStaffPlaceHolder: 'جميع الموظفين',
        column: {
            total_product: 'أنواع المنتجات الحالية',
            total_product_quantity: 'إجمالي المنتجات الحالية'
        }
    },
    subscription: {
        paywall_small: {
            title: 'ميزة VIP',
            description: 'انتهت فترة التجربة لهذه الميزة. ادعم تطوير هذا التطبيق المحلي من خلال الاشتراك.',
            button: 'عرض خطط الاشتراك'
        }
    },
    payment: {
        order: {
            title: 'الدفع'
        }
    },
    imageUploadMessage: {
        uploadSize: 'يجب ألا يتجاوز حجم الصورة 5 ميغابايت!',
        failedMessage: 'فشل في تحميل صورة العنصر، يرجى المحاولة مرة أخرى!',
        successUpload: 'تم تحميل الصورة بنجاح!',
        failedUIError: 'فشل في حذف الصورة، يرجى المحاولة مرة أخرى!',
        SuccessErrorImage: 'تم حذف الصورة بنجاح!'
    },
    productColumns: {
        SKULabel: 'SKU',
        nameLabel: 'اسم المنتج',
        categoryLabel: 'الفئة',
        BuyingPriceLabel: 'سعر الشراء',
        SellingLabel: 'سعر البيع',
        StockLabel: 'المخزون'
    },
    historyColumns: {
        Transactionlabel: 'نوع المعاملة',
        Locallabel: 'التوقيت المحلي',
        Createdlabel: 'تم الإنشاء بواسطة',
        Vendorlabel: 'اسم المورد',
        Buyerlabel: 'اسم المشتري',
        SKUlabel: 'SKU',
        Productlabel: 'اسم المنتج',
        Categorylabel: 'الفئة',
        Buyinglabel: 'سعر الشراء',
        Sellinglabel: 'سعر البيع',
        Initiallabel: 'المخزون الابتدائي',
        Finallabel: 'المخزون النهائي',
        Stocklabel: 'تغيير المخزون'
    },
    stockReportColumns: {
        skuLabel: 'SKU',
        productNameLabel: 'اسم المنتج',
        categoryLabel: 'الفئة',
        buyingPriceLabel: 'سعر الشراء',
        sellingPriceLabel: 'سعر البيع',
        stockInLabel: 'المخزون الداخل',
        stockOutLabel: 'المخزون الخارج',
        remainingStockLabel: 'المخزون المتبقي'
    },
    accountingReportColumns: {
        labelDate: 'التاريخ',
        labelSales: 'المبيعات',
        labelPurchases: 'المشتريات',
        labelProfit: 'الربح'
    },
    customerReportColumns: {
        labelName: 'الاسم',
        labelTotalTransaction: 'إجمالي المعاملات',
        labelTotalSales: 'إجمالي المبيعات',
        labelTotalProfit: 'إجمالي الربح'
    },
    scannerDialog: {
        finished: 'انتهى',
        reduce: 'تقليل',
        successfull: 'ناجح',
        searching: 'جارٍ البحث...',
        title: 'المسح نشط. وجهه نحو الرمز الشريطي!'
    },
    historyFilter: {
        SelectBuyer: 'اختر المشتري',
        SelectVendor: 'اختر المورد'
    },
    productStockFilter: {
        StockLowtoHigh: 'الحد الأدنى إلى الحد الأقصى',
        StockHightoLow: 'الحد الأقصى إلى الحد الأدنى',
        AlphabetAZ: 'الحروف من أ إلى ي',
        AlphabetZA: 'الحروف من ي إلى أ'
    },
    minimizeTitle: 'المسح نشط',
    security_guarantee: {
        title: 'ضمان أمان البيانات'
    },
    invoice: {
        invoicePlaceholder: 'رقم الفاتورة',
        invoiceTitle: 'فاتورة'
    },
    stocklowreport: {
        no_stocklowreport_one: 'لا توجد عناصر بإعدادات أمان المخزون.',
        no_stocklowreport_two: 'يرجى إضافة كمية أمان المخزون.'
    },
    low_alert_stock: 'أدخل كمية أمان المخزون',
    lowStockAlertNotify: 'ستتلقى إشعارًا عندما يكون المخزون أقل من الكمية المحددة.',
    applyButtonStockAlert: 'تطبيق',
    cancelButtonStockAlert: 'إلغاء',
    searchProductLabel: 'ابحث عن منتج...',
    successfullyAddedSafetyStock: 'تم تحديث مخزون الأمان بنجاح',
    safety_Stock: 'المخزون الآمن',
    safety_stock_placeholder: 'أدخل المخزون الآمن',
    lowStockDetailsInfo: {
        ProductCode: 'رمز المنتج',
        SafetyStock: 'مخزون الأمان',
        Stock: 'المخزون',
        CostPrice: 'سعر التكلفة',
        SellingPrice: 'سعر البيع',
        Category: 'الفئة',
        Description: 'الوصف',
        ExpirationDate: 'تاريخ انتهاء الصلاحية'
    },
    CustomLang: {
        Attribute: 'خاصية',
        AddAttribute: 'إضافة خاصية',
        EditAttribute: 'تعديل الخاصية',
        CustomLabePlaceholder: 'أدخل اسم الخاصية',
        CustomLabePlaceholderSelect: 'اختيار النوع',
        AddMoreAttribute: 'إضافة المزيد من الخصائص',
        ItemAttribute: 'خاصية العنصر',
        TableName: 'الاسم',
        TableType: 'النوع',
        TableAction: 'إجراء',
        TableDelete: 'حذف',
        TableEdit: 'تعديل',
        deleteMessage: 'تم حذف الخاصية بنجاح',
        editSuccessMessage: 'تم تحديث الخاصية بنجاح',
        AddSuccessMessage: 'تم إضافة الخاصية بنجاح',
        AddAtt: 'إضافة',
        EditAtt: 'تعديل',
        SubmitAtt: 'يتم الإرسال...'
    },
    Expenses: {
        ExpensesTitle: 'المصروفات',
        AddExpenses: 'إضافة المصروفات',
        ExpenseCategory: 'فئة المصروفات',
        NoExpenseCategory: 'لا توجد فئة مصاريف',
        AddExpenseCategory: 'إضافة فئة المصروفات',
        SelectExpenseCategory: 'اختر فئة المصروفات',
        NoExpenses: 'لا توجد مصروفات',
        ExpensesSuccess: 'تم إنشاء المصروفات بنجاح',
        ExpensesSuccessEdit: 'تم تحديث المصروفات بنجاح',
        ExpensesCategoryName: 'اسم فئة المصروفات',
        ExpensesSuccessDelete: 'تم حذف المصروفات بنجاح',
        TotalSpentThisWeek: 'إجمالي الإنفاق هذا الأسبوع'
    },
    Manufacturing: {
        AddManufacture: 'إضافة تفاصيل التصنيع',
        Manufacturing: 'التصنيع',
        RawMaterials: 'المواد الخام',
        AdditonCosts: 'التكاليف الإضافية',
        RawMaterial: 'المادة الخام',
        AdditonCost: 'التكلفة الإضافية',
        TotalCost: 'التكلفة الإجمالية',
        AddRawMaterial: 'إضافة المواد الخام لـ',
        AddAdditonCost: 'إضافة التكلفة الإضافية لـ',
        AddMore: 'أضف المزيد',
        Add: 'إضافة',
        Update: 'تحديث',
        Updating: 'جارٍ الإرسال...',
        ProductRequired: 'المنتج مطلوب',
        QuantityRequired: 'الكمية مطلوبة',
        ChargeRequired: 'التكلفة مطلوبة',
        EstimatedCostRequired: 'التكلفة التقديرية مطلوبة',
        PurchasePriceRequired: 'سعر الشراء مطلوب',
        SelectCharge: 'اختر التكلفة',
        EstimatedCost: 'التكلفة التقديرية',
        SelectProduct: 'اختر منتجًا',
        Quantity: 'الكمية',
        ManufactureDetails: 'تفاصيل التصنيع',
        BuyingPrice: 'سعر الشراء',
        LabourCharge: 'تكلفة العمالة',
        ElectricityCost: 'تكلفة الكهرباء',
        PackagingCharge: 'تكلفة التعبئة',
        LogisticCost: 'تكلفة اللوجستيات',
        OtherCharges: 'تكاليف أخرى',
        Save: 'حفظ التفاصيل',
        PurchasePrice: 'سعر الشراء',
        Date: 'التاريخ',
        For: 'لـ',
        PurchaseRate: 'معدل الشراء (السعر)',
        Scrolltoloadmore: 'مرر لتحميل المزيد...',
        Noproductsfound: 'لم يتم العثور على منتجات',
        ManufacturingQuantity: 'كمية التصنيع',
        ManufactureAddedSuccess: 'تم تحديث تفاصيل التصنيع بنجاح'
    },
    Warehouses: {
        transferOrder: 'نقل العناصر',
        AddTransferOrder: 'إضافة عناصر النقل',
        NewWarehouse: 'مستودع جديد',
        newHead: 'ابدأ في إدارة مستودعات متعددة!',
        headTwo: 'تتبع وأدر مخزونك بسلاسة عبر مستودعات متعددة',
        EnableWarehouse: 'تفعيل المستودع',
        EnableWarehouse2: 'تفعيل المستودع',
        OrganizationPrimary: 'المستودع الرئيسي للمؤسسة',
        Edit: 'تعديل',
        AreEnableWarehouse: 'هل أنت متأكد أنك تريد تفعيل المستودع؟',
        Confirm: 'تأكيد',
        Cancel: 'إلغاء',
        WarehouseCreatedSuccessfully: 'تم إنشاء المستودع بنجاح',
        MarkAsOP: 'تحديد كمستودع رئيسي للمؤسسة',
        MarkAsInAct: 'تحديد كغير نشط',
        MarkAsAct: 'تحديد كنشط',
        MarkedAsInactive: 'تم تحديد المستودع كغير نشط بنجاح',
        MarkedAsActive: 'تم تحديد المستودع كنشط بنجاح',
        MarkedAsOP: 'تم تحديد المستودع كرئيسي للمؤسسة بنجاح',
        WareDeletedSuccess: 'تم حذف المستودع بنجاح',
        SuccessfullyUpdatedWarehouse: 'تم تحديث المستودع بنجاح',
        SuccessfullyCreatedWarehouse: 'تم إنشاء المستودع بنجاح',
        EditWarehouse: 'تعديل المستودع',
        AddWarehouse: 'إضافة مستودع',
        WarehouseNameIsRequired: 'اسم المستودع مطلوب',
        EnterWarehouseName: 'أدخل اسم المستودع',
        EnterEmail: 'أدخل البريد الإلكتروني',
        EnterPhoneNumber: 'أدخل رقم الهاتف',
        Location: 'الموقع...',
        name: 'اسم المستودع',
        email: 'البريد الإلكتروني',
        phoneNumber: 'رقم الهاتف',
        location: 'الموقع'
    }
}

export default ar
