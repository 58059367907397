import jwt_decode from 'jwt-decode'
import { AccessToken, CollectionApiParams, PaginationRequest, SortingRequest, ToQueryParamsObject } from '~/types'
import { localStore, StoreKey } from './local-store'

interface FormatNumberToCurrencyStringOption {
    roundType: 'round' | 'ceil' | 'floor'
    round: boolean
}

export const formatNumberToCurrencyString = (
    num = 0,
    options: FormatNumberToCurrencyStringOption = {
        roundType: 'round',
        round: true
    }
) => {
    const { roundType, round } = options
    let value = num

    if (round) {
        value = Math[roundType]((num + Number.EPSILON) * 100) / 100
    }

    return String(value)
        .replace(/\./g, '.') // Keep the decimal as period
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // Use comma for thousands separator
}

export const isNumberOnly = (number: string) => {
    const numberRegx = /^\d+$/
    return number.match(numberRegx)
}

export const delayedCallback = (callback: () => void, timeout = 150) => {
    setTimeout(() => {
        callback()
    }, timeout)
}

export const padTime = (time: number) => {
    return String(time).length === 1 ? `0${time}` : `${time}`
}

export const formatTime = (time: number) => {
    // Convert seconds into minutes and take the whole part
    const minutes = Math.floor(time / 60)

    // Get the seconds left after converting minutes
    const seconds = time % 60

    //Return combined values as string in format mm:ss
    return `${minutes}:${padTime(seconds)}`
}

export const convertToNumber = (value: string) => {
    return Number(value.replace(/\D/g, '').replace(/\b0+/g, ''))
}
export const convertToNumberDecimal = (value: string) => {
    // Allow only digits and one decimal point
    const cleanedValue = value.replace(/[^0-9.]/g, '') // Keep digits and decimal point
    const parts = cleanedValue.split('.')

    if (parts.length > 2) {
        return NaN // More than one decimal point, return NaN
    }

    const integerPart = parts[0]
    const decimalPart = parts[1] ? parts[1].slice(0, 2) : ''

    // Reassemble the number with truncated decimal places
    const finalValue = decimalPart ? `${integerPart}.${decimalPart}` : integerPart

    return parseFloat(finalValue) // Convert to number and return
}

export const SkuIDGenerator = () => {
    const random = Math.floor(1000000000 + Math.random() * 9000000000).toString()
    return '123' + random
}

export const SkuIDGeneratorForTranferOrder = () => {
    const random = Math.floor(10000 + Math.random() * 90000).toString() // Generates a 5-digit random number
    return `TO-${random}`
}

export const GetCustomerShortNames = (name: string) => {
    if (!name) {
        return '-'
    }

    return name
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), '')
        .toUpperCase()
        .substring(0, 3)
}

export const getCurrentBusiness = () => {
    const business = localStore.get(StoreKey.SELECTED_BUSINESS)?.data

    if (!business) {
        return null
    }

    return business
}

export const setCurrentBusiness = (businessId: number) => {
    localStore.set(StoreKey.SELECTED_BUSINESS, businessId)
}

export const unsetCurrentBusiness = () => {
    localStore.remove(StoreKey.SELECTED_BUSINESS)
}

export const getAccessToken = (token?: string): AccessToken | null => {
    const accessToken = !token ? localStore.get(StoreKey.ACCESS_TOKEN)?.data : token

    try {
        return jwt_decode(accessToken!)
    } catch {
        return null
    }
}

export const difference = (a: number, b: number) => {
    return Math.abs(a - b)
}

export const isElementInViewport = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect()

    return isInViewport(rect.top, rect.right, rect.bottom, rect.left)
}

export const isInViewport = (_top: number, _right: number, _bottom: number, _left: number) => {
    const top = _top >= 0
    const right = _right <= (window.innerWidth || document.documentElement.clientWidth)
    const bottom = _bottom <= (window.innerHeight || document.documentElement.clientHeight)
    const left = _left >= 0

    return {
        top,
        right,
        bottom,
        left,
        all: top && left && bottom && right
    }
}

type MultiPromiseResolver = <T extends any[]>(promises: { [K in keyof T]: Promise<T[K]> }) => Promise<{
    [K in keyof T]: { status: 'fulfilled' | 'rejected'; value?: T[K]; reason?: any }
}>

export const multiPromiseResolver: MultiPromiseResolver = <T extends any[]>(promises: {
    [K in keyof T]: Promise<T[K]>
}) => {
    return Promise.all(
        promises.map((promise) =>
            promise
                .then((value) => ({ status: 'fulfilled' as const, value }))
                .catch((reason) => ({ status: 'rejected' as const, reason }))
        )
    ) as Promise<{ [K in keyof T]: { status: 'fulfilled' | 'rejected'; value?: T[K]; reason?: any } }>
}

export const replaceToPhoneNumberFormat = (text: string) => {
    return text.replace(/[^0-9+]/g, '').replace(/\b0+/g, '')
}

export const countryCodeRegex = /^(?!91)/g

export const generateRandomSkuHash = () => {
    const length = Math.floor(Math.random() * 3) + 6 // Random length between 6 and 8
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const numbers = '0123456789'
    let result = ''
    // Start with 2 characters
    for (let i = 0; i < 2; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length))
    }
    // Followed by numbers
    for (let i = 2; i < length; i++) {
        result += numbers.charAt(Math.floor(Math.random() * numbers.length))
    }
    return result
}

export const randomNumber = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1)) + min
}

export const convertToQueryParams = <T extends object, M extends ToQueryParamsObject<CollectionApiParams<T>>>(
    params: CollectionApiParams<T>,
    callback: (queryString: string, queryObject: M) => void,
    skippedKeys: (keyof CollectionApiParams<T>)[] = []
) => {
    const query = new URLSearchParams()
    const queryObject = {} as M

    Object.keys(params).forEach((key) => {
        const typedKey = key as keyof CollectionApiParams<T>
        const queryValue = params[typedKey]
        if (skippedKeys.includes(typedKey)) {
            return
        }
        if (queryValue) {
            query.append(key, queryValue.toString())
            queryObject[typedKey] = queryValue.toString() as unknown as M[keyof CollectionApiParams<T>]
        } else if (queryValue === false) {
            query.append(key, 'false')
        }
    })
    const queryString = query.toString()
    callback(queryString, queryObject)
    return queryString
}
export const defaultPagination: PaginationRequest = {
    offset: 0,
    limit: 20
}
export const defaultSort: SortingRequest = {
    sortBy: 'name',
    orderBy: 'ASC'
}
