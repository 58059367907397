const featuresPay = {
    featureTitle: 'ഡേറ്റാ അടിസ്ഥാനമാക്കിയുള്ള ബിസിനസ് തീരുമാനങ്ങൾ എടുക്കുക',
    featureDescription:
        'എന്ത് സാധനങ്ങൾ വിൽക്കുന്നു എന്നതും ഏറ്റവും കൂടുതൽ ലാഭം നൽകുന്നതുമായവയെ അറിയുക, മറ്റ് സാമ്പത്തിക റിപ്പോർട്ടുകൾ എപ്പോഴും, എവിടെയും ആക്സസ് ചെയ്യുക. നിങ്ങളുടെ ബിസിനസ് നിങ്ങളുടെ കൈയിൽ!',
    featureButton: 'ആപ്പിൽ നിന്നുള്ള പ്രീമിയം സബ്‌സ്ക്രിപ്ഷൻ',
    featureStartingPrice: '80',
    featureEndingPart: '30',
    featureStaringText: 'ആരംഭിക്കുന്നത്',
    featureEndingText: 'ആയിരം',

    features: [
        {
            title: 'അൺലിമിറ്റഡ് ഐറ്റംസ്',
            description: 'നിങ്ങൾ സ്വന്തമാക്കിയ എല്ലാ സാധനങ്ങളും അപ്ഡേറ്റ് ചെയ്യാൻ പരിധികൾ ഇല്ല.'
        },
        {
            title: 'അൺലിമിറ്റഡ് സ്റ്റാഫ്',
            description: 'ബിസിനസ്സ്‌కు സ്റ്റാഫ്/അഡ്മിൻ ചേർക്കാൻ പരിധികൾ ഇല്ല.'
        },
        {
            title: 'അൺലിമിറ്റഡ് ഹിസ്റ്ററി',
            description: 'ഇന്ത്യയിലെ പഴയ സ്റ്റോക്ക് രേഖകളിൽ മാറ്റങ്ങൾ കാണുക, പരിധികൾ ഇല്ല.'
        },
        {
            title: 'ഹിസ്റ്ററി ഫിൽട്ടർ',
            description: 'പ്രകാരമുള്ള, വിഭാഗം, സ്റ്റാഫ്, തിയതി എന്നിവയിൽ അടിസ്ഥാനമാക്കി ചരിത്രം എളുപ്പത്തിൽ കാണുക.'
        },
        {
            title: 'എക്സൽ ആക്സസ്സ്',
            description: 'ഒരു ക്ലിക്കിൽ മൈക്രോസോഫ്റ്റ് എക്സലിലേക്ക് സ്റ്റോക്ക് റെക്കോർഡ് ചരിത്രം ലഭിക്കുക.'
        },
        {
            title: 'പിഡിഎഫിലേക്ക് ഡൗൺലോഡ്',
            description: 'ഒരു ക്ലിക്കിൽ ഏറ്റവും പുതിയ ഐറ്റങ്ങളും സ്റ്റോക്ക് ലിസ്റ്റും PDF ആയി ലഭിക്കുക.'
        }
    ],
    warehousesGuide: [
        {
            desc: 'മെച്ചപ്പെട്ട സ്റ്റോക്ക് മാനേജ്മെന്റിനായി ഒന്നിലധികം ഗോദാമുകൾ സൃഷ്ടിക്കുക.'
        },
        {
            desc: 'നിർദ്ദിഷ്ട സ്ഥലങ്ങളിൽ ഉൽപ്പന്നങ്ങൾ നിയോഗിക്കുക.'
        },
        {
            desc: 'ഗോദാമുകളിൽ ഇൻവെന്ററി നില നിരീക്ഷിക്കുക.'
        }
    ],
    addVariantsGuide: [
        { desc: 'വലുപ്പം, നിറം, അല്ലെങ്കിൽ മെറ്റീരിയൽ വകഭേദങ്ങൾ ചേർക്കുക.' },
        { desc: 'ഓരോ വകഭേദത്തിനും സ്റ്റോക്ക് നിലകൾ ക്രമീകരിക്കുക.' },
        { desc: 'ഗോദാമുകളിൽ ട്രാക്ക് ചെയ്യുക.' }
    ]
}

export default featuresPay
