const featuresPay = {
    featureTitle: 'ڈیٹا کی بنیاد پر کاروباری فیصلے کریں',
    featureDescription:
        'جانیں کہ کون سی اشیاء فروخت ہو رہی ہیں اور سب سے زیادہ منافع فراہم کر رہی ہیں، اور مالی رپورٹس کسی بھی وقت، کہیں بھی حاصل کریں۔ آپ کا کاروبار آپ کے ہاتھ کی ہتھیلی میں!',
    featureButton: 'ایپ سے پریمیم سبسکرپشن',
    featureStartingPrice: '80',
    featureEndingPart: '30',
    featureStaringText: 'شروع ہو رہا ہے',
    featureEndingText: 'ہزار',

    features: [
        {
            title: 'لامحدود اشیاء',
            description: 'بغیر کسی حد کے تمام اشیاء کو شامل کریں۔'
        },
        {
            title: 'لامحدود عملہ',
            description: 'کاروبار میں عملہ/ایڈمن کو بغیر کسی حد کے شامل کریں۔'
        },
        {
            title: 'لامحدود تاریخ',
            description: 'پرانے اسٹاک کے ریکارڈ میں تبدیلیاں بغیر کسی حد کے دیکھیں۔'
        },
        {
            title: 'تاریخ کا فلٹر',
            description: 'قسم، زمرہ، عملہ اور تاریخ کی بنیاد پر آسانی سے تاریخ دیکھیں۔'
        },
        {
            title: 'ایکسسل میں ڈاؤنلوڈ کریں',
            description: 'ایک کلک سے اسٹاک ریکارڈ کی تاریخ مائیکروسافٹ ایکسل میں حاصل کریں۔'
        },
        {
            title: 'پی ڈی ایف میں ڈاؤنلوڈ کریں',
            description: 'ایک کلک سے تازہ ترین اشیاء اور اسٹاک کی فہرست کو پی ڈی ایف میں حاصل کریں۔'
        }
    ],
    warehousesGuide: [
        {
            desc: 'بہتر اسٹاک مینجمنٹ کے لیے متعدد گودام بنائیں۔'
        },
        {
            desc: 'مصنوعات کو مخصوص مقامات پر تفویض کریں۔'
        },
        {
            desc: 'گوداموں میں انوینٹری کی سطح کی نگرانی کریں۔'
        }
    ],
    addVariantsGuide: [
        { desc: 'سائز، رنگ یا مواد کی اقسام شامل کریں۔' },
        { desc: 'ہر قسم کے لیے اسٹاک لیول سیٹ کریں۔' },
        { desc: 'گوداموں میں ٹریک کریں۔' }
    ]
}

export default featuresPay
