const common = {
    no_data: 'ഡാറ്റ ലഭ്യമല്ല',
    header: {
        business_name: 'നിങ്ങളുടെ ബിസിനസ്'
    },
    download_app: {
        text: 'സിമ്പ്ലി വെബ് പതിപ്പ് ഉപയോഗിക്കാൻ, ദയവായി ലാപ്‌ടോപ്പ് അല്ലെങ്കിൽ പി.സി. ഉപയോഗിക്കുക.',
        button: 'മൊബൈൽ പതിപ്പ് ഡൗൺലോഡ് ചെയ്യുക'
    },
    unknown: 'അജ്ഞാതം',
    request_error:
        'ഒരു പിശക് സംഭവിച്ചു. ദയവായി വീണ്ടും ശ്രമിക്കുക! അല്ലെങ്കിൽ നിങ്ങളുടെ പ്രശ്നം റിപ്പോർട്ട് ചെയ്യുന്നതിന് ഉപഭോക്തൃ പിന്തുണയ്‌ക്കെത്തുക.',
    home: 'ഹോം',
    product: 'ഉൽപ്പന്നം',
    add_product: 'ഉൽപ്പന്നം ചേർക്കുക',
    edit_product: 'ഉൽപ്പന്നം എഡിറ്റ് ചെയ്യുക',
    stock_in: 'സ്റ്റോക്ക് ഇന്ഗ്രസ്സ്',
    Sales_Turnover: 'വിൽപ്പനയുടെ തിരിവ്',
    profit: 'ലാഭം',
    stock_out: 'സ്റ്റോക്ക് ഔട്ട്',
    staff: 'സ്റ്റാഫ്',
    stock_audit: 'സ്റ്റോക്ക് ഓഡിറ്റ്',
    button: {
        save: 'സേവ്',
        save_and_add_more: 'സേവ് & കൂടുതൽ ചേർക്കുക',
        submit: 'സമർപ്പിക്കുക',
        done: 'ചെയ്തു',
        edit: 'എഡിറ്റ്',
        save_edit: 'മാറ്റങ്ങൾ സംരക്ഷിക്കുക',
        yes: 'ആണ്',
        clear: 'ശുദ്ധീകരിക്കുക',
        delete: 'ഇല്ലാതാക്കുക',
        cancel: 'റദ്ദാക്കുക',
        reset: 'റിസെറ്റ്'
    },
    select_date: 'തീയതി തിരഞ്ഞെടുക്കുക',
    select_action: 'നടപടി തിരഞ്ഞെടുക്കുക',
    mandatory: ' *(ആവശ്യമാണ്)',
    not_mandatory: '(ആവശ്യമായില്ല)',
    business: 'ബിസിനസ്',
    account: 'അക്കൗണ്ട്',
    profile: 'പ്രൊഫൈൽ',
    setting: 'സജ്ജീകരണം',
    whichLanguage: 'മലയാളം',
    headerDashboard: 'വിഐപി ആകുക',
    contact_us: 'ഞങ്ങളെ ബന്ധപ്പെടുക',
    logout: 'ലോഗ് ഔട്ട്',
    category: 'വിഭാഗം',
    data: 'ഡാറ്റ',
    history: 'ചരിത്രം',
    lowStockAlert: 'താഴ്ന്ന സ്റ്റോക്ക് മുന്നറിയിപ്പ്',
    lowStockAlertButton: 'സുരക്ഷ സ്റ്റോക്ക് അളവ് ചേർക്കുക',
    message: {
        login_success: 'സഫലമായി ലോഗിൻ ചെയ്തു!',
        onboard_success: 'ഹൂരായ്!! നിങ്ങളുടെ യാത്ര ആരംഭിച്ചു!',
        successfully_saved: 'ഡാറ്റ വിജയകരമായി സേവ് ചെയ്തു',
        successfully_deleted: 'ഡാറ്റ വിജയകരമായി ഇല്ലാതാക്കി',
        error_required_field: 'ആവശ്യമായ ഫീൽഡ് ശൂന്യമായിരിക്കരുത്',
        no_access: 'അവസാനം ഇല്ല',
        error_update_history: 'ചരിത്രം പുതുക്കാൻ പരാജയപ്പെട്ടു'
    },
    feedback: 'പ്രതികരണം നൽകുക',
    contact: 'ബന്ധപ്പെടുക',
    add: 'ചേർക്കുക',
    supplier: 'വെൻഡർ',
    customer: 'ഉപഭോക്താവ്',
    random: 'കറുക്കൽ',
    scan: 'സ്കാൻ ചെയ്യുക',
    enter: 'ബാർക്കോഡ് സ്കാൻ ചെയ്ത് ലോഗിൻ ചെയ്യുക',
    terms: {
        text: 'നിങ്ങൾ തുടരുമെങ്കിൽ, നിങ്ങൾക്ക് തത്ത്വചിന്തനത്തിന്റെ',
        link: 'സ്വകാര്യതാ നയം ആഗ്രഹിക്കുന്നു'
    },
    others: 'മറ്റ്',
    calender: {
        presets: {
            today: 'ഇന്ന്',
            yesterday: 'ഇന്നലെ',
            last_7_days: 'കഴിഞ്ഞ 7 ദിവസം',
            last_30_days: 'കഴിഞ്ഞ 30 ദിവസം',
            this_month: 'ഈ മാസം',
            last_month: 'കഴിഞ്ഞ മാസം',
            custom_range: 'ഇഷ്ടാനുസൃത പരിധി'
        }
    },
    print_large: 'ലാർജ് പ്രിന്റ് (A4)',
    print_dot_matrix: 'വലിയ മുടിപ്പ് (ഡോട്ട് മാട്രിക്‌സ്)',
    print_medium: 'മധ്യപ്രിണ്‌റ്റ് (75mm)',
    print_small: 'ചെറിയ പ്രിന്റ് (48mm)',
    view_invoice: 'ഇന്വോയ്സ്',
    price: 'വില',
    stock: 'സ്റ്റോക്ക്',
    quantity: 'അളവ്',
    total: 'മൊത്തം',
    print_footer_left: 'സൃഷ്ടിച്ചത്',
    print_footer_center: 'പ്രിന്റ് ചെയ്തത്',
    print_footer_date: 'ഇവിടെയുണ്ട്',
    print_to: 'വിലേക്ക്',
    language: 'മലയാളം',
    dateStatus: 'കഴിഞ്ഞ 7 ദിവസം',
    placeholder: 'ഗതാഗതം തരം',
    staffHistory: 'സ്റ്റാഫ് തിരഞ്ഞെടുക്കുക',
    languageTitle: 'മലയാളം',
    date: 'തീയതി',
    download: 'ഡൗൺലോഡ്',
    download_excel: 'എക്സൽ ഡൗൺലോഡ് ചെയ്യുക',
    download_pdf: 'ബാർകോഡ് ഡൗൺലോഡ് ചെയ്യുക',
    currentTitle: 'വ്യാപാര നാണയം',
    Selectperiod: 'കാലയളവ് തിരഞ്ഞെടുക്കുക',
    TransactionType: 'ഗതാഗത തരം',
    SelectCustomer: 'ഉപഭോക്താവ് തിരഞ്ഞെടുക്കുക',
    invoice: 'ഇന്വോയ്സ്',
    login: {
        google: 'ഗൂഗിളുമായി ലോഗിൻ ചെയ്യുക',
        apple: 'ആപ്പിളുമായി ലോഗിൻ ചെയ്യുക'
    },
    trusted_by: {
        title: 'ഇന്ത്യയിൽ 50,000-ത്തിലധികം ബിസിനസ്സുകൾക്കായി വിശ്വസിക്കപ്പെടുന്നു 🇮🇩'
    },
    privacyPolicyOne: 'ലോഗിൻ ചെയ്താൽ, നിങ്ങൾ Simply-യുടെ',
    privacyPolicyTwo: 'ഗോപ്പ്യതാ നയം',
    privacyPolicyThree: 'അംഗീകരിക്കുന്നു.',
    manufacture: 'തയാറാക്കൽ',
    warehouse: 'ഗൊഡൗണുകൾ'
}

export default common
