const common = {
    no_data: 'డేటా అందుబాటులో లేదు',
    header: {
        business_name: 'మీ వ్యాపారం'
    },
    download_app: {
        text: 'Simply వెబ్ వెర్షన్‌ను ఉపయోగించడానికి, దయచేసి లాప్‌టాప్ లేదా పీసీని ఉపయోగించండి.',
        button: 'మొబైల్ వెర్షన్ డౌన్‌లోడ్ చేయండి'
    },
    unknown: 'తెలియదు',
    request_error:
        'ఒక లోపం జరిగింది. దయచేసి మళ్లీ ప్రయత్నించండి! లేదా మీ సమస్యను నివేదించడానికి కస్టమర్ సపోర్ట్‌ను సంప్రదించండి.',
    home: 'హోమ్',
    product: 'ఉత్పత్తి',
    add_product: 'ఉత్పత్తి జోడించండి',
    edit_product: 'ఉత్పత్తిని మారుస్తున్నారు',
    stock_in: 'స్టాక్ లో',
    Sales_Turnover: 'అమ్మకపు వ్యాప్తి',
    profit: 'లాభం',
    stock_out: 'స్టాక్ బయట',
    staff: 'సిబ్బంది',
    stock_audit: 'స్టాక్ ఆడిట్',
    button: {
        save: 'సేవ్',
        save_and_add_more: 'సేవ్ & మరింత జోడించండి',
        submit: 'సమర్పించు',
        done: 'చెయ్యాను',
        edit: 'మార్చు',
        save_edit: 'మార్పులను సేవ్ చేయండి',
        yes: 'అవును',
        clear: 'తుడిచి వేయు',
        delete: 'తొలగించు',
        cancel: 'రద్దు',
        reset: 'పునఃసంస్థాపన'
    },
    select_date: 'తేదీ ఎంచుకోండి',
    select_action: 'చర్య ఎంచుకోండి',
    mandatory: ' *(అవసరం)',
    not_mandatory: '(అవసరం కాదు)',
    business: 'వ్యాపారం',
    account: 'ఖాతా',
    profile: 'ప్రొఫైల్',
    setting: 'సెట్టింగులు',
    whichLanguage: 'తెలుగు',
    headerDashboard: 'వీఐపీ అవ్వండి',
    contact_us: 'మాతో సంప్రదించండి',
    logout: 'లాగ్ అవుట్',
    category: 'వర్గం',
    data: 'డేటా',
    history: 'చరిత్ర',
    lowStockAlert: 'తక్కువ స్టాక్ హెచ్చరిక',
    lowStockAlertButton: 'సేఫ్టీ స్టాక్ పరిమాణాన్ని జోడించండి',
    message: {
        login_success: 'సఫలంగా లాగిన్ అయ్యారు!',
        onboard_success: 'హుర్రా!! మీ ప్రయాణం ప్రారంభమైంది!',
        successfully_saved: 'డేటా విజయవంతంగా సేవ్ చేయబడింది',
        successfully_deleted: 'డేటా విజయవంతంగా తొలగించబడింది',
        error_required_field: 'అవసరమైన ఫీల్డ్ ఖాళీగా ఉండకూడదు',
        no_access: 'ప్రవేశం లేదు',
        error_update_history: 'చరిత్రను నవీకరించడంలో విఫలమయ్యారు'
    },
    feedback: 'మాట్లాడండి',
    contact: 'సంప్రదించండి',
    add: 'చేర్చండి',
    supplier: 'వెండర్',
    customer: 'గ్రాహకుడు',
    random: 'యాదృచ్ఛిక',
    scan: 'స్కాన్ చేయండి',
    enter: 'బార్కోడ్ స్కాన్ చేసి లాగిన్ అవ్వండి',
    terms: {
        text: 'కొనసాగించడం ద్వారా, మీరు',
        link: 'గోప్యతా విధానానికి అంగీకరించేవారు'
    },
    others: 'ఇతరాలు',
    calender: {
        presets: {
            today: 'ఈ రోజు',
            yesterday: 'నిన్న',
            last_7_days: 'చివరి 7 రోజులు',
            last_30_days: 'చివరి 30 రోజులు',
            this_month: 'ఈ నెల',
            last_month: 'చివరి నెల',
            custom_range: 'కస్టమ్ శ్రేణి'
        }
    },
    print_large: 'ప్రింట్ లార్జ్ (A4)',
    print_dot_matrix: 'పెద్ద ముద్రణ (డాట్ మ్యాట్రిక్స్)',
    print_medium: 'ప్రింట్ మీడియం (75mm)',
    print_small: 'ప్రింట్ స్మాల్ (48mm)',
    view_invoice: 'బిల్లులు',
    price: 'ధర',
    stock: 'స్టాక్',
    quantity: 'మోతాదు',
    total: 'మొత్తం',
    print_footer_left: 'సృష్టించినది',
    print_footer_center: 'ముద్రించినది',
    print_footer_date: 'పై',
    print_to: 'కోసం',
    language: 'తెలుగు',
    dateStatus: 'చివరి 7 రోజులు',
    placeholder: 'వ్యవహార రకం',
    staffHistory: 'స్టాఫ్ ఎంచుకోండి',
    languageTitle: 'తెలుగు',
    date: 'తేదీ',
    download: 'డౌన్‌లోడ్',
    download_excel: 'ఎక్సెల్‌ను డౌన్‌లోడ్ చేయండి',
    download_pdf: 'బార్‌కోడ్‌ను డౌన్‌లోడ్ చేయండి',
    currentTitle: 'కరెన్సీ',
    Selectperiod: 'కాల వ్యవధిని ఎంచుకోండి',
    TransactionType: 'వ్యవహార రకం',
    SelectCustomer: 'గ్రాహకుడిని ఎంచుకోండి',
    invoice: 'బిల్లులు',
    login: {
        google: 'గూగుల్‌తో లాగిన్ అవ్వండి',
        apple: 'ఆపిల్‌తో లాగిన్ అవ్వండి'
    },
    trusted_by: {
        title: 'ఇండోనేషియాలో 50,000 కంటే ఎక్కువ వ్యాపారాలు నమ్మకంగా వినియోగిస్తున్నాయి 🇮🇩'
    },
    privacyPolicyOne: 'సైన్ ఇన్ చేస్తే, మీరు Simply యొక్క',
    privacyPolicyTwo: 'గోప్యతా విధానం',
    privacyPolicyThree: 'ని అంగీకరిస్తున్నారు.',
    manufacture: 'ఉత్పత్తి',
    warehouse: 'గోదాములు'
}

export default common
