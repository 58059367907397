const common = {
    no_data: 'No data available',
    header: {
        business_name: 'Your Business'
    },
    download_app: {
        text: 'To use Simply web version, please use a laptop or PC.',
        button: 'Download mobile version'
    },
    unknown: 'Unknown',
    request_error: 'An error occurred. Please try again! Or contact customer support to report your issue.',
    home: 'Home',
    product: 'Product',
    add_product: 'Add Product',
    edit_product: 'Edit Product',
    stock_in: 'Stock In',
    Sales_Turnover: 'Sales Turnover',
    profit: 'Profit',
    stock_out: 'Stock Out',
    staff: 'Staff',
    stock_audit: 'Stock Audit',
    button: {
        save: 'Save',
        save_and_add_more: 'Save & Add More',
        submit: 'Submit',
        done: 'Done',
        edit: 'Edit',
        save_edit: 'Save Changes',
        yes: 'Yes',
        clear: 'Clear',
        delete: 'Delete',
        cancel: 'Cancel',
        reset: 'Reset'
    },
    select_date: 'Select Date',
    select_action: 'Select Action',
    mandatory: ' *(Required)',
    not_mandatory: '(Not Required)',
    business: 'Business',
    account: 'Account',
    profile: 'Profile',
    setting: 'Settings',
    whichLanguage: 'English',
    headerDashboard: 'Become a VIP',
    contact_us: 'Contact Us',
    logout: 'Logout',
    category: 'Category',
    data: 'Data',
    history: 'History',
    lowStockAlert: 'Low Stock Alert',
    Expenses: 'Expenses',
    lowStockAlertButton: 'Add Safety Stock Quantity',
    message: {
        login_success: 'Successfully Logged In!',
        onboard_success: 'Hooray!! Your journey has started!',
        successfully_saved: 'Data saved successfully',
        successfully_deleted: 'Data deleted successfully',
        error_required_field: 'Required field cannot be empty',
        no_access: 'No access',
        error_update_history: 'Failed to update history'
    },
    feedback: 'Give feedback',
    contact: 'Contact',
    add: 'Add',
    supplier: 'Vendor',
    customer: 'Customer',
    random: 'Random',
    scan: 'Scan',
    enter: 'Login by scanning barcode',
    terms: {
        text: 'By continuing, you agree to the',
        link: 'Privacy Policy'
    },
    others: 'Others',
    calender: {
        presets: {
            today: 'Today',
            yesterday: 'Yesterday',
            last_7_days: 'Last 7 Days',
            last_30_days: 'Last 30 Days',
            this_month: 'This Month',
            last_month: 'Last Month',
            custom_range: 'Custom Range'
        }
    },
    print_large: 'Print Large (A4)',
    print_dot_matrix: 'Print Large (Dot Matrix)',
    print_medium: 'Print Medium (75mm)',
    print_small: 'Print Small (48mm)',
    view_invoice: 'Invoice',
    view_chalan: 'Challan',
    price: 'Price',
    stock: 'Stock',
    quantity: 'Quantity',
    total: 'Total',
    print_footer_left: 'Created by',
    print_footer_center: 'Printed on',
    print_footer_date: 'on',
    print_to: 'To',
    language: 'English',
    dateStatus: 'Last 7 Days',
    placeholder: 'Transaction Type',
    staffHistory: 'Select Staff',
    languageTitle: 'English',
    date: 'Date',
    download: 'Download',
    download_excel: 'Download Excel',
    download_pdf: 'Download Barcode',
    currentTitle: 'Currency',
    Selectperiod: 'Select period',
    TransactionType: 'Transaction Type',
    SelectCustomer: 'Select Customer',
    invoice: 'Invoice',
    login: {
        google: 'Login with Google',
        apple: 'Login with Apple'
    },
    trusted_by: {
        title: 'Trusted by over 50,000 businesses \n across Indonesia 🇮🇩'
    },
    privacyPolicyOne: "By signing in, you agree to Simply's",
    privacyPolicyTwo: 'Privacy Policy',
    manufacture: 'Manufacture',
    warehouse: 'Warehouses'
}

export default common
