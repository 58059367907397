const common = {
    no_data: 'தகவல் இல்லை',
    header: {
        business_name: 'உங்கள் வணிகம்'
    },
    download_app: {
        text: 'Simply வலைப் பதிப்பைப் பயன்படுத்த, ஒரு மடிக்கணினி அல்லது பிசி பயன்படுத்தவும்.',
        button: 'மொபைல் பதிப்பை பதிவிறக்கவும்'
    },
    unknown: 'அறியப்படாதது',
    request_error:
        'பிழை ஏற்பட்டது. மீண்டும் முயற்சிக்கவும்! அல்லது உங்கள் பிரச்சினையை புகாரளிக்க வாடிக்கையாளர் ஆதரவை தொடர்புகொள்ளவும்.',
    home: 'முகப்பு',
    product: 'தயாரிப்பு',
    add_product: 'தயாரிப்பைச் சேர்க்கவும்',
    edit_product: 'தயாரிப்பைத் திருத்தவும்',
    stock_in: 'பங்கு உள்ளீடு',
    Sales_Turnover: 'விற்பனை வருமானம்',
    profit: 'லாபம்',
    stock_out: 'பங்கு வெளியேற்றம்',
    staff: 'ஸ்டாஃப்',
    stock_audit: 'பங்கு தணிக்கை',
    button: {
        save: 'சேமிக்கவும்',
        save_and_add_more: 'சேமித்து மேலும் சேர்க்கவும்',
        submit: 'சமர்ப்பிக்கவும்',
        done: 'முடிந்தது',
        edit: 'திருத்து',
        save_edit: 'மாற்றங்களைச் சேமிக்கவும்',
        yes: 'ஆம்',
        clear: 'அழிக்கவும்',
        delete: 'நீக்கவும்',
        cancel: 'ரத்து',
        reset: 'மீட்டமைக்கவும்'
    },
    select_date: 'தேதியைத் தேர்ந்தெடுக்கவும்',
    select_action: 'செயல்களைத் தேர்ந்தெடுக்கவும்',
    mandatory: ' *(தேவை)',
    not_mandatory: '(தேவை இல்லை)',
    business: 'வணிகம்',
    account: 'கணக்கு',
    profile: 'சுயவிவரம்',
    setting: 'அமைப்புகள்',
    whichLanguage: 'தமிழ்',
    headerDashboard: 'VIP ஆகுங்கள்',
    contact_us: 'எங்களை தொடர்புகொள்ளவும்',
    logout: 'வெளியேறு',
    category: 'வகை',
    data: 'தகவல்',
    history: 'வரலாறு',
    lowStockAlert: 'குறைந்த பங்கு அறிவிப்பு',
    lowStockAlertButton: 'பாதுகாப்பு பங்கு அளவைச் சேர்க்கவும்',
    message: {
        login_success: 'வெற்றிகரமாக உள்நுழைந்தீர்கள்!',
        onboard_success: 'ஹூரே!! உங்கள் பயணம் தொடங்கியுள்ளது!',
        successfully_saved: 'தகவல் வெற்றிகரமாக சேமிக்கப்பட்டது',
        successfully_deleted: 'தகவல் வெற்றிகரமாக நீக்கப்பட்டது',
        error_required_field: 'தேவையான புலம் காலியாக இருக்க முடியாது',
        no_access: 'அணுகல் இல்லை',
        error_update_history: 'வரலாற்றைப் புதுப்பிக்க தோல்வி'
    },
    feedback: 'பின்னூட்டம் வழங்கவும்',
    contact: 'தொடர்பு',
    add: 'சேர்க்கவும்',
    supplier: 'விற்பனையாளர்',
    customer: 'வாடிக்கையாளர்',
    random: 'சீரற்ற',
    scan: 'ஸ்கேன்',
    enter: 'பார்கோடு ஸ்கேனிங் மூலம் உள்நுழைக',
    terms: {
        text: 'தொடர்ந்து உங்கள் மூலம் ஒப்புக்கொள்கிறீர்கள்',
        link: 'தனியுரிமை கொள்கை'
    },
    others: 'மற்றவை',
    calender: {
        presets: {
            today: 'இன்று',
            yesterday: 'நேற்று',
            last_7_days: 'கடந்த 7 நாட்கள்',
            last_30_days: 'கடந்த 30 நாட்கள்',
            this_month: 'இந்த மாதம்',
            last_month: 'கடந்த மாதம்',
            custom_range: 'சொந்த வரம்பு'
        }
    },
    print_large: 'பெரியது அச்சிடுக (A4)',
    print_dot_matrix: 'பெரிய அச்சு (டாட் மாட்ரிக்ஸ்)',
    print_medium: 'மத்திய அளவு அச்சிடுக (75mm)',
    print_small: 'சிறியது அச்சிடுக (48mm)',
    view_invoice: 'இன்வாய்ஸ்',
    price: 'விலை',
    stock: 'பங்கு',
    quantity: 'அளவு',
    total: 'மொத்தம்',
    print_footer_left: 'உருவாக்கியது',
    print_footer_center: 'அச்சிடப்பட்ட தேதி',
    print_footer_date: 'தேதி',
    print_to: 'அனுப்பவும்',
    language: 'தமிழ்',
    dateStatus: 'கடந்த 7 நாட்கள்',
    placeholder: 'பரிவர்த்தனை வகை',
    staffHistory: 'ஸ்டாஃப் தேர்வு செய்க',
    languageTitle: 'தமிழ்',
    date: 'தேதி',
    download: 'பதிவிறக்கவும்',
    download_excel: 'எக்செல் பதிவிறக்கவும்',
    download_pdf: 'பார்கோடு பதிவிறக்கம் செய்க',
    currentTitle: 'நாணயம்',
    Selectperiod: 'காலத்தைத் தேர்ந்தெடுக்கவும்',
    TransactionType: 'பரிவர்த்தனை வகை',
    SelectCustomer: 'வாடிக்கையாளரைத் தேர்ந்தெடுக்கவும்',
    invoice: 'இன்வாய்ஸ்',
    login: {
        google: 'கூகுளுடன் உள்நுழைக',
        apple: 'ஆப்பிளுடன் உள்நுழைக'
    },
    trusted_by: {
        title: 'இந்தோனேஷியா 🇮🇩 முழுவதும் 50,000க்கும் மேற்பட்ட தொழில்களால் நம்பிக்கை'
    },
    privacyPolicyOne: 'உள்நுழைவதன் மூலம், நீங்கள் Simply-யின்',
    privacyPolicyTwo: 'தனியுரிமை கொள்கை',
    privacyPolicyThree: 'க்கு ஒப்புக்கொள்கிறீர்கள்.',
    manufacture: 'உற்பத்தி',
    warehouse: 'கிடங்குகள்'
}

export default common
